import React from 'react';
import { useSearchParams } from 'react-router-dom';
import PublicationCard from './PublicationCard';
import ProjectPagination from '../Global/ProjectPagination';
import { NavLink } from 'react-router-dom';
import { titleToSlug } from '../../lib/helpers/slug';
import SideNavbar from './SideNavbar';
import { useFetcher } from '../../api';
import DataWidget from '../Global/DataWidget';
import PublicationCardLoader from './PublicationCardLoader';

const PublicationCardList = () => {
  const [searchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page')) || 1;
  const [page, setPage] = React.useState(currentPage);
  const { data, isError, isLoading } = useFetcher(
    `/api/publications?page=${page}&limit=${
      page === 1 ? 7 : 6
    }&sortBy=recent`,
  );
  const publications = data?.data || [];
  const [publication = {}, ...rest] = publications;
  const slug = titleToSlug(publication.title || '#');

  const pagination = data?.pagination || {};

  React.useEffect(() => {
    setPage(currentPage);
    const element = document.querySelector('#publications');
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [currentPage]);

  return (
    <>
      <div className="flex flex-col md:flex-row w-full mt-6 md:mt-8 gap-y-4 md:space-x-6">
        <SideNavbar />
        <div className="flex flex-col flex-grow">
          {isLoading ? (
            <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-3">
              {[...Array(12)].map((_, index) => (
                <PublicationCardLoader key={index} />
              ))}
            </div>
          ) : null}
          <DataWidget isLoading={isLoading} isError={isError}>
            {!publications.length ? (
              <div className="flex justify-center w-full items-center py-12 text-center">
                <p className="text-gray-500 text-xl">
                  There are no publications available yet!
                </p>
              </div>
            ) : null}
            {pagination.page <= 1 && publications.length ? (
              <div
                className="relative flex flex-col w-full mb-12"
                data-aos="zoom-in"
              >
                <img
                  src={publication.cover}
                  height={442}
                  alt=""
                  className="h-[442px] object-cover w-full"
                />
                <div className="w-full bg-black/30 backdrop-blur flex flex-col items-start bottom-0 p-4 text-white absolute">
                  <p className="text-sm font-light">
                    {publication.name}
                  </p>
                  <h1 className="text-xl md:text-2xl font-bold mt-2">
                    {publication.title}
                  </h1>

                  <a
                    href={publication.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mt-6 py-3 px-4 md:px-8 text-sm md:text-base font-semibold rounded-full text-white bg-brand-green"
                  >
                    View Publication
                  </a>
                </div>
              </div>
            ) : null}
            {rest?.length > 1 ? (
              <h1 className="text-xl font-semibold mb-4 mx-4 md:mx-0">
                Other publications
              </h1>
            ) : null}
            <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-3">
              {(pagination.page > 1 ? publications : rest).map(
                publication => (
                  <PublicationCard
                    key={publication._id}
                    publication={publication}
                  />
                ),
              )}
            </div>
          </DataWidget>
        </div>
      </div>
      {pagination.pages > 1 ? (
        <div className="mt-6 md:mt-12 max-w-4xl ml-auto w-full flex items-center justify-end">
          <ProjectPagination
            onChange={page => {
              setPage(page);
            }}
            pages={pagination.pages}
            page={page}
          />
        </div>
      ) : null}
    </>
  );
};

export default PublicationCardList;
