import { GET_MEMBERS } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const getAllMembers = () => {
  return async dispatch => {
    try {
      const result = await api.getMembers();
      if (!result.data.allStaffMembers) {
        throw new Error('Failed to retrieve services');
      }
      dispatch({
        type: GET_MEMBERS,
        payload: {
          members: result.data.allStaffMembers,
        },
      });
    } catch (error) {
      // console.log(error);
    }
  };
};
