import Joi from 'joi';

export const individualInfoShema = Joi.object({
  // plan: Joi.string()
  //   .valid('junior', 'coorporate', 'fellow', 'application fee')
  //   .required(),
  firstName: Joi.string().required().label('First name'),
  lastName: Joi.string().label('Last name'),
  gender: Joi.string()
    .valid('male', 'female', 'other')
    .required()
    .label('Gender'),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  phoneNumber: Joi.string().required().label('Phone number'),
  nationality: Joi.string().required(),
  identificationNumber: Joi.string()
    .required()
    .label('ID or Passport'),
  dateOfBirth: Joi.date().required().label('Date of Birth'),
  placeOfBirth: Joi.string().required().label('Place of Birth'),
  placeOfResidence: Joi.string()
    .required()
    .label('Place of Residence'),
  education: Joi.object({
    level: Joi.string().required().label('Level of education'),
    field: Joi.string().required().label('Field of specialization'),
    institution: Joi.string().required().label('Institution'),
    graduationYear: Joi.string().required().label('Graduation year'),
  }).required(),
  referee: Joi.object({
    name: Joi.string().required().label('Name'),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label('Email'),
    phoneNumber: Joi.string()
      .required()
      // .messages({
      //   'string.base': `"a" should be a type of 'text'`,
      //   'string.empty': `"a" cannot be an empty field`,
      //   'string.min': `"a" should have a minimum length of {#limit}`,
      //   'any.required': `"a" is a required field`,
      // })
      .label('Phone number'),
    relationship: Joi.string().required().label('Relationship'),
    position: Joi.string().required().label('Relationship'),
    institution: Joi.string().required().label('Institution'),
  }).required(),
  cv: Joi.string().required().label('CV or Resume').messages({
    'string.base': 'CV or Resume is required',
    'any.required': 'CV or Resume is required',
  }),
  motivationLetter: Joi.string()
    .required()
    .label('Motivation letter')
    .messages({
      'string.base': 'Motivation letter is required',
      'any.required': 'Motivation letter is required',
    }),
  certificates: Joi.array()
    .items(Joi.string().required())
    .min(1)
    .max(10)
    .required(),
  // .message(
  //   'The certificates field must contain 1 item with a maximum of 10.',
  // ),
  // 'array.min':
  //   'The certificates field must contain at least 1 item.',
  // 'array.max':
  //   'The certificates field must contain at least 10 items maximum.',
}).required();

export const companyInfoShema = Joi.object({
  // plan: Joi.string()
  //   .valid('monthly', 'yearly', 'application fee')
  //   .required(),
  name: Joi.string().required(),
  location: Joi.string(),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  yearsOfExperience: Joi.number()
    .required()
    .label('Years of experience'),
  staffSize: Joi.number().required().label('Staff size'),
  completedProjects: Joi.number()
    .required()
    .label('Completed projects'),
  recentProject: Joi.object({
    link: Joi.string().uri().label('Project link'),
    files: Joi.array().items(Joi.string()).max(20),
  })
    .label('Project')
    .required(),
  ceo: Joi.object({
    name: Joi.string().required().label('CEO Name'),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .label('CEO Email'),
    phoneNumber: Joi.string().required().label('CEO Phone number'),
    position: Joi.string().required().label('Position'),
  }).required(),
}).required();

export const publicationInfoShema = Joi.object().keys({
  title: Joi.string().required(),
  cover: Joi.string().required(),
  link: Joi.string().uri().required(),
  description: Joi.string().required(),
});
