import React from 'react';

const Background = () => {
  return (
    <div
      className="mt-auto flex flex-col items-center w-full bg-gray-401 px-8 py-6 md:py-12"
      id="story-behind-rupi"
    >
      <div
        className="flex flex-col max-w-6xl w-full"
        data-aos="fade-up"
      >
        <h1 className="font-black text-brand-green">Background</h1>
        <h2 className="text-3xl md:text-4xl font-extrabold tracking-wide mt-2">
          The story behind RUPI
        </h2>
        <p className="mt-4 md:mt-6 leading-relaxed text-justify">
          Rwanda Urban Planners Institute (RUPI) is a professional
          body representing urban planners in Rwanda. It is a National
          organization that was established in 2013 and has over 3,000
          professional members. As a consequence of the first
          graduates from the former National University of Rwanda,
          being the only university that started the urban and
          regional planning programme in the department of geography,
          the urban planners&#39; movement became quite available in
          the Rwandan labor market. However, they were working
          independently in different institutions and companies, and
          there was no professional constitution regarding the urban
          planning. Over the years, planners gradually began to
          emerge, and others graduated with higher degrees from abroad
          and eventually began to contribute to the first development
          of master plans in Rwanda and to provide other professional
          services related to urban planning. Following numerous
          struggles, different urban planners collaborated and
          committed themselves to work professionally and initiate the
          community where they can get into the most valuable network.
          On 18 January 2013, a first general assembly brought
          together different urban planners on table. The list of
          founding members included Fred KALEMA, Dr. Peter BUTERA
          BAZIMYA, Louise MUJIJIMA, Moses TWESIGYE TURATSINZE, Felix
          SUGI, Thierry MBAYIHA, Benon KARASIRA, Liliane UWANZIGA
          MUPENDE, and Vincent RWIGAMBA. Among the founding members,
          an election of the Council Committee members was held to
          speed up the process of registering the Institute with the
          relevant Rwandan authority, as foreseen by the law. Fred
          KALEMA was elected Chairman of the institution, Dr. Peter
          BUTERA BAZIMYA Vice-Chairman, Moses TURATSINZE TWESIGYE
          Secretary, and finally Louise MUJIJIMA Treasurer.
        </p>

        <p className="mt-2 leading-relaxed text-justify">
          Following the initiation, frankly, the government may not
          have helped them, which led to the downfall of the
          institution. It was Dr. Peter BUTERA BAZIMYA who resisted
          the urge to push and push again to hoist the flag of the
          planners professional body. Later, on 11 October 2017,
          BARAHIRA Evariste, who has been a close member to Dr. Peter
          BAZIMYA, created a WhatsApp group of Rwanda Urban Planners
          for the reorganization. This reorganization was successful
          as on 28 September 2018, with the support of Rwanda Housing
          Authority (RHA), the Institute (RUPI) held the second
          General Assembly and elected the new committee to fight for
          legal recognition. The newly elected committee was made up
          of Dr. Peter BUTERA BAZIMYA as Chairman of the institution,
          Parfait KAREKEZI as Vice-Chairman, Grace INGABIRE as
          Secretary, and Evariste BARAHIRA NSABIMANA as Treasurer.
          Despite COVID 19, Chairman Dr. BAZIMYA and Vice-Chairman
          KAREKEZI Parfait travelled abroad due to illness and studies
          respectively. HAKIZIMANA Eric has been present with
          constructive ideas during this transition so far and has
          supported the institution at least to keep striving.
          INGABIRE Grace (Secretary) and BARAHIRA Evariste, in
          accordance with the Constitution, signed a resolution
          choosing MUNYABURANGA Vivien as the interim Chairman of the
          Institute. Later in 2022, RUPI participated in CHOGM 2022
          held in Kigali, Rwanda, and became an official member of CAP
          (Commonwealth Association of Planners).
        </p>
        <div className="mt-2 leading-relaxed text-justify">
          On 24 August 2022, the Ministry of Environment, as the
          regulatory authority, authorised RUPI to operate
          provisionally until 1 July 2023, while the relevant
          institutions consulted to establish regulatory frameworks
          for the profession of Urban and Regional Planning. During
          this process, high respect was given to the NLA (National
          Land Authority) team of planners (RUTAGENGWA Alexis,
          MPAYIMANA Protais and others) for their assistance in
          obtaining this provisional letter and in reaching the 3rd
          GA. On 00 March, RUPI held its 3rd General Assembly in view
          of adopting amendments to eliminate the existing loopholes
          in its three main documents: Constitution, Rules of
          Procedure and Code of Ethics, in order to be ready for the
          regulatory frameworks of the profession. During the GA,
          there was also the election of a new committee to comply
          with the documents and facilitate the full functionality of
          RUPI as an institution. The elected committee was composed
          of 6 different categories with a three (3) year term of
          office that can be extended. These categories are as
          follows:
          <div>
            {' '}
            1. The Governing Council Committee, consisting of 7
            members, including the Chairperson of the institution.
          </div>
          <div>
            {' '}
            2. The Membership and Licensing Committee with 5 members
          </div>
          <div>
            {' '}
            3. The Finance and Resource Mobilization Committee with 5
            members
          </div>
          <div>
            {' '}
            4. Professional Compliance, Inspection and Discipline
            Committee with 5 members
          </div>
          <div>
            {' '}
            5. Professional Development, Research and Publication
            Committee, consisting of 5 members.
          </div>
          <div className="pb-3">
            {' '}
            6. and finally the honorary members of the institution
            with 10 members.
          </div>
          It is planned that in mid-May 2023 (before the expiry of a
          given provisional letter) there will be an official launch
          of RUPI at Kigali Convention Center. This time, RUPI will be
          presented in the media; compliant RUPI members will receive
          certificates and stamps allowing them to provide all
          services in the country.
        </div>
      </div>
    </div>
  );
};

export default Background;
