import { FaCheck, FaTimes } from 'react-icons/fa';
export const individualPricingData = [
  {
    id: 3,
    price: '50,000',
    currency: 'RWF',
    title: 'JUNIOR',
    membership: 'Junior',
    features: [
      {
        icon: <FaCheck />,
        text: 'Professional development',
        active: true,
      },
      {
        icon: <FaCheck />,
        text: 'Network and employment',
        active: false,
      },
      {
        icon: <FaTimes />,
        text: 'Mentorship opportunities',
        active: false,
      },
      {
        icon: <FaTimes />,
        text: 'Consultancy opportunities',
        active: false,
      },
      {
        icon: <FaTimes />,
        text: 'Research and publication',
        active: false,
      },
      {
        icon: <FaTimes />,
        text: 'Leadership and decisions',
        active: false,
      },
    ],
  },
  {
    id: 4,
    price: '100,000',
    currency: 'RWF',
    title: 'PROFESSIONAL',
    membership: 'Professional',
    isPopular: true,
    features: [
      {
        icon: <FaCheck />,
        text: 'Professional development',
        active: true,
      },
      {
        icon: <FaCheck />,
        text: 'Network and employment',
        active: false,
      },
      {
        icon: <FaCheck />,
        text: 'Mentorship opportunities',
        active: false,
      },
      {
        icon: <FaCheck />,
        text: 'Consultancy opportunities',
        active: false,
      },
      {
        icon: <FaTimes />,
        text: 'Research and publication',
        active: false,
      },
      {
        icon: <FaTimes />,
        text: 'Leadership and decisions',
        active: false,
      },
    ],
  },
  {
    id: 5,
    price: '150,000',
    currency: 'RWF',
    title: 'CONSULTING',
    membership: 'Consulting',
    features: [
      {
        icon: <FaCheck />,
        text: 'Professional development',
        active: true,
      },
      {
        icon: <FaCheck />,
        text: 'Network and employment',
        active: false,
      },
      {
        icon: <FaCheck />,
        text: 'Mentorship opportunities',
        active: false,
      },
      {
        icon: <FaCheck />,
        text: 'Consultancy opportunities',
        active: false,
      },
      {
        icon: <FaCheck />,
        text: 'Research and publication',
        active: false,
      },
      {
        icon: <FaCheck />,
        text: 'Leadership and decisions',
        active: false,
      },
    ],
  },
];

export const companyPricingData = [
  {
    id: 6,
    price: '300,000',
    currency: 'RWF',
    title: 'COMPANY',
    membership: 'Company',
    features: [
      {
        icon: <FaCheck />,
        text: 'Professional development',
        active: true,
      },
      {
        icon: <FaCheck />,
        text: 'Network opportunities',
        active: false,
      },
      {
        icon: <FaCheck />,
        text: 'Advocacy and influence',
        active: false,
      },
      {
        icon: <FaCheck />,
        text: 'Consultancy opportunities',
        active: false,
      },
      {
        icon: <FaCheck />,
        text: 'Credibility and reputation',
        active: false,
      },
      {
        icon: <FaCheck />,
        text: 'Marketing and promotion',
        active: false,
      },
    ],
  },
];

export const individualApplicationFee = [
  {
    price: '10,000',
    currency: 'RWF',
    title: 'Application Fee',
    features: [
      {
        icon: <FaCheck />,
        text: 'Professional development',
        active: true,
      },
      {
        icon: <FaTimes />,
        text: 'Network and employment',
        active: false,
      },
      {
        icon: <FaTimes />,
        text: 'Mentorship opportunities',
        active: false,
      },
      {
        icon: <FaTimes />,
        text: 'Consultancy opportunities',
        active: false,
      },
      {
        icon: <FaTimes />,
        text: 'Research and publication',
        active: false,
      },
      {
        icon: <FaTimes />,
        text: 'Leadership and decisions',
        active: false,
      },
    ],
  },
];

export const defaultPlans = {
  individual: 'JUNIOR',
  company: 'MONTHLY',
};
