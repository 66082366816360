import React from 'react';
import PublicationContainer from '../components/Publications/PublicationContainer';
import AboutHeader from '../components/About/AboutHeader';

export const Publication = () => {
  return (
    <>
      <div id="publications" className="invisible" />
      <AboutHeader
        subTitle="RUPI Publications"
        details="Publish your work on our site and be able to share it with the world"
      />
      <PublicationContainer />
    </>
  );
};
