import React from 'react';
import { PaymentForm } from '../components/Payment/PaymentForm';

const Pay = () => {
  return (
    <div className="flex flex-col items-center w-full bg-white p-8 py-6 md:py-12">
      <PaymentForm />
    </div>
  );
};

export default Pay;
