import React from "react";
import HashLoader from 'react-spinners/HashLoader';

export const Preloader = () => {
  return (
    <div className="fixed z-50 inset-0 bg-green backdrop-blur-sm flex items-center justify-center">
      <HashLoader
        color="#000000"
        loading={true}
        size={80}
        aria-label="Loading Spinner"
        data-testid="loader"
      />  
    </div>
  );
}