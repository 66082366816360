import PageHeader from '../components/Global/PageHeader';

export const Committee = () => {
  return (
    <>
      <PageHeader
        title="RUPI committees"
        description="There are no enough committees content to show at this time, coming soon..."
      />
      {/* <div>No committees content, coming soon...</div> */}
    </>
  );
};
