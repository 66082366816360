import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FaUser, FaEdit } from 'react-icons/fa';

const LeftNavbar = () => {
  const { pathname } = useLocation();
  return (
    <div className="w-fit flex flex-col pb-0 md:pb-12 p-8 md:py-12 border-r border-[#858585]">
      <NavLink
        to="/profile"
        className={`font-semibold flex space-x-2 items-center whitespace-nowrap flex-nowrap ${
          pathname === '/profile' ? 'text-black' : 'text-[#858585]'
        }`}
      >
        <FaUser />
        <span>Profile</span>
      </NavLink>

      <NavLink
        to="/profile/edit"
        className={`font-semibold flex space-x-2 items-center whitespace-nowrap flex-nowrap py-3 mt-2 ${
          pathname === '/profile/edit'
            ? 'text-black'
            : 'text-[#858585]'
        }`}
      >
        <FaEdit className="text-inherit" />
        <span>Edit Profile</span>
      </NavLink>
    </div>
  );
};

export default LeftNavbar;
