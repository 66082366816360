import React from 'react';
import { disciplines } from '../components/Disciplines/data/disciplines';
import DisciplineSection from '../components/Disciplines/DisciplineSection';
import OurDisciplines from '../components/Disciplines/OurDisciplines';
import PageHeader from '../components/Global/PageHeader';

export const Disciplines = () => {
  return (
    <>
      <PageHeader
        page="Disciplines"
        title="RUPI Disciplines"
        description="Learn more about different kinds of disciplines available in RUPI"
      />
      <OurDisciplines />
      {Object.keys(disciplines).map((key, index) => {
        return (
          <DisciplineSection
            key={key}
            id={key}
            isEven={Math.floor(index % 2) === 0}
            discipline={disciplines[key]}
            isLast={index === Object.keys(disciplines).length - 1}
          />
        );
      })}
    </>
  );
};
