import React from 'react';
import PublicationCardList from './PublicationCardList';


const PublicationContainer = () => {
  
  return (
    <div className="flex flex-col overflow-hidden items-center w-full bg-white p-8 py-6 md:py-12">
      <div className="flex flex-col max-w-6xl w-full mx-auto">
        <h1 className="text-lg md:text-3xl font-bold text-gray-800" data-aos = "fade-down">
          Our Publications
        </h1>
        <PublicationCardList />
      </div>
    </div>
  );
};

export default PublicationContainer;
