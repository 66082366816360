export const websiteContents = [
  {
    text: 'How To Apply for Membership at RUPI',
    description:
      'Want to know how to apply at RUPI membership and ejnoy our benefits. watch the tutorial video to know more. application submission',
    url: '/#how-to-apply',
  },
  {
    text: 'Apply Now',
    description:
      'Apply now if you haven`t already and enjoy our benefits. application submission. individual. personal. company. junior. consulting. professional',
    url: '/application',
  },
  {
    text: 'How can we help you?',
    description:
      'We can help you submit your application if you wish to join the community, we can help you pay a membership fee to secure your membership in our network, we provide certification that can support your profession as well as support in research development and we can help you to publish your work.',
    url: '/#how-can-we-help',
  },
  {
    text: 'About RUPI',
    description:
      'Rwanda Urban Planners Institute (RUPI) is a professional body representing urban planners in Rwanda.',
    url: '/about',
  },
  {
    text: 'Benefits of Joining RUPI Membership',
    description:
      'Joining the Rwanda Urban Planners Institute can provide a range of benefits to urban planners, including professional development, networking opportunities, access to resources, advocacy, and recognition.',
    url: '/membership#membership-benefits',
  },
  {
    text: 'The story behind RUPI',
    description:
      'Rwanda Urban Planners Institute (RUPI) is a professional body representing urban planners in Rwanda.',
    url: '/about#story-behind-rupi',
  },
  {
    text: 'Meet Our Team',
    description:
      'Our philosophy is simple, we work with the most talented staff to deliver the best possible outcomes. RUPI committees',
    url: '/about#meet-our-team',
  },
  {
    text: 'What our members say',
    description:
      'Several RUPI members, who already believe in our service. testimonials',
    url: '/membership#testimonials',
  },
  {
    text: 'RUPI Disciplines',
    description:
      'Learn more about different kinds of disciplines available in RUPI. Green Urbanization. Climate Change. Health community.Equity, Diversity and Inclusiveness',
    url: '/disciplines',
  },
  {
    text: 'RUPI Publications',
    description:
      'Publish your work on our site and be able to share it with the world',
    url: '/publications',
  },
  {
    text: 'RUPI Services',
    description: 'PLearn more about services offered in RUPI',
    url: '/services',
  },
  {
    text: 'RUPI Projects',
    description: 'Learn more about RUPI projects',
    url: '/projects',
  },
  {
    text: 'Membership Certificate',
    description:
      'Generate certificate and download in very easy steps to acknowledge that you work very well with us.',
    url: '/certificate',
  },
  {
    text: 'News and Events',
    description:
      'Welcome To Our Blog Learn more about different events and social activities held in RUPI',
    url: '/blog',
  },
  {
    text: 'Your Membership Status',
    description:
      'Check your current membership status and see if it is pending, accepted or rejected',
    url: '/membershipStatus',
  },
  {
    text: 'Your Payments History',
    description:
      'Check your payments history made such an application fee or membership fees',
    url: '/payments',
  },
  {
    text: 'RUPI committees',
    description:
      'Learn more about RUPI Committee and join our amazing community. duties, responsibilities.',
    url: '/committee',
  },
  {
    text: 'RUPI President Quote',
    description: 'President qoute or speech. Vivien Munyaburanga',
    url: '/#president-quote',
  },
  {
    text: 'Licensing Fees',
    description:
      'Licensing membership fees: junior, professional, consulting or company. pay for it',
    url: '/#membershipFees',
  },
  {
    text: 'Why do we help with urbanization?',
    description:
      'Supporting urbanization is essential to create opportunities for economic development, improving infrastructure, promoting environmental sustainability and advancing social equity. Economic development. Infrastructure development. Environmental sustainability. Advancing social equity',
    url: '/#Why-do-we-help',
  },
  {
    text: 'Our achievements',
    description:
      'Our achievements in the journey depicted in numbers, We have a partnership with 100+ amazing companies, We have an experience of working with 30+ amazing companies and organizations, We have a have been in the industry for 10 whole years',
    url: '/#Our-achievements',
  },
  {
    text: 'Our partners',
    description:
      'Our partners, Ministry of Environment, National Land Authority and Rwanda Housing Authority. Partnership',
    url: '/#Our-partners',
  },
  {
    text: 'Our Mission',
    description:
      'is to promote the profession of urban and rural planning in Rwanda through continuous capacity building. Vivien MUNYABURANGA. RUPI President',
    url: '/about#Our-mission',
  },
  {
    text: 'Our Vision',
    description:
      'To be Africa`s most serving Professional Body for community development, through sustainable plans',
    url: '/about#Our-vision',
  },
  {
    text: 'Our Values',
    description:
      'We’re an ambitious and smart team with a shared mission. Serve. Integrity. Collaborative. Innovative',
    url: '/about#Our-values',
  },
  {
    text: 'RUPI Membership Categories',
    description:
      'Members at RUPI are categorized into two different categories which are Individual members and Company / Firm members.',
    url: '/membership#categories',
  },
];
