import React, { useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';

const SearchContentCard = ({ content, onClick }) => {
  const [showArrow, setShowArrow] = useState(false);

  const handleShowArrow = () => {
    setShowArrow(prev => !prev);
  };
  return (
    <div
      className="hover:bg-green p-2 px-6 hover:text-white cursor-pointer"
      onMouseOver={handleShowArrow}
      onMouseOut={handleShowArrow}
      onClick={onClick}
    >
      <h1 className="font-bold text-lg flex items-center space-x-4">
        <span>{content.text}</span>
        {showArrow && (
          <span className="text-sm">
            <FaArrowRight />
          </span>
        )}
      </h1>
      <p className="search-description">{content.description}</p>
    </div>
  );
};

export default SearchContentCard;
