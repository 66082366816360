export const disciplines = {
  urbanization: {
    text: 'Green Urbanization',
    img: 'https://cdn.pixabay.com/photo/2017/02/14/03/03/ama-dablam-2064522_1280.jpg',
    description:
      'Green urbanization focuses on creating environmentally sustainable and livable cities. It involves designing and implementing urban development strategies that promote the use of green infrastructure, renewable energy, and low-carbon transportation systems, among other sustainable practices. The goal of green urbanization is to create cities that are resilient to climate change, reduce greenhouse gas emissions, conserve natural resources, and promote social equity. This can be achieved through various approaches, including compact urban design, mixed-use development, green building design, and green space planning. Green urbanization also involves engaging with local communities and stakeholders to ensure that urban development projects are inclusive and responsive to their needs and priorities.',
  },
  climate: {
    text: 'Climate Change',
    img: 'https://cdn.pixabay.com/photo/2012/08/27/14/19/mountains-55067_1280.png',
    description:
      'Climate change is a major challenge facing urban planners today, as it poses significant threats to the livability, sustainability, and resilience of urban areas. The discipline of climate change in urban planning involves integrating climate change considerations into urban planning and design processes to reduce greenhouse gas emissions, adapt to the impacts of climate change, and promote sustainability. To address climate change in urban planning, planners must consider a range of strategies, including: Mitigation , Adaptation, Planning for sustainability and Public engagement. By integrating climate change considerations into urban planning, planners can help create more sustainable, livable, and resilient cities that are better able to adapt to the challenges of a changing climate.',
  },

  health: {
    text: 'Health community',
    img: 'https://cdn.pixabay.com/photo/2015/12/29/14/51/mountains-1112911_1280.jpg',
    description:
      'The discipline of health community in urban planning focuses on creating communities that promote health and well-being for their residents. It involves designing and implementing urban planning  strategies that improve access to healthcare services, promote active transportation and physical activity, and encourage healthy food environments, among other strategies. The goal of health community in urban planning is to create environments that support healthy behaviors and lifestyles and reduce the prevalence of chronic diseases such as obesity, diabetes, and heart disease. This can be achieved through various approaches, including: Active transportation planning, Access to healthy food, Access to healthcare, Green space planning and Safety planning.',
  },
  equity: {
    text: 'Equity, Diversity and Inclusiveness',
    img: 'https://cdn.pixabay.com/photo/2016/05/24/16/48/mountains-1412683_1280.png',
    description:
      'The discipline of Equity, Diversity, and Inclusiveness (EDI) in urban planning focuses on creating cities that are fair, equitable, and inclusive for all residents, regardless of their race, ethnicity, gender, age, ability, or socioeconomic status. It involves designing and implementing urban planning strategies that promote equity, diversity, and inclusiveness in all aspects of urban life. The goal of EDI in urban planning is to ensure that urban planning decisions are made through a lens of equity, and that all residents have equal access to resources, services, and opportunities. This can be achieved through various approaches, including: Community engagement, Inclusive zoning and housing policies, Accessibility planning, Culturally sensitive planning and Anti-racist planning.', 
  },
};
