import React from 'react';

const ProjectCardLoader = () => {
  return (
    <div
      className="z-10 flex h-full flex-col animate-pulse border border-gray-300 shadow"
      data-aos="fade-up"
    >
      <div className="h-[247px] bg-slate-200 w-full object-cover" />
      <div className="flex flex-col p-2">
        <div className="h-2 bg-slate-200 rounded" />
      </div>
    </div>
  );
};

export default ProjectCardLoader;
