import {
  SAVE_CERTIFICATE,
  GET_CERTIFICATE,
} from '../constants/actionTypes';
import * as api from '../api/index.js';
import Keys from '../constants/keys';

export const saveUserCertificate =
  certificateData => async dispatch => {
    try {
      const result = await api.saveCertificate(certificateData);

      dispatch({
        type: SAVE_CERTIFICATE,
        payload: {
          Certificate: result.data.certificateContent,
        },
      });
    } catch (error) {
      if (error.response && error.response.data) {
        return error.response.data;
      } else {
        return 'Something went wrong. Please try again!';
      }
    }
  };

export const getuserCertificate = certificateId => {
  return async dispatch => {
    try {
      const result = await api.getCertificate(certificateId);
      if (!result.data.certificateData) {
        throw new Error('Failed to fetch the certificate');
      }
      dispatch({
        type: GET_CERTIFICATE,
        payload: {
          Certificate: result.data.certificateData,
        },
      });
    } catch (error) {
      if (error) {
        // console.log(error);
      }
    }
  };
};
