import React from 'react'
import EditProfileContainer from '../components/Profile/EditProfileContainer';

const EditProfilePage = () => {
  
  return (
    <>
      <EditProfileContainer />
    </>
  );
};

export default EditProfilePage;
