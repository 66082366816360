import * as actionType from '../constants/actionTypes';

const initialState = {
  errors: null,
  Certificate: null,
};

const certificateReducer = (state = initialState, action) => {
  switch (action.type) {

    case actionType.SAVE_CERTIFICATE:
    return {
      errors: null,
      Certificate: {...action.payload.Certificate},
    };

    case actionType.GET_CERTIFICATE:
        return {
            errors: null,
            Certificate: {...action.payload.Certificate},
        };
      
    default:
      return state;
  }
};

export default certificateReducer;