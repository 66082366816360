import BagIcon from '../../../../assets/images/icons/svg/BagIcon';
import CircleIcon from '../../../../assets/images/icons/svg/CircleIcon';
import RocketIcon from '../../../../assets/images/icons/svg/RocketIcon';
import UserArrowIcon from '../../../../assets/images/icons/svg/UserArrowIcon';

export const whyData = [
  {
    icon: <CircleIcon />,
    title: 'Economic development',
    description:
      'Urbanization can boost economic development by concentrating businesses and industries in urban areas, attracting investment and entrepreneurial activity.',
  },
  {
    icon: <BagIcon />,
    title: 'Infrastructure development',
    description:
      'Urbanization drives infrastructure development by increasing demand for public services and leading to more efficient delivery of services.',
  },
  {
    icon: <RocketIcon />,
    title: 'Environmental sustainability',
    description:
      'Urbanization can promote environmental sustainability through efficient land use, mixed-use development and sustainable transportation.',
  },
  {
    icon: <UserArrowIcon />,
    title: 'Advancing social equity',
    description:
      'Urbanization can promote social equity by providing greater access to economic opportunities, education and healthcare.',
  },
];
