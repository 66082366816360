import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import success from '../../../../assets/images/application/success.svg';
import { fees } from '../../data/fees';

const Completed = ({ membership = 'individual' }) => {
  const [price, setPrice] = useState(
    JSON.parse(localStorage.getItem('price')),
  );

  useEffect(() => {
    setPrice(JSON.parse(localStorage.getItem('price')));
  }, []);

  return (
    <div className="flex flex-col justify-center items-center md:w-1/2 mx-auto my-10 text-center">
      <img src={success} alt="Filling info success" />
      <h1 className="font-semibold text-lg mt-4">
        Thank you for applying for RUPI membership
      </h1>
      <p className="my-3 md:my-6 text-sm">
        The next step is to pay application fee, proceed to payment in order to make us review your application. We will
        get in touch within the next 24 hours.
      </p>
      <div className="border p-4 mb-8 rounded-md">
        <h1>
          <span className="font-semibold">Application fee:</span>{' '}
          {/* {fees[membership]}/{membership} */}
          {price?.amount} {price?.currency}
        </h1>
      </div>
      <NavLink
        to="/pay"
        className="bg-green text-white shadow-lg rounded-full px-4 py-2 text-xs hover:shadow-none"
      >
        Proceed to Payment
      </NavLink>
    </div>
  );
};

export default Completed;
