import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

const ExploreMenuItem = ({ item }) => {
  const [showExplore, setShowExplore] = useState(false);
  return (
    <div
      className="relative"
      onMouseOver={() => setShowExplore(true)}
      onMouseOut={() => setShowExplore(false)}
    >
      <Link
        to={item.url}
        className="menu-link text-white py-1.5 hover:border-b-[2px] hover:border-white transition duration-300 ease-out text-xs lg:text-[16px] flex items-center space-x-4"
      >
        <span>{item.text}</span>
        {showExplore ? <FaChevronUp /> : <FaChevronDown />}
      </Link>

      {showExplore && (
        <div className="bg-[#0b450c] rounded absolute right-0 top-[30px] p-2 border border-grey-301 shadow-2xl flex flex-col">
          {item.items.map((subItem, indexA) => {
            return (
              <NavLink
                to={subItem.url}
                key={`${indexA}sub-menu${subItem}`}
                className="menu-link text-white py-2 px-4 text-sm hover:opacity-50"
              >
                {subItem.text}
              </NavLink>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ExploreMenuItem;
