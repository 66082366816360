import { GET_TESTIMONIALS } from '../constants/actionTypes';
import * as api from '../api/index.js';

export const getAllTestimonials = () => {
  return async dispatch => {
    try {
      const result = await api.getTestimonials();
      if (!result.data.allTestimonials) {
        throw new Error('Failed to retrieve testimonials');
      }
      dispatch({
        type: GET_TESTIMONIALS,
        payload: {
          testimonials: result.data.allTestimonials,
        },
      });
    } catch (error) {
      // console.log(error);
    }
  };
};
