import React from 'react';

const SearchIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4437 4.18638H11.8618C11.4804 4.18638 11.1641 3.87007 11.1641 3.48864C11.1641 3.1072 11.4804 2.79089 11.8618 2.79089H17.4437C17.8252 2.79089 18.1415 3.1072 18.1415 3.48864C18.1415 3.87007 17.8252 4.18638 17.4437 4.18638Z"
        fill="#008D41"
      />
      <path
        d="M14.6528 6.97739H11.8618C11.4804 6.97739 11.1641 6.66108 11.1641 6.27965C11.1641 5.89822 11.4804 5.58191 11.8618 5.58191H14.6528C15.0342 5.58191 15.3505 5.89822 15.3505 6.27965C15.3505 6.66108 15.0342 6.97739 14.6528 6.97739Z"
        fill="#008D41"
      />
      <path
        d="M9.53582 19.0716C4.27949 19.0716 0 14.7921 0 9.53582C0 4.27949 4.27949 0 9.53582 0C9.91725 0 10.2336 0.31631 10.2336 0.697743C10.2336 1.07918 9.91725 1.39549 9.53582 1.39549C5.04235 1.39549 1.39549 5.05166 1.39549 9.53582C1.39549 14.02 5.04235 17.6761 9.53582 17.6761C14.0293 17.6761 17.6761 14.02 17.6761 9.53582C17.6761 9.15438 17.9925 8.83807 18.3739 8.83807C18.7553 8.83807 19.0716 9.15438 19.0716 9.53582C19.0716 14.7921 14.7921 19.0716 9.53582 19.0716Z"
        fill="#008D41"
      />
      <path
        d="M19.3046 20.0019C19.1278 20.0019 18.9511 19.9367 18.8115 19.7972L16.9509 17.9365C16.6811 17.6667 16.6811 17.2202 16.9509 16.9504C17.2207 16.6806 17.6672 16.6806 17.937 16.9504L19.7977 18.811C20.0675 19.0808 20.0675 19.5274 19.7977 19.7972C19.6581 19.9367 19.4814 20.0019 19.3046 20.0019Z"
        fill="#008D41"
      />
    </svg>
  );
};

export default SearchIcon;
