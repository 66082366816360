import React from 'react';
import PricingCard from './PricingCard/PricingCard';
import { companyPricingData, individualPricingData } from './data';
import { useFetcher } from '../../../api';

const PlanList = ({
  category = 'individual',
  onSelectPlan = plan => {},
  className = 'grid md:grid-cols-3 gap-5 mt-6 md:mt-8 ',
  selected = 'individual',
  label = 'Choose Plan',
}) => {
  const isCompany = category === 'company';

  const { data } = useFetcher('/api/applications/user/mine');

  const [application, setApplication] = React.useState(null);

  React.useEffect(() => {
    if (data) {
      setApplication(data.data);
    }
  }, [data]);

  let plans;
  if(!application || application?.membership === 'none'){
    plans = !isCompany
    ? individualPricingData
    : companyPricingData;
  }
  else{
    plans = application?.category === "individual"
    ? individualPricingData
    : companyPricingData;
  }

  const handleSelector = plan => {
    onSelectPlan(plan);
  };
//  console.log(plans) 
  return (
    <div className={!application || application?.membership === 'none' ? className : 'flex justify-center items-center mt-10 md:mt-12'}>
      {
    !application || application?.membership === 'none'
    ?
    (
    plans.map((element, index) => (
      <PricingCard
        key={element.title}
        noCategory
        data={element}
        isPrimary={element.title.toLocaleLowerCase() === selected}
        selector={() =>
          handleSelector(element.title.toLocaleLowerCase())
        }
        label={label}
      />
    ))
    )
    :
    (
    plans
    .filter((element) => element.membership === application?.membership)
    .map((element, index) => (
      <PricingCard
        key={element.title}
        data={element}
        isPrimary={element.title.toLowerCase() === selected}
        selector={() => handleSelector(element.title.toLowerCase())}
        label={label}
      />
    ))
    )
    }
    </div>
  );
};

export default PlanList;
