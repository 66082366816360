import React from 'react';
import FastMarquee from 'react-fast-marquee';
import { partners } from './data/partners';

export const Partners = () => {
  return (
    <div
      className="py-6 md:py-12 mt-auto flex flex-col items-center w-full bg-gray-301 px-8"
      data-aos="fade-right"
      id="Our-partners"
    >
      <div className="flex flex-col max-w-6xl w-full overflow-x-hidden">
        <FastMarquee direction="right" gradient={false}>
          {partners.map((item, index) => (
            <div
              className="border rounded text-xl font-black mx-4 md:mx-6 border-gray-200 shadow min-w-[170px] min-h-[170px] flex justify-center items-center"
              key={index}
            >
              <img src={item.logo} alt="" className="w-[150px]" />
            </div>
          ))}
        </FastMarquee>
      </div>
    </div>
  );
};
