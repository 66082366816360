import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAllServices } from '../../actions/service';
import ServiceCard from './ServiceCard';
import DataWidget from '../Global/DataWidget';

const ServiceCardList = ({
  services,
  getServices,
  error,
  loading,
}) => {
  useEffect(() => {
    if (!services.length) {
      getServices();
    }
  }, [services]);

  return (
    <DataWidget
      title="Services"
      isError={error}
      isLoading={loading}
      isEmpty={!services.length}
      isWhiteMode={true}
    >
      <div className="grid gap-10 md:gap-16 sm:grid-cols-2 lg:grid-cols-3">
        {services.map((service, index) => (
          <ServiceCard key={index} service={service} />
        ))}
      </div>
    </DataWidget>
  );
};

const mapStateToProps = state => ({
  services: state.service.services,
  error: state.service.error,
  loading: state.service.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    getServices: () => dispatch(getAllServices()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceCardList);
