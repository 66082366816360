import avatar from '../../assets/images/about/avatar.jpeg';

const PresidentQuote = () => {
  return (
    <div
      data-aos="fade-up"
      id="president-quote"
      className="px-3 md:px-20"
    >
      <div className=" bg-gray-301 p-8 md:p-10 md:text-left mb-12">
        <p className='text-center md:text-justify'>
        “ Welcome to the Rwanda Urban Planners Institute! As President of this esteemed organization, 
          it is my pleasure to extend a warm greeting to all our members and visitors. Our institute is 
          dedicated to promoting sustainable urban planning practices and building vibrant communities 
          across Rwanda. We believe that the success of our cities and towns depends on thoughtful, 
          strategic planning and collaboration among stakeholders. As members of the Rwanda Urban Planners 
          Institute, we are committed to advancing these principles and making a positive impact on the 
          built environment. Thank you for visiting our website and we look forward to working with you! ”{' '}
        </p>
        <div className="flex md:space-x-4 space-y-3 items-center md:items-start mt-4 flex-col md:flex-row">
          <img
            src={avatar}
            alt="RUPI President"
            // width={48}
            // height={48}
            className="rounded-full w-20 h-20 object-cover"
          />
          <div className="flex flex-col">
            <h3 className="text-lg font-semibold">
              Vivien MUNYABURANGA
            </h3>
            <p className="text-center md:text-left">RUPI President</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresidentQuote;
