import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';

export const socialMedia = [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/',
    icon: <FaFacebook />,
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/',
    icon: <FaTwitter />,
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/',
    icon: <FaInstagram />,
  },
];

export const footerNavItems = [
  {
    label: 'Home',
    url: '/',
  },
  {
    label: 'About',
    url: '/about',
  },
  {
    label: 'Disciplines',
    url: '/disciplines',
  },
  {
    label: 'Membership',
    url: '/membership',
  },
  {
    label: 'Projects',
    url: '/projects',
  },
  {
    label: 'Services',
    url: '/services',
  },
  {
    label: 'Blog',
    url: '/blog',
  },
  {
    label: 'Publications',
    url: '/publications',
  },
  {
    label: 'CHOGM',
    url: 'https://www.chogm2022.rw/',
  },

];
