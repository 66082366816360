import React from 'react';
import { FaPlus } from 'react-icons/fa';
import VisaIcon from '../../assets/images/icons/svg/VisaIcon';
import MastercardIcon from '../../assets/images/icons/svg/MastercardIcon';
import { getCardType } from '../../lib/helpers/card';
import PopupModal from '../Global/PopupModal';

const AddPaymentMethod = () => {
  const [open, setOpen] = React.useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const [card, setCard] = React.useState({});

  const [state, setState] = React.useState({
    number: '',
    expiry: '',
    cvc: '',
    name: '',
    focus: '',
    month: '',
    year: '',
  });

  const handleInputChange = evt => {
    const { name, value } = evt.target;
    setState(prev => ({ ...prev, [name]: value }));
    if (name === 'number') {
      setCard(prev => ({ ...prev, issuer: getCardType(value) }));
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="flex justify-center text-soft-black space-x-3 items-center rounded-lg border border-gray-402 py-3 px-12 uppercase text-sm font-semibold mt-8 md:mt-12"
      >
        <FaPlus /> <span>Add new card</span>
      </button>
      <PopupModal title="Add New Card" onClose={onClose} show={open}>
        <div className="flex flex-col space-y-6 px-6 pb-4 sm:pb-6 lg:px-8 xl:pb-8 z-50">
          <div className="flex flex-col">
            <label
              htmlFor="name-on-card"
              className="flex relative w-full"
            >
              <input
                className="rounded-lg border border-gray-402 bg-white peer px-3 py-2 focus:border-brand-green w-full focus:placeholder-transparent focus:outline-none bg-transparent"
                placeholder="Name on card"
                id="name-on-card"
                type="text"
                name="name"
                autoComplete="off"
                value={state.name}
                onChange={handleInputChange}
              />
              <span className="peer-focus:block text-xs peer-placeholder-shown:hidden text-brand-green left-3 -top-2 bg-white transition-all absolute">
                Name on card
              </span>
            </label>

            <label
              htmlFor="card-number"
              className="flex items-center relative w-full mt-4"
            >
              <input
                className="rounded-lg border border-gray-402 bg-white peer px-3 py-2 focus:border-brand-green w-full focus:placeholder-transparent focus:outline-none bg-transparent"
                placeholder="Card Number"
                id="card-number"
                type="text"
                name="number"
                autoComplete="off"
                value={state.number}
                onChange={handleInputChange}
              />
              <span className="peer-focus:block text-xs peer-placeholder-shown:hidden text-brand-green left-3 -top-2 bg-white transition-all absolute">
                Card Number
              </span>

              {card.issuer === 'visa' && (
                <VisaIcon className="right-3 absolute" />
              )}
              {card.issuer === 'mastercard' && (
                <MastercardIcon className="right-3 absolute" />
              )}
            </label>

            <div className="flex flex-col mt-4">
              <p className="w-full text-gray-600">Expiry date</p>
              <div className="flex gap-x-4 mt-2">
                <label
                  htmlFor="month"
                  className="flex relative w-full"
                >
                  <input
                    className="rounded-lg border border-gray-402 bg-white peer px-3 py-2 focus:border-brand-green w-full focus:placeholder-transparent focus:outline-none bg-transparent"
                    placeholder="Month"
                    id="month"
                    type="text"
                    name="month"
                    autoComplete="off"
                    value={state.month}
                    onChange={handleInputChange}
                  />
                  <span className="peer-focus:block text-xs peer-placeholder-shown:hidden text-brand-green left-3 -top-2 bg-white transition-all absolute">
                    Month
                  </span>
                </label>
                <label
                  htmlFor="year"
                  className="flex relative w-full"
                >
                  <input
                    className="rounded-lg border border-gray-402 bg-white peer px-3 py-2 focus:border-brand-green w-full focus:placeholder-transparent focus:outline-none bg-transparent"
                    placeholder="Year"
                    id="year"
                    type="text"
                    name="year"
                    autoComplete="off"
                    value={state.year}
                    onChange={handleInputChange}
                  />
                  <span className="peer-focus:block text-xs peer-placeholder-shown:hidden text-brand-green left-3 -top-2 bg-white transition-all absolute">
                    Year
                  </span>
                </label>
              </div>
            </div>
            <label
              htmlFor="cvc"
              className="flex relative w-full mt-4"
            >
              <input
                className="rounded-lg border border-gray-402 bg-white peer px-3 py-2 focus:border-brand-green w-full focus:placeholder-transparent focus:outline-none bg-transparent"
                placeholder="CVC"
                id="cvc"
                type="text"
                name="cvc"
                autoComplete="off"
                value={state.cvc}
                onChange={handleInputChange}
              />
              <span className="peer-focus:block text-xs peer-placeholder-shown:hidden text-brand-green left-3 -top-2 bg-white transition-all absolute">
                CVC
              </span>
            </label>
          </div>
          <div className="w-full">
            <button className="bg-brand-green text-white">
              Add Card
            </button>
          </div>
        </div>
      </PopupModal>
      {/* <Modal
        size="md"
        position="center-right"
        className="md:overflow-y-hidden"
      >
        <Modal.Body className="md:min-h-[90vh] overflow-y-hidden"></Modal.Body>
      </Modal> */}
    </>
  );
};

export default AddPaymentMethod;
