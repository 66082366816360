import React from 'react';
import PlanList from './PlanList';
import { companyPricingData, individualPricingData } from './data';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Keys from '../../../constants/keys';
import { useFetcher } from '../../../api';

export const MembershipFees = () => {
  const authenticatedUser = useSelector(
    state => state.auth.presentUser,
  );
  const navigate = useNavigate();
  const [category, setCategory] = React.useState('individual');
  const isCompany = category === 'company';

  const { data } = useFetcher('/api/applications/user/mine');

  const [application, setApplication] = React.useState(null);

  const userPayments = useSelector(
    state => state.payment.allUserPayments,
  );
  const applicationFeePayment = userPayments?.filter(
    payment => payment.paymentPlan === 'Application Fee',
  );
  const latestFeePayment = applicationFeePayment?.length - 1;
  const validMembershipPaymentPlans = ["Junior", "Professional", "Consulting", "Company"];
  const membershipFeePayment = userPayments?.filter(payment => validMembershipPaymentPlans.includes(payment.paymentPlan)) || [];
  const latestPayment = membershipFeePayment.length - 1;

  React.useEffect(() => {
    if (data) {
      setApplication(data.data);
    }
  }, [data, authenticatedUser]);

  const defaultPlan = isCompany
  ? companyPricingData[0].title.toLocaleLowerCase()
  : individualPricingData[1].title.toLocaleLowerCase();

  let label = 'Pay now';
  if (membershipFeePayment && membershipFeePayment?.length !== 0 && membershipFeePayment[latestPayment].transactionStatus === "Success") {
    label = 'Renew';
  }

  return (
    <>
      <div
        className="py-6 md:py-12 mt-auto flex flex-col items-center w-full bg-white px-8"
        id="membershipFees"
      >
        <div className="flex flex-col max-w-6xl">
          
            {
            !application || application?.membership === 'none' ? (
            <h1
              className="text-3xl text-center md:text-4xl font-bold tracking-wide max-w-xl mx-auto"
              data-aos="fade-up"
            >
            Our Licencing Fees
            </h1>
            ) : (
              <h1
                className="text-3xl text-center md:text-4xl font-bold tracking-wide max-w-xl mx-auto"
                data-aos="fade-up"
              >
            Pay Licencing Fee
              </h1>
            )
            }
          

        {
          !application || application?.membership === 'none' ? (
              <p
                className="max-w-xl mx-auto text-center text-grey-601 mt-3"
                data-aos="fade-up"
              >
                We offer different plans based on the category and type of our members.
              </p>
            ) : null
        }

        {
          !application || application?.membership === 'none' ? (
          <div
            className="mt-6 flex items-center space-x-6 md:space-x-12 justify-center overflow-x-auto"
            data-aos="zoom-in"
          >
            <button
              type="button"
              className={`${
                !isCompany ? 'bg-brand-green' : 'bg-brand-green/40'
              } text-white font-semibold px-4 py-3 rounded-md whitespace-nowrap`}
              onClick={() => setCategory('individual')}
            >
              Individual
            </button>
            <button
              type="button"
              className={`${
                isCompany ? 'bg-brand-green' : 'bg-brand-green/40'
              } text-white font-semibold px-4 py-3 rounded-md whitespace-nowrap`}
              onClick={() => setCategory('company')}
            >
              Company
            </button>
          </div>
          ) : null
        }

          <PlanList
            label={label}
            category={category}
            // selected={defaultPlan}
            className={  
              isCompany
                ? 'flex flex-col md:flex-row justify-center space-y-8 md:space-x-8 md:space-y-0 mt-8'
                : undefined
            }
            onSelectPlan={plan => {
              const link = '/pay';
              if (!authenticatedUser) {
                localStorage.setItem(Keys.REDIRECT_URL_KEY, link);
                navigate('/login');
                return;
              }
              if (authenticatedUser && !application) {
                navigate('/application');
                return;
              }
              if (
                applicationFeePayment &&
                applicationFeePayment?.length == 0
              ) {
                navigate('/application?step=4');
              }
              if (
                applicationFeePayment &&
                applicationFeePayment?.length !== 0 &&
                applicationFeePayment[latestFeePayment]?.transactionStatus !== 'Success'
              ) {
                navigate('/application?step=4');
              }
              if (
                applicationFeePayment &&
                applicationFeePayment?.length !== 0 &&
                applicationFeePayment[latestFeePayment]?.transactionStatus === 'Success'
              ) {
                navigate('/pay');
              } 
            }}
          />
        </div>
      </div>
    </>
  );
};
