import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  FaArrowDown,
  FaCartArrowDown,
  FaChevronDown,
  FaChevronUp,
  FaFacebookSquare,
  FaInstagramSquare,
  FaPhoneAlt,
  FaTwitterSquare,
} from 'react-icons/fa';

const MobileMenu = ({ menuITems }) => {
  const [showExplore, setShowExplore] = useState(false);
  return (
    <>
      <div className="flex justify-end">
        <div className="border-b-[#000000bd] border-b-[20px] border-l-[15px] border-r-[15px] border-r-transparent border-l-transparent w-0 h-0 mr-4"></div>
      </div>
      <div className="bg-[#000000bd] pb-8">
        <nav className="flex flex-col">
          {menuITems.map((item, index) => {
            if (!item.url) {
              return (
                <React.Fragment key={`${index}`}>
                  <Link
                    to={item.url}
                    onClick={() => setShowExplore(prev => !prev)}
                    className="menu-link-mobile py-3 px-8 hover:border-b-[2px] transition duration-300 ease-out flex items-center justify-between"
                  >
                    <span>{item.text}</span>
                    {showExplore ? (
                      <FaChevronUp />
                    ) : (
                      <FaChevronDown />
                    )}
                  </Link>
                  {showExplore &&
                    item.items.map((subItem, indexA) => {
                      return (
                        <NavLink
                          to={subItem.url}
                          key={`${indexA}sub-menu${subItem}`}
                          className="menu-link-mobile py-3 px-8 hover:border-b-[2px] transition duration-300 ease-out"
                        >
                          {subItem.text}
                        </NavLink>
                      );
                    })}
                </React.Fragment>
              );
            }
            return (
              <NavLink
                to={item.url}
                key={index}
                className="menu-link-mobile py-3 px-8 hover:border-b-[2px] transition duration-300 ease-out"
              >
                {item.text}
              </NavLink>
            );
          })}
        </nav>
        <div className="flex justify-center my-4 items-center space-x-2">
          <FaPhoneAlt />
          <p>+250783453211</p>
        </div>
        <div className="flex justify-center text-xl space-x-16 my-2">
          <FaFacebookSquare />
          <FaInstagramSquare />
          <FaTwitterSquare />
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
