import React from 'react';

const VisaIcon = ({ ...props }) => {
  return (
    <svg
      width="35"
      height="21"
      viewBox="0 0 35 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.7213 2.99292H12.5992V18.0934H21.7213V2.99292Z"
        fill="#FF5F00"
      />
      <path
        d="M13.1783 10.5468C13.1771 9.09279 13.535 7.65753 14.2248 6.34969C14.9147 5.04185 15.9185 3.89568 17.1603 2.99793C15.6223 1.88459 13.7752 1.19227 11.8303 1.00009C9.88532 0.80792 7.9209 1.12364 6.16153 1.91118C4.40217 2.69872 2.91883 3.9263 1.88107 5.45362C0.843301 6.98094 0.292969 8.74638 0.292969 10.5482C0.292969 12.3499 0.843301 14.1154 1.88107 15.6427C2.91883 17.17 4.40217 18.3976 6.16153 19.1851C7.9209 19.9727 9.88532 20.2884 11.8303 20.0962C13.7752 19.9041 15.6223 19.2117 17.1603 18.0984C15.9181 17.2004 14.9141 16.0538 14.2242 14.7454C13.5343 13.4371 13.1767 12.0013 13.1783 10.5468V10.5468Z"
        fill="#EB001B"
      />
      <path
        d="M34.0291 10.5472C34.029 12.3491 33.4786 14.1147 32.4406 15.642C31.4027 17.1694 29.9191 18.397 28.1595 19.1844C26.3999 19.9718 24.4353 20.2873 22.4902 20.0949C20.5452 19.9024 18.6981 19.2098 17.1602 18.0961C18.4015 17.1975 19.405 16.0511 20.0951 14.7431C20.7851 13.4352 21.1437 12 21.1437 10.5459C21.1437 9.09168 20.7851 7.65649 20.0951 6.34857C19.405 5.04065 18.4015 3.89417 17.1602 2.99562C18.6983 1.88179 20.5456 1.1891 22.491 0.996755C24.4363 0.804405 26.4011 1.12016 28.1608 1.90791C29.9205 2.69566 31.404 3.92361 32.4418 5.45138C33.4795 6.97914 34.0296 8.74505 34.0291 10.5472Z"
        fill="#F79E1B"
      />
      <path
        d="M33.0271 16.4931V16.1836H33.1632V16.1223H32.8244V16.1863H32.9605V16.4957L33.0271 16.4931ZM33.696 16.4931V16.1196H33.6063L33.4846 16.3864L33.363 16.1196H33.2559V16.4931H33.3167V16.213L33.4296 16.4557H33.5078L33.6208 16.213V16.4957L33.696 16.4931Z"
        fill="#F79E1B"
      />
    </svg>
  );
};

export default VisaIcon;
