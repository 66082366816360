import React from 'react';
import ProjectCardList from './ProjectCardList';


const ProjectContainer = () => {
  
  return (
    <div className="flex flex-col overflow-hidden items-center w-full bg-white p-8 py-6 md:py-12">
      <div className="flex flex-col max-w-6xl w-full mx-auto">
        <ProjectCardList />
      </div>
    </div>
  );
};

export default ProjectContainer;
