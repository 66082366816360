const BenefitCard = ({ number, text, icon, card, badge }) => {
  return (
    <div className={`absolute ${card} md:w-auto`}>
      <div className="bg-white shadow-lg p-4 relative md:w-52 lg:w-64 rounded">
        <h1 className="text-3xl font-semibold">{number}+</h1>
        <div className="flex text-sm space-x-1.5 ml-2 mt-1">
          <div className="bg-green w-1.5 h-5"></div>
          <p>{text}</p>
        </div>
        <div
          className={`hidden md:block absolute w-12 h-12 ${badge}`}
        >
          <img src={icon} alt={text} />
        </div>
      </div>
    </div>
  );
};

export default BenefitCard;
