export function getCardType(cardNumber) {
  // Remove any spaces or dashes from the card number
  cardNumber = cardNumber.replace(/[^\d]/g, '');

  // Check if the card number starts with 4 for Visa or 51-55 for Mastercard
  if (/^4/.test(cardNumber)) {
    return 'visa';
  } else if (/^5[1-5]/.test(cardNumber)) {
    return 'mastercard';
  } else {
    return 'unknown';
  }
}
