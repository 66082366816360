import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai"
import { useSelector } from 'react-redux';

const PayMembershipFees = () => {
    const [typedText, setTypedText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isShown, setIsShown] = useState(true);
    const authenticatedUser = useSelector(
        state => state.auth.presentUser,
      );
    const name = authenticatedUser?.firstName
  
    const textToType =
      `Hi ${name}, RUPI team here! Congratulations! You recently applied for RUPI membership and your application was accepted. The next step is to pay the required licencing fee to secure your RUPI Membership Certificate. `;
    
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        if (currentIndex < textToType.length) {
          setTypedText((prevText) => prevText + textToType[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
          clearInterval(intervalId);
        }
      }, 50);
  
      return () => clearInterval(intervalId);
    }, [currentIndex, textToType]);
  
    return (
        <>
        {  isShown && <div className="fixed z-50 flex items-start justify-end top-2 right-2">
            <div className="bg-white  rounded w-[370px]">
            <div className="relative p-3">
                <div className="absolute right-3 top-3">
                <AiFillCloseCircle className="text-2xl text-red-500 hover:cursor-pointer" onClick={() => setIsShown(false) }/>
                </div>
                <div className="p-3">
                {typedText}
                </div>
            </div>
            </div>
        </div>
        
            }
        </>
    );
  };
  
  export default PayMembershipFees;
