import React from 'react';

const NextButton = ({
  onClick,
  type = 'button',
  disabled = false,
  label = 'Next Step',
  className = '',
}) => {
  return (
    <button
      disabled={disabled}
      type={type}
      className={`text-white disabled:cursor-not-allowed disabled:opacity-25 bg-blue py-1 px-4 text-xs min-[377px]:text-sm rounded-full min-[377px]:px-6 min-[377px]:py-2 hover:shadow-lg ${className}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};

export default NextButton;
