import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { dashes, dots, services } from './data';
import { FaArrowRight } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useFetcher } from '../../../api';
import Keys from '../../../constants/keys';

export const Services = () => {
  const [selectedService, setSelectedService] = useState(0);
  const authenticatedUser = useSelector(
    state => state.auth.presentUser,
  );
  const userPayments = useSelector(
    state => state.payment.allUserPayments,
  );
  const applicationFeePayment = userPayments?.filter(
    payment => payment.paymentPlan === 'Application Fee',
  );
  const latestFeePayment = applicationFeePayment?.length - 1;
  const { data } = useFetcher('/api/applications/user/mine');
  const [application, setApplication] = useState(null);

  useEffect(() => {
    if (data) {
      setApplication(data.data);
    }
  }, [data]);

  return (
    <div
      className="px-5 md:px-16 py-8 text-center bg-gray-301"
      data-aos="fade-right"
      id="how-can-we-help"
    >
      <h1 className="font-bold text-2xl">How can we help you?</h1>
      <p className="py-6 text-sm text-neutral-500">
        Submit your application if you wish to join
        the community, Pay a licencing fee to secure
        your membership in our network, Get a certificate that
        can support your profession and Publish your work to share it with the world.
      </p>
      <div className="mt-4 flex md:flex-col space-x-6 md:space-x-0 justify-center">
        <div className="flex justify-center mb-3 md:mb-12 flex-col md:flex-row md:hidden">
          {Object.keys(services).map((i, index) => {
            const service = services[i];
            const isLast = Object.keys(services).length - 1 == index;
            return (
              <div
                className="flex items-center flex-col md:flex-row"
                key={index}
                onClick={() => setSelectedService(i)}
              >
                <div
                  className={`h-6 w-6  border border-green rounded-full mx-2 ${
                    selectedService == i ? 'bg-green' : 'bg-white'
                  }`}
                ></div>

                {!isLast && dots}
              </div>
            );
          })}
        </div>
        <div className="flex flex-col justify-start space-y-7 md:space-y-0 md:flex-row md:justify-center md:space-x-0 text-left md:text-center">
          {Object.keys(services).map((i, index) => {
            const service = services[i];
            return (
              <div
                className="flex flex-col md:items-center md:space-y-9"
                onClick={() => setSelectedService(i)}
                key={index}
              >
                <div className="hidden md:flex items-center">
                  <span
                    className={`flex ${
                      index === 0 ? 'opacity-0' : ''
                    }`}
                  >
                    {dashes}
                  </span>
                  <div
                    className={`h-6 w-6  border border-green rounded-full mx-2 ${
                      selectedService == i ? 'bg-green' : 'bg-white'
                    }`}
                  ></div>
                  <span
                    className={`flex ${
                      index === Object.keys(services).length - 1
                        ? 'opacity-0'
                        : ''
                    }`}
                  >
                    {dashes}
                  </span>
                </div>

                <NavLink
                  to={
                  !authenticatedUser && service.url == '/publications' ? service.url : 
                  !authenticatedUser && service.url !== '/publications' ? "/login" : 
                  authenticatedUser && !application && service.url == '/certificate' ? '/application' : 
                  authenticatedUser && !application && service.url == '/pay' ? '/application' : 
                  authenticatedUser && application && applicationFeePayment && applicationFeePayment?.length == 0 && service.url == '/application' ? '/application?step=4' :
                  authenticatedUser && application && applicationFeePayment && applicationFeePayment?.length !== 0 && applicationFeePayment[latestFeePayment]?.transactionStatus !== 'Success' && service.url == '/application' ? '/application?step=4' : 
                  authenticatedUser && application && applicationFeePayment && applicationFeePayment?.length !== 0 && applicationFeePayment[latestFeePayment]?.transactionStatus === 'Success' && service.url == '/application' ? '/membershipStatus' : 
                  authenticatedUser && application && applicationFeePayment && applicationFeePayment?.length == 0 && service.url == '/pay' ? '/application?step=4'  : 
                  authenticatedUser && application && applicationFeePayment && applicationFeePayment?.length !== 0 && applicationFeePayment[latestFeePayment]?.transactionStatus !== 'Success' && service.url == '/pay' ? '/application?step=4'  : 
                  authenticatedUser && application && application.status !=="APPROVED" && applicationFeePayment && applicationFeePayment?.length == 0 && service.url == '/certificate' ? '/application?step=4' :
                  authenticatedUser && application && application.status !=="APPROVED" && applicationFeePayment && applicationFeePayment?.length !== 0 && applicationFeePayment[latestFeePayment]?.transactionStatus !== 'Success' && service.url == '/certificate' ? '/application?step=4' :  
                  authenticatedUser && application && application.status !=="APPROVED" && applicationFeePayment && applicationFeePayment?.length !== 0 && applicationFeePayment[latestFeePayment]?.transactionStatus === 'Success' && service.url == '/certificate' ? '/membershipStatus' :  
                  service.url
                } 
                  onClick={() => !authenticatedUser && service.url !== '/publications' ? localStorage.setItem(Keys.REDIRECT_URL_KEY, service.url) : ''}
                  className={`text-sm font-semibold cursor-pointer ${
                    selectedService == i ? 'text-green' : ''
                  }`}
                  key={index}
                >
                  {service.title}
                </NavLink>
              </div>
            );
          })}
        </div>
      </div>
      <div className="text-center mt-12">
        <NavLink
          to="/services"
          className="text-sm underline text-green font-semibold flex justify-center text-center items-center space-x-4"
        >
          <span>See More</span>
          <FaArrowRight />
        </NavLink>
      </div>
    </div>
  );
};
