import { FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa';

const TeamMember = ({ member }) => {
  return (
    <div
      className="relative flex flex-col items-center"
      data-aos="fade-up"
    >
      <div className="flex flex-col bg-black/30 backdrop-blur border-black border absolute min-h-[100px] p-4 bottom-0 left-0 right-0">
        <div className="flex flex-col items-center text-center">
          <h2 className="text-xl font-bold text-white">
            {member.name}
          </h2>
          <p className="text-white mt-3">{member.position}</p>

          <div className="mt-4 hidden flex-wrap items-center space-x-3">
            {member.facebookProfile && (
              <a
                href={member.facebookProfile}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white p-2 rounded-full hover:bg-green-401"
              >
                <FaFacebook size={24} />
              </a>
            )}
            {member.twitterProfile && (
              <a
                href={member.twitterProfile}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white p-2 rounded-full hover:bg-green-401"
              >
                <FaTwitter size={24} />
              </a>
            )}
            {member.linkedlinProfile && (
              <a
                href={member.linkedlinProfile}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white p-2 rounded-full hover:bg-green-401"
              >
                <FaLinkedin size={24} />
              </a>
            )}
          </div>
        </div>
      </div>
      <img
        src={member.image}
        alt={member.name}
        className="w-full h-full object-cover"
      />
    </div>
  );
};

export default TeamMember;
