import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import { Footer } from './components/Home/Footer/Footer';
import Nav from './components/Home/navbar/Nav';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { Blog } from './pages/blog/Blog';
import { Membership } from './pages/Membership';
import { Projects } from './pages/Projects';
import { Publication } from './pages/Publication';
import { Services } from './pages/Services';
import { Disciplines } from './pages/Disciplines';
import Certificate from './pages/Certificate';
import CertificateData from './pages/CertificateData';
import { BackToTop } from './components/Global/BackToTop';
import PendingTransaction  from './components/Global/PendingTransaction';
import Application from './pages/application/Application';
import SignUp from './pages/authentication/SignUp';
import Login from './pages/authentication/Login';
import NotFoundPage from './pages/NotFound';
import Pay from './pages/Pay';
import Payments from './pages/Payments';
import MembershipStatus from './pages/MembershipStatus';
import PaymentMethods from './pages/PaymentMethods';
import Author from './pages/blog/Author';
import SingleBlog from './pages/blog/SingleBlog';
import { ViewProjectPage } from './pages/ViewProjectPage';
import NewPublicationPage from './pages/NewPublicationPage';
import ProfilePage from './pages/ProfilePage';
import EditProfilePage from './pages/EditProfilePage';
import GetCertificate from './components/Global/GetCertificate';
import PayMembershipFees from './components/Global/PayMembershipFees';
import { EmailVerified } from './pages/authentication/EmailVerified';
import { ForgotPassword } from './pages/authentication/ForgotPassword';
import { ResetPassword } from './pages/authentication/ResetPassword';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { useSelector } from 'react-redux';
import { useFetcher } from './api';
import { Committee } from './pages/Committee';

const App = () => {
  const { pathname } = useLocation();
  const { data } = useFetcher('/api/applications/user/mine');
  const [application, setApplication] = useState(null);
  const [shouldShow, setShouldShow] = useState(false);
  const authenticatedUser = useSelector(
    state => state.auth.presentUser,
  );

  const userPayments = useSelector(
    state => state.payment.allUserPayments,
  );
  const applicationFeePayment = userPayments?.filter(
    payment => payment.paymentPlan === 'Application Fee',
  );
  const validMembershipPaymentPlans = ["Junior", "Professional", "Consulting", "Company"];
  const membershipFeePayment =
    userPayments?.filter(payment =>
      validMembershipPaymentPlans.includes(payment.paymentPlan),
    ) || [];
  const latestPayment = membershipFeePayment.length - 1;
  const [pendingPayment, setPendingPayment] = useState(JSON.parse(localStorage.getItem("paymentDetails")))

  useEffect(() => {
        setPendingPayment(JSON.parse(localStorage.getItem("paymentDetails")))
    }, []);

  useEffect(() => {
    if (data) {
      setApplication(data.data);
    }
  }, [data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldShow(true);
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/author/:id" element={<Author />} />
        <Route path="/blog/:slug" element={<SingleBlog />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/projects/:slug" element={<ViewProjectPage />} />
        <Route path="/publications" element={<Publication />} />
        <Route
          path="/publications/new"
          element={<NewPublicationPage />}
        />
        <Route path="/services" element={<Services />} />
        <Route path="/committee" element={<Committee />} />
        <Route path="/disciplines" element={<Disciplines />} />
        <Route path="/application" element={<Application />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/pay" element={<Pay />} />
        <Route path="/payments" element={<Payments />} />
        <Route
          path="/membershipStatus"
          element={<MembershipStatus />}
        />
        <Route path="/certificate" element={<Certificate />} />
        <Route
          path="/certificate/:id"
          element={<CertificateData />}
        />
        <Route path="/payment-methods" element={<PaymentMethods />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/profile/edit" element={<EditProfilePage />} />
        <Route path="/verified" element={<EmailVerified />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword" element={<ResetPassword />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer />
      <BackToTop />
      <ToastContainer />
      {pendingPayment && <PendingTransaction />}
      {authenticatedUser &&
        application &&
        application.status === 'APPROVED' &&
        !application.hasCertificate &&
        applicationFeePayment &&
        applicationFeePayment?.length !== 0 &&
        membershipFeePayment &&
        membershipFeePayment?.length !== 0 &&
        membershipFeePayment[latestPayment].transactionStatus === 'Success' &&
        shouldShow && <GetCertificate />}
      {authenticatedUser &&
        application &&
        application.status === 'APPROVED' &&
        !application.hasCertificate &&
        applicationFeePayment &&
        applicationFeePayment?.length !== 0 &&
        membershipFeePayment &&
        membershipFeePayment?.length == 0 &&
        shouldShow && <PayMembershipFees />}
    </>
  );
};

export default App;

