import { useEffect, memo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import NextButton from '../../elements/NextButton';
import PrevButton from '../../elements/PrevButton';
import CompanyInformation from './CompanyInformation';
import PersonalInformation from './PersonalInformation';
import PulseLoader from "react-spinners/PulseLoader";
import {
  individualInfoShema,
  companyInfoShema,
} from '../../../../../lib/schema/application';

const Informations = ({
  membership,
  onPrev,
  onNext,
  information,
  loading = false,
}) => {
  const isCompany = membership === 'company';
  const [referees, setReferees] = useState(
    information?.referees || [],
  );

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(
      !isCompany ? individualInfoShema : companyInfoShema,
    ),
  });
  // console.log(errors);
  const onSubmit = async query => {
    const { referee, ...others } = query;
    if (referees.length || referee) {
      const isIncluded = referees.some(
        item => JSON.stringify(item) === JSON.stringify(referee),
      );
      if (!isIncluded) {
        referees.push(referee);
      }
      others.referees = referees;
    }
    onNext(others);
  };

  const onAddReferee = () => {
    const newReferee = getValues('referee');
    const isIncluded = referees.some(
      item => JSON.stringify(item) === JSON.stringify(newReferee),
    );
    if (isIncluded) {
      return;
    }
    const allKeysHaveValues = Object.values(newReferee).every(
      value => value,
    );

    if (newReferee && allKeysHaveValues) {
      setReferees(prev => [...prev, newReferee]);
      setValue(
        'referee',
        {
          name: undefined,
          email: undefined,
          phoneNumber: undefined,
          relationship: undefined,
          position: undefined,
          institution: undefined,
        },
      );
      setFocus('referee.name', { shouldSelect: true });
    }
  };

  const onRemoveReferee = index => {
    setReferees(prev => prev.filter((_, idx) => idx !== index));
  };

  useEffect(() => {
    if (information) {
      const { referees, ...others } = information;
      reset(others);
    }
  }, [information]);

  return (
    <form
      onSubmit={event => {
        handleSubmit(onSubmit)(event);
      }}
    >
      <div>
        {isCompany ? (
          <CompanyInformation
            register={register}
            errors={errors}
            setValue={setValue}
          />
        ) : (
          <PersonalInformation
            referees={referees}
            register={register}
            errors={errors}
            setValue={setValue}
            onAddReferee={onAddReferee}
            onRemoveReferee={onRemoveReferee}
          />
        )}
      </div>
      <div>
        <div className="flex justify-between mt-16 mb-8">
          <PrevButton disabled={loading} onClick={onPrev} />
          <NextButton type="submit" label={loading ? 
          <PulseLoader
          color="#ffffff"
          loading={true}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
        />: 'Next Step'} />
        </div>
      </div>
    </form>
  );
};

export default memo(Informations);
