import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// const blogA = {
//   title: 'Today we are celebrating 10 years of serving our  clients',
//   slug: 'slug-slug-wifi',
//   category: 'JUST-IN',
//   img: 'https://cdn.pixabay.com/photo/2023/04/13/16/51/girls-7922980_1280.jpg',
// };

const FeaturedBlogCard = ({ blog, onClose }) => {
  const handleClick = () => {
    localStorage.setItem('blog', blog.title);
    onClose();
  };
  return (
    <div
      className="w-[280px] sm:w-96 fixed bottom-20 right-5 z-50 flex flex-row-reverse items-start"
      data-aos="fade-up"
    >
      <div
        className="bg-green text-white p-1.5 rounded-full -mt-3 cursor-pointer"
        onClick={handleClick}
      >
        <FaTimes />
      </div>
      <div className="border-t-green border-t-[20px] border-r-[15px] border-r-transparent w-0 h-0"></div>
      <div className="bg-green flex shadow text-white">
        <img
          src={blog.postImage}
          width={120}
          className="object-cover p-1"
        />
        <div className="p-2">
          <h1 className="">
            {blog.category.name}: {blog.title}
          </h1>
          <Link
            to={`/blog/${blog.slug}`}
            className="text-sm underline text-slate-100"
            onClick={handleClick}
          >
            Learn More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FeaturedBlogCard;
