import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAllMembers } from '../../actions/staff';
import TeamMember from './TeamMember';

// ----------------------------------------------------------------

function groupMembersByDepartment(members) {
  const membersByCategory = {};
  members.forEach(member => {
    if (membersByCategory[member.category]) {
      membersByCategory[member.category].push(member);
    } else {
      membersByCategory[member.category] = [member];
    }
  });
  return membersByCategory;
}

// ----------------------------------------------------------------

const OurTeam = ({ members, getMembers }) => {
  const [currentTeam, setCurrentTeam] = useState('');

  const filtered = groupMembersByDepartment(members);

  useEffect(() => {
    if (members.length === 0) {
      getMembers();
    } else {
      setCurrentTeam(Object.keys(filtered)[0]);
    }
  }, [members]);

  return (
    <div
      className="mt-auto flex flex-col items-center w-full bg-white px-8 py-6 md:py-12"
      data-aos="fade-up"
    >
      <div
        className="flex flex-col max-w-6xl w-full"
        id="meet-our-team"
      >
        <h1 className="text-center tracking-wide font-black text-3xl md:text-4xl">
          Meet Our Team
        </h1>
        <p className="max-w-xl text-center mx-auto mt-3 md:mt-4">
          Our philosophy is simple, we work with the most talented
          staff to deliver the best possible outcomes.
        </p>

        <div className="my-6 md:my-10 hidden md:inline-grid sm:grid-cols-2 lg:grid-cols-3 gap-y-2 gap-x-3 justify-between">
          {Object.keys(filtered).map((team, index) => (
            <button
              type="button"
              className={`${
                currentTeam === team
                  ? 'bg-brand-green'
                  : 'bg-brand-green/70'
              } text-white text-sm font-semibold px-4 py-3 rounded-md whitespace-nowrap`}
              onClick={() => setCurrentTeam(team)}
              key={index}
            >
              {team}
            </button>
          ))}
        </div>

        <select
          className="my-6 md:hidden w-full bg-white border border-gray-300 rounded-md py-2 px-3 text-gray-800 text-sm focus:outline-none focus:ring-brand-green focus:border-brand-green"
          onChange={event => {
            const matchedCategory = Object.keys(filtered).find(
              category => category === event.target.value,
            );
            setCurrentTeam(matchedCategory);
          }}
        >
          {Object.keys(filtered).map((category, index) => (
            <option
              key={index}
              value={category}
              className={`whitespace-nowrap ${
                currentTeam === category ? 'text-brand-green' : ''
              }`}
            >
              {category}
            </option>
          ))}
        </select>

        {filtered[currentTeam] && (
          <div className="grid gap-5 gap-x-10 grid-cols sm:grid-cols-2 lg:grid-cols-3">
            {filtered[currentTeam].map((member, index) => (
              <TeamMember key={index} member={member} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  members: state.member.members,
});

const mapDispatchToProps = dispatch => {
  return {
    getMembers: () => dispatch(getAllMembers()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OurTeam);
