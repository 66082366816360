import React, { useEffect, useState } from 'react';
import LeftNavbar from './LeftNavbar';
import cover from '../../assets/images/profile/cover.png';
import { useNavigate } from 'react-router-dom'; 
import { Preloader } from '../Global/Preloader';
import { getLoggedInUser } from '../../actions/auth';
import { connect } from 'react-redux';

const ProfileContainer = ({  user, getUser }) => {
  const navigate = useNavigate();
  const [imageError, setImageError] = useState(false);

  if (user && user == undefined) {
    navigate('/')
  }

  useEffect(() => {
      getUser();
  }, []);

  if(!user) {
    return <Preloader />
  }

  return (
    <div className="flex flex-col md:flex-row">
      <LeftNavbar />

      <div className="flex flex-col overflow-hidden items-center w-full bg-white p-8 py-6 md:py-12">
        <div
          className="flex flex-col items-center max-w-4xl min-h-[403px] w-full mx-auto relative bg-center bg-cover bg-no-repeat"
          style={{ backgroundImage: `url(${cover})` }}
        >
          <div className="profile-shadow translate-y-8 rounded-[28px] max-w-2xl w-full p-4 md:px-8 flex flex-col bottom-0 items-center absolute profile-shadow bg-white">
            {
              user?.picture && !imageError
               ?
              <img
              src={user?.picture.url}
              alt=""
              width={100}
              height={100}
              className="w-20 h-20 rounded-full -translate-y-16"
              onError={() => setImageError(true)}
            />
              :
            <div className='bg-green p-3 w-20 h-20 text-3xl font-bold leading-[55px] -translate-y-14 text-center rounded-full'>
              { user?.firstName.charAt(0) + user?.lastName.charAt(0) }
            </div>
            }
            <h1 className="font-semibold -translate-y-8 text-lg md:text-xl">
              { user?.name }
            </h1>
            <p className="text-sm text-gray-600 -translate-y-2">
            { user?.bio ? user?.bio : ''}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center w-full max-w-4xl mt-24">
          <div className="w-full max-w-2xl space-y-3 flex flex-col px-4 md:px-8 border-l-4 border-brand-green">
            <p className="text-[#787878]">
              <span className="font-bold text-black">Names:</span>{' '}
              { user?.name }
            </p>
            <p className="text-[#787878]">
              <span className="font-bold text-black">Email:</span>{' '}
              { user?.email }
            </p>
            <p className="text-[#787878]">
              <span className="font-bold text-black">Role:</span>{' '}
              { user?.role }
            </p>
            {
            user?.company && (<p className="text-[#787878]">
              <span className="font-bold text-black">Company:</span>{' '}
              { user?.company }
            </p>)
            }

            {
            user?.address && (<p className="text-[#787878]">
            <span className="font-bold text-black">Address:</span>{' '}
            { user?.address }
          </p>)
            }

            {
            user?.phoneNumber && (<p className="text-[#787878]">
            <span className="font-bold text-black">Contact:</span>{' '}
            { user?.phoneNumber }
          </p>)
            }

            {
            user?.country && (<p className="text-[#787878]">
            <span className="font-bold text-black">Country:</span>{' '}
            { user?.country }
          </p>)
            }

            {
            user?.city && (<p className="text-[#787878]">
            <span className="font-bold text-black">City:</span>{' '}
            { user?.city }
          </p>)
            }
            
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.auth.presentUser,
});

const mapDispatchToProps = dispatch => {
  return {
    getUser: () => dispatch(getLoggedInUser()),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);

