//Authentication
export const AUTH = 'AUTH';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const FETCH__USER = 'FETCH__USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const NEW_PASSWORD = 'NEW_PASSWORD';
export const CERTIFICATE_MESSAGE_DISPLAYED =
  'CERTIFICATE_MESSAGE_DISPLAYED';

//Payments
export const INITIALIZE_PAYMENT = 'INITIALIZE_PAYMENT';
export const CONFIRM_PAYMENT = 'CONFIRM_PAYMENT';
export const GET__PAYMENTS = 'GET__PAYMENTS';
export const DECIDE_ON_CERTIFICATE = 'DECIDE_ON_CERTIFICATE';

//Projects
export const GET_ALL_PROJECTS = 'GET_ALL_PROJECTS';
export const PROJECT_ERROR = 'PROJECT_ERROR';

//Services
export const GET_SERVICES = 'GET_SERVICES';
export const SERVICES_ERROR = 'SERVICES_ERROR';

//Testimonials
export const GET_TESTIMONIALS = 'GET_TESTIMONIALS';

//Members
export const GET_MEMBERS = 'GET_MEMBERS';

//Application
export const USER_APPLICATION = 'USER_APPLICATION';
export const GET_APPLICATION = 'GET_APPLICATION';

//Certificate
export const SAVE_CERTIFICATE = 'SAVE_CERTIFICATE';
export const GET_CERTIFICATE = 'GET_CERTIFICATE';
