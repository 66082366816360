import React, { useState, useEffect } from 'react';
import dots from '../../assets/images/icons/members/dots.svg';
import { useNavigate } from 'react-router-dom';
import Keys from '../../constants/keys';
import { useSelector } from 'react-redux';
import { useFetcher } from '../../api';

const Categories = () => {
  const navigate = useNavigate();
  const authenticatedUser = useSelector(
    state => state.auth.presentUser,
  );

  const userPayments = useSelector(
    state => state.payment.allUserPayments,
  );
  const applicationFeePayment = userPayments?.filter(
    payment => payment.paymentPlan === 'Application Fee',
  );
  const latestFeePayment = applicationFeePayment?.length - 1;

  const handleStorePathCategories = () => {
    const link = '/application';
    if (!authenticatedUser) {
      localStorage.setItem(Keys.REDIRECT_URL_KEY, link);
    }
  };

  const { data } = useFetcher('/api/applications/user/mine');
  const [application, setApplication] = useState(null);

  useEffect(() => {
    if (data) {
      setApplication(data.data);
    }
  }, [data]);

  return (
    <div className="relative my-12" id="categories">
      <div className="flex flex-col md:flex-row lg:px-16 py-8 justify-evenly md:justify-center items-center top-0 absolute z-20 bottom-0">
        <div className="md:w-2/3 px-8 lg:px-12">
          <div
            className="flex flex-col items-center text-center md:text-left md:items-start justify-center space-y-6 w-full"
            data-aos="fade-right"
          >
            <h1 className="text-3xl lg:text-4xl font-bold">
              RUPI Membership Categories
            </h1>
            <p className="pb-4 md:pb-2">
              Members at RUPI are categorized into two different
              categories which are Individual members and Company /
              Firm members.
            </p>
          </div>
        </div>
        <div className="w-full px-8 md:px-12 flex flex-col items-center">
          <div className="flex flex-col md:flex-y-2 md:flex-row md:space-x-4 items-start my-8 space-y-12 md:space-y-0 md:my-0">
            {[
              'Individual Membership',
              'Companies / Firms Membership',
            ].map((data, index) => {
              return (
                <div
                  key={index}
                  data-aos="fade-up"
                  data-aos-delay={index == 0 ? '500' : ''}
                  className={`${
                    index == 0 ? 'md:mt-32' : ''
                  } bg-white shadow-xl md:mx-8 text-center px-6 py-6 rounded-3xl md:w-[17rem]`}
                >
                  <h1 className="text-xl font-semibold mb-3">
                    {data}
                  </h1>
                  {index == 0 ? (
                    <div className="text-sm">
                      Individual membership in RUPI is divided into
                      the following 3 categories:
                      <div>
                        {' '}
                        <p className="font-semibold">
                          1. Junior or Graduate:
                        </p>{' '}
                        Fresh graduates or those with less than 5
                        years of experience.
                      </div>
                      <div>
                        {' '}
                        <p className="font-semibold">
                          2. Corporate Members:
                        </p>{' '}
                        Members who have between 5 and 10 years of
                        experience.
                      </div>
                      <div>
                        {' '}
                        <p className="font-semibold">
                          3. Fellow Members:
                        </p>{' '}
                        Members with more than 10 years of experience.
                      </div>
                    </div>
                  ) : (
                    <p className="text-sm">
                      This refers to a membership that is held by a
                      company or organization that employs urban
                      planners or is involved in urban planning work.
                      This membership may provide various benefits to
                      the company, such as access to educational and
                      professional development opportunities,
                      networking events, publications, consultancy
                      services and resources related to urban
                      planning.
                    </p>
                  )}
                </div>
              );
            })}
          </div>
          <div className="my-4" data-aos="flip-right">
            <button
              className="bg-green transform -rotate-12 rounded-full text-white text-sm px-12 py-3 font-semibold hover:shadow-lg"
              onClick={event => {
                if (!authenticatedUser) {
                  handleStorePathCategories(event);
                  navigate('/login');
                } else {
                  navigate(
                    application && applicationFeePayment?.length == 0 ? "/application?step=4" :
                    application && applicationFeePayment && applicationFeePayment?.length !== 0 && applicationFeePayment[latestFeePayment]?.transactionStatus !== 'Success' ? "/application?step=4" : 
                    application && applicationFeePayment && applicationFeePayment?.length !== 0 && applicationFeePayment[latestFeePayment]?.transactionStatus === 'Success' ? "/membershipStatus" :
                    "/application"
                  );
                }
              }}
            >
              Join RUPI
            </button>
          </div>
        </div>
      </div>

      <div className="h-[1200px] sm:h-[800px] md:h-[75vh] relative">
        <img
          src={dots}
          alt="dots"
          className="absolute top-[27vh] bottom-0"
        />
        <div className="w-10/12 h-[75%] bg-slate-100 rounded-tl-[160px] bottom-12 absolute right-0 z-10"></div>
        <div className="h-24 w-24 border-8 rounded-full border-green absolute right-[5%] bottom-0"></div>
      </div>
    </div>
  );
};

export default Categories;
