import * as actionType from '../constants/actionTypes';
import * as api from '../api/index.js';

export const checkOutPayment = cardData => async dispatch => {
  try {
    const { data } = await api.confirmPayment(cardData);

    dispatch({ type: actionType.CONFIRM_PAYMENT, data });

    return data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return 'Something went wrong. Please try again!';
    }
  }
};

export const cancelTransaction = cardData => async dispatch => {
  try {
    const { data } = await api.deletePayment(cardData);

    dispatch({ type: actionType.CONFIRM_PAYMENT, data });

    return data;
  } catch (error) {
    if (error.response && error.response.data) {
      return error.response.data;
    } else {
      return 'Something went wrong. Please try again!';
    }
  }
};

export const getPayments = () => {
  return async dispatch => {
    try {
      const result = await api.getUserPayments();
      if (!result.data.allAvailablePayments) {
        throw new Error('Failed to fetch payments for this user');
      }
      dispatch({
        type: actionType.GET__PAYMENTS,
        payload: {
          allUserPayments: result.data.allAvailablePayments,
        },
      });
    } catch (error) {
      if (error) {
        // console.log(error);
      }
    }
  };
};

export const alreadyHasACertificate = (body, id) => {
  return async dispatch => {
    try {
      const response = await api.hasACertificate(body, id);
      dispatch({
        type: actionType.DECIDE_ON_CERTIFICATE,
        payload: {
          decideCertificate: response.data.updatedApplication,
        },
      });
    } catch (error) {
      // console.log(error);
    }
  };
};
