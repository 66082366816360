import React, { useState, useEffect } from 'react'; 
import { NavLink } from 'react-router-dom';
import { individualPricingData } from '../data';
import { useSelector } from 'react-redux';
import { useFetcher } from '../../../../api';

const PricingCard = ({
  data = individualPricingData[0],
  noCategory,
  isPrimary = false,
  selector = id => {},
  label = 'Choose Plan',
}) => {


  const userPayments = useSelector(
    state => state.payment.allUserPayments,
  );
  const applicationFeePayment = userPayments?.filter(payment => payment.paymentPlan === "Application Fee");
  const latestFeePayment = applicationFeePayment?.length - 1;

  
  return (
    <div className="border border-gray-101 flex flex-col items-center shadow-md space-y-4 md:space-y-6 bg-white rounded-md p-8 py-12">
      <h2 className="md:text-lg font-bold uppercase text-green-802">
        {data.title}
      </h2>
      <p className="text-2xl md:text-4xl text-green-802">
        {data.price} {data.currency}
      </p>
      <div className="flex flex-col space-y-2">
        {data.features.map((feature, index) => (
          <p
            key={index}
            className={`${
              feature.isAvailable ? 'text-black' : 'text-grey-601'
            } flex items-center space-x-2`}
          >
            {feature.icon}
            <span>{feature.text}</span>
          </p>
        ))}
      </div>

      {selector ? (
        <button
          onClick={event => {
            if (selector) {
              selector(event);
            }
            if(applicationFeePayment && applicationFeePayment?.length !== 0 && applicationFeePayment[latestFeePayment]?.transactionStatus === 'Success' ) {
              localStorage.setItem(
                'price',
                JSON.stringify({
                  id: data.id,
                  amount: data.price,
                  currency: data.currency,
                  paymentType: 'Membership Fee'
                }),
              )
          }
          }}
          className={`border ${noCategory ? 'hidden' : ''} border-green-702 px-12 py-3 rounded-md font-semibold tracking-wide text-xs lg:text-sm ${
            isPrimary ? 'text-white bg-green-702' : 'text-green-702'
          }`}
        >
          {label}
        </button>
      ) : (
        <NavLink
          to="/pay"
          onClick={() => {
            if(applicationFeePayment) {
              localStorage.setItem(
                'price',
                JSON.stringify({
                  id: data.id,
                  amount: data.price,
                  currency: data.currency,
                  paymentType: 'Membership Fee'
                }),
              )
          }
          }}
          className={`border border-green-702 px-12 py-3 rounded-md font-semibold tracking-wide ${
            isPrimary ? 'text-white bg-green-702' : 'text-green-702'
          }`}
        >
          Pay now
        </NavLink>
      )}
    </div>
  );
};

export default PricingCard;
