import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import BenefitCard from './BenefitCard';
import greeting from '../../../assets/images/icons/members/greeting.svg';
import award from '../../../assets/images/icons/members/award.svg';
import business from '../../../assets/images/icons/members/text_write.svg';
import contract from '../../../assets/images/icons/members/text_edit.svg';
import Keys from '../../../constants/keys';
import { useSelector } from 'react-redux';
import { useFetcher } from '../../../api';

const Benefits = () => {
  const authenticatedUser = useSelector(
    state => state.auth.presentUser,
  );

  const userPayments = useSelector(
    state => state.payment.allUserPayments,
  );
  const applicationFeePayment = userPayments?.filter(
    payment => payment.paymentPlan === 'Application Fee',
  );
  const latestFeePayment = applicationFeePayment?.length - 1;

  const handleStorePathBenefits = () => {
    const link = '/application';
    if (!authenticatedUser) {
      localStorage.setItem(Keys.REDIRECT_URL_KEY, link);
    }
  };

  const { data } = useFetcher('/api/applications/user/mine');
  const [application, setApplication] = useState(null);

  useEffect(() => {
    if (data) {
      setApplication(data.data);
    }
  }, [data]);

  return (
    <div
      className="flex bg-slate-100 overflow-hidden px-6 md:px-12 py-16 items-center flex-col md:flex-row space-y-4 md:space-y-0"
      id="membership-benefits"
    >
      <div className="w-full">
        <div className="flex flex-col items-center text-center md:text-left md:items-start justify-center space-y-6 w-full">
          <h1 className="text-4xl font-bold" data-aos="fade-right">
            Benefits of Joining RUPI Membership
          </h1>
          <p className="pb-4 md:pb-2" data-aos="fade-right">
            Joining the Rwanda Urban Planners Institute can provide a
            range of benefits to urban planners, including
            professional development, networking opportunities, access
            to resources, advocacy, and recognition.
          </p>

          <Link
            to={
              !authenticatedUser ? "/login" : 
              application && applicationFeePayment?.length == 0 ? "/application?step=4" :
              application && applicationFeePayment && applicationFeePayment?.length !== 0 && applicationFeePayment[latestFeePayment]?.transactionStatus !== 'Success' ? "/application?step=4" : 
              application && applicationFeePayment && applicationFeePayment?.length !== 0 && applicationFeePayment[latestFeePayment]?.transactionStatus === 'Success' ? "/membershipStatus" :
              "/application"
            }
            onClick={
              !authenticatedUser ? handleStorePathBenefits : ''
            }
            data-aos="fade-up"
            className="bg-green text-white text-sm px-12 py-2 font-semibold hover:shadow-lg hidden md:block"
          >
            Join RUPI
          </Link>
        </div>
      </div>
      <div className="w-full" data-aos="fade-left">
        <div className="relative h-96 md:h-72">
          <BenefitCard
            number={100}
            text="Professional Urban Planners"
            icon={greeting}
            card="top-0 w-5/6 left-8 md:left-auto md:right-16 z-10"
            badge="-top-6 -right-5"
          />
          <BenefitCard
            number={80}
            text="Certified Members"
            icon={contract}
            card="top-20 w-full md:top-14 md:left-16"
            badge="-top-8 -left-6"
          />
          <BenefitCard
            number={10}
            text="Years of Experience"
            icon={award}
            card="top-40 w-5/6 left-8 md:left-auto md:top-auto md:bottom-16 z-10 md:right-24"
            badge="-top-6 -right-5"
          />
          <BenefitCard
            number={50}
            text="Completed Projects"
            icon={business}
            card="top-60 w-full md:top-auto md:bottom-0 md:left-32"
            badge="-bottom-4 -right-6"
          />
        </div>
        <div className="text-center md:hidden">
          <Link
            to={
              !authenticatedUser ? "/login" : 
              application && applicationFeePayment?.length == 0 ? "/application?step=4" :
              application && applicationFeePayment && applicationFeePayment?.length !== 0 && applicationFeePayment[latestFeePayment]?.transactionStatus !== 'Success' ? "/application?step=4" : 
              application && applicationFeePayment && applicationFeePayment?.length !== 0 && applicationFeePayment[latestFeePayment]?.transactionStatus === 'Success' ? "/membershipStatus" :
              "/application"
            }
            onClick={
              !authenticatedUser ? handleStorePathBenefits : ''
            }
            className="bg-green text-white text-sm px-12 py-2 font-semibold hover:shadow-lg"
          >
            Join RUPI
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
