import React from 'react';

const UserArrowIcon = () => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25008 25.4374C8.18289 25.4374 7.13966 25.121 6.25232 24.5281C5.36498 23.9352 4.67338 23.0924 4.26498 22.1065C3.85659 21.1205 3.74973 20.0356 3.95793 18.9889C4.16613 17.9422 4.68003 16.9808 5.43465 16.2262C6.18927 15.4715 7.15072 14.9576 8.19741 14.7494C9.2441 14.5412 10.329 14.6481 11.315 15.0565C12.3009 15.4649 13.1437 16.1565 13.7366 17.0438C14.3295 17.9312 14.6459 18.9744 14.6459 20.0416C14.6442 21.4721 14.0751 22.8436 13.0636 23.8551C12.0521 24.8666 10.6806 25.4357 9.25008 25.4374Z"
        fill="#0388A9"
      />
      <path
        d="M13.3205 23.5439C12.8209 24.137 12.1978 24.6138 11.4947 24.9409C10.7916 25.2679 10.0255 25.4374 9.25008 25.4374C8.47463 25.4374 7.70855 25.2679 7.00546 24.9409C6.30236 24.6138 5.67923 24.137 5.17968 23.5439C4.06966 24.2321 3.15321 25.1917 2.51673 26.3322C1.88025 27.4726 1.54471 28.7564 1.54175 30.0624C1.54175 30.4713 1.70417 30.8634 1.99329 31.1525C2.28241 31.4417 2.67454 31.6041 3.08341 31.6041H15.4167C15.8256 31.6041 16.2178 31.4417 16.5069 31.1525C16.796 30.8634 16.9584 30.4713 16.9584 30.0624C16.9555 28.7564 16.6199 27.4726 15.9834 26.3322C15.347 25.1917 14.4305 24.2321 13.3205 23.5439ZM27.7501 25.4374C26.6829 25.4374 25.6397 25.121 24.7523 24.5281C23.865 23.9352 23.1734 23.0924 22.765 22.1065C22.3566 21.1205 22.2497 20.0356 22.4579 18.9889C22.6661 17.9422 23.18 16.9808 23.9347 16.2262C24.6893 15.4715 25.6507 14.9576 26.6974 14.7494C27.7441 14.5412 28.829 14.6481 29.815 15.0565C30.8009 15.4649 31.6437 16.1565 32.2366 17.0438C32.8295 17.9312 33.1459 18.9744 33.1459 20.0416C33.1442 21.4721 32.5751 22.8436 31.5636 23.8551C30.5521 24.8666 29.1806 25.4357 27.7501 25.4374Z"
        fill="#C3E8F1"
      />
      <path
        d="M31.8205 23.5439C31.3209 24.137 30.6978 24.6138 29.9947 24.9409C29.2916 25.2679 28.5255 25.4374 27.7501 25.4374C26.9746 25.4374 26.2086 25.2679 25.5055 24.9409C24.8024 24.6138 24.1792 24.137 23.6797 23.5439C22.5697 24.2321 21.6532 25.1917 21.0167 26.3322C20.3802 27.4726 20.0447 28.7564 20.0417 30.0624C20.0417 30.4713 20.2042 30.8634 20.4933 31.1526C20.7824 31.4417 21.1745 31.6041 21.5834 31.6041H33.9167C34.3256 31.6041 34.7178 31.4417 35.0069 31.1526C35.296 30.8634 35.4584 30.4713 35.4584 30.0624C35.4555 28.7564 35.1199 27.4726 34.4834 26.3322C33.847 25.1917 32.9305 24.2321 31.8205 23.5439Z"
        fill="#027794"
      />
      <path
        d="M26.5276 8.93085L23.4442 5.84751C23.1543 5.56275 22.7636 5.40402 22.3572 5.40586C21.9508 5.4077 21.5615 5.56996 21.2742 5.85734C20.9868 6.14472 20.8245 6.53395 20.8227 6.94036C20.8208 7.34677 20.9796 7.73746 21.2643 8.02743L21.716 8.47914H15.2843L15.736 8.02743C16.0221 7.73772 16.1819 7.34659 16.1806 6.93945C16.1793 6.5323 16.017 6.1422 15.7291 5.8543C15.4412 5.56641 15.0511 5.40411 14.644 5.40284C14.2368 5.40157 13.8457 5.56142 13.556 5.84751L10.4727 8.93085C10.3295 9.07396 10.2159 9.24388 10.1384 9.43091C10.0609 9.61794 10.021 9.8184 10.021 10.0208C10.021 10.2233 10.0609 10.4238 10.1384 10.6108C10.2159 10.7978 10.3295 10.9677 10.4727 11.1109L13.556 14.1942C13.8457 14.4803 14.2368 14.6401 14.644 14.6388C15.0511 14.6375 15.4412 14.4752 15.7291 14.1873C16.017 13.8994 16.1793 13.5093 16.1806 13.1021C16.1819 12.695 16.022 12.3039 15.7359 12.0142L15.2842 11.5625H21.7159L21.2641 12.0142C20.9751 12.3033 20.8127 12.6954 20.8127 13.1042C20.8127 13.513 20.9751 13.9051 21.2642 14.1942C21.5533 14.4833 21.9454 14.6457 22.3543 14.6456C22.7631 14.6456 23.1552 14.4832 23.4442 14.1941L26.5276 11.1108C26.6708 10.9676 26.7843 10.7977 26.8618 10.6107C26.9393 10.4237 26.9792 10.2232 26.9792 10.0208C26.9792 9.81837 26.9393 9.61791 26.8618 9.43089C26.7843 9.24388 26.6708 9.07396 26.5276 8.93085Z"
        fill="#027794"
      />
    </svg>
  );
};

export default UserArrowIcon;
