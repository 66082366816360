import React from 'react'
import ProfileContainer from '../components/Profile/ProfileContainer';

const ProfilePage = () => {
  return (
    <>
      <ProfileContainer />
    </>
  );
};

export default ProfilePage;
