import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { joiResolver } from '@hookform/resolvers/joi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import SideNavbar from './SideNavbar';
import ChooseFileField from '../../pages/application/components/elements/ChooseFileField';
import { publicationInfoShema } from '../../lib/schema/application';
import { formatJoiErorr } from '../../lib/helpers/format';
import { API } from '../../api';
import Keys from '../../constants/keys';
import { connect } from 'react-redux';
import { FaEdit, FaEraser } from 'react-icons/fa';

const NewPublication = ({ user }) => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(publicationInfoShema),
  });

  const handleStorePathPublicationHistory = () => {
  const link = '/publications/new';  
  if (!user) {
    localStorage.setItem(Keys.REDIRECT_URL_KEY, link);
    }
  }

  const onSubmit = async query => {
    try {
      setLoading(true);
      await API.post(`/api/publications`, query);
      toast.success(
        'Publication added successfully! It will be visible publicly after review.',
      );
      reset();
      setTimeout(() => {
        navigate('/publications');
      }, 6000);
    } catch (error) {
      const err = error.response?.data || error;
      const errMessage =
        err?.message || 'Something went wrong. Please try again!';
      toast.error(errMessage);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="flex flex-col md:flex-row w-full mt-6 md:mt-8 gap-y-4 md:space-x-6">
        <SideNavbar />
        {user ? (
          <div className="flex flex-col flex-grow items-center">
            <form
              onSubmit={event => {
                handleSubmit(onSubmit)(event);
              }}
              className="flex flex-col max-w-3xl w-full items-start"
            >
              <label htmlFor="title" className="flex flex-col w-full">
                <span className="font-semibold">
                  Publication Title
                </span>
                <input
                  id="title"
                  type="text"
                  placeholder="Type title here"
                  {...register('title')}
                  className="px-3 py-2 rounded outline-none focus:outline-none border border-slate-400 mt-1 w-full"
                />
                {errors.title ? (
                  <p className="mt-1 text-red-500 text-sm">
                    {formatJoiErorr(errors.title.message)}
                  </p>
                ) : null}
              </label>

              <label
                htmlFor="publication-link"
                className="flex flex-col w-full mt-3"
              >
                <span className="font-semibold">
                  Publication Link
                </span>
                <input
                  id="publication-link"
                  type="text"
                  placeholder="Type publication link here"
                  {...register('link')}
                  className="px-3 py-2 rounded outline-none focus:outline-none border border-slate-400 mt-1 w-full"
                />
                {errors.link ? (
                  <p className="mt-1 text-red-500 text-sm">
                    {formatJoiErorr(errors.link.message)}
                  </p>
                ) : null}
              </label>

              <div className="flex flex-col mt-3 w-full">
                <ChooseFileField
                  label="Cover Page"
                  isMultiple={false}
                  labelClassName="font-semibold text-black"
                  acceptFileTypes="image/png,image/jpeg,image/gif,image/jpg"
                  className="border border-slate-300 rounded h-40 overflow-scroll text-xs"
                  error={errors.cover?.message}
                  onChange={value => {
                    setValue('cover', !!value ? value : null, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }}
                />
              </div>

              <label
                htmlFor="small-desc"
                className="flex flex-col w-full mt-3"
              >
                <span className="font-semibold">
                  Small Description
                </span>
                <textarea
                  rows={5}
                  id="small-desc"
                  placeholder="Type small description here"
                  {...register('description')}
                  className="resize-none px-3 py-2 rounded outline-none focus:outline-none border border-slate-400 mt-1 w-full"
                />
                {errors.description ? (
                  <p className="mt-1 text-red-500 text-sm">
                    {formatJoiErorr(errors.description.message)}
                  </p>
                ) : null}
              </label>

              <button
                type="submit"
                disabled={loading}
                className="disabled:opacity-25 disabled:cursor-not-allowed mt-8 rounded bg-brand-green text-white font-semibold px-8 py-3"
              >
                Publish Your Work
              </button>
            </form>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center text-center w-full px-12 space-y-4">
            <FaEdit className="text-2xl text-green" />
            <p>Please log in to be able to publish your work</p>
            <Link
              to="/login"
              onClick={handleStorePathPublicationHistory}
              className="rounded-full px-5 py-2 text-xs bg-green text-white hover:shadow-xl"
            >
              Go to login
            </Link>
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};
const mapStateToProps = state => ({
  user: state.auth.presentUser,
});

export default connect(mapStateToProps)(NewPublication);
