import React, { useEffect, useState, useCallback } from 'react';
import LeftNavbar from './LeftNavbar';
import { FaCamera } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { updateuser } from '../../actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PulseLoader from "react-spinners/PulseLoader";
import HashLoader from "react-spinners/HashLoader";
import { Preloader } from '../Global/Preloader';

const initialState = { picture: '' , firstName: '' , lastName: '' , company: '' , address: '' , phoneNumber: '' , country: '' , city: '' , bio: '' };

const EditProfileContainer = () => {
  const [formData, setFormData] = useState(initialState);
  const [editedFields, setEditedFields] = useState({});
  const [pictureEdited, setPictureEdited] = useState(false);
  const [isGoogleUser, setIsGoogleUser] = useState(localStorage.getItem('IsGoogleUser') === 'true');
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const user = useSelector(state => state.auth.presentUser);
  const [isLoading, setIsLoading ] = useState(false)
  const [isImageLoading, setIsImageLoading ] = useState(false)

  if (user && user == undefined) {
    navigate('/')
  }

  useEffect(() => {
    if (user) {
      setFormData(user);
      setIsGoogleUser(localStorage.getItem('IsGoogleUser') === 'true')
    }
  }, [user]);
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setEditedFields({ ...editedFields, [name]: true });
  }

  const handelSubmit = async(e) => {
    setIsLoading(true)
    if (e) {
      e.preventDefault();
    }
    const editedData = Object.keys(editedFields).reduce((acc, key) => {
      acc[key] = formData[key];
      return acc;
    }, {});
    await dispatch(updateuser(editedData, navigate)).then(response => {
      if(response.status == 'fail'){
        toast.error("Something went wrong, that's all we know!")
      }
      toast.success(response.successMessage)
    })
  }
  

  // eslint-disable-next-line no-unused-vars
  const [imageFile, setImageFile] = React.useState();
  const fileInput = React.useRef(null);
  
  const onChangeFile = event => { 
    setIsImageLoading(true)
    event.preventDefault();
    const { files } = event.target;
    const reader = new FileReader();
    if (files && files.length !== 0) {
      reader.onload = () => {
        setFormData({ ...formData, picture: reader.result });
        setPictureEdited(true) 
      };
      reader.readAsDataURL(files[0]);
      setImageFile(files[0]);
    }
  };

  useEffect(() => {
    if (pictureEdited) {
      handelSubmit();
    }
  }, [pictureEdited]);

  if(!user) {
    return <Preloader />
  }

  return (
    <div className="flex flex-col md:flex-row">
      <LeftNavbar />
      <div className="flex flex-col overflow-hidden items-center w-full bg-white p-8 py-6 md:py-12">
        <div className="flex flex-col max-w-4xl w-full mx-auto">
          <div className="flex flex-wrap gap-3 justify-between">
            <h1
              className={`text-lg md:text-3xl font-bold text-gray-800 ${isGoogleUser ? 'mb-12' : ''}`}
              data-aos="fade-down"
            >
              Edit profile
            </h1>
            <div className={`group relative flex flex-col ${isGoogleUser ? 'hidden' : ''}`}>
            <input
              type="file"
              accept="image/*"
              name="picture"
              onChange={(event) => {
                onChangeFile(event)
                handleChange(event)
              }}
              className="invisible top-0 left-0 absolute"
              ref={fileInput}
            />
              {
              user?.picture
               ?
              <img
               src={ user?.picture.url }
               alt=""
               width={100}
               height={100}
               className="w-[100px] h-[100px] rounded-full object-cover object-top"
             />
              :
            <div className='bg-green p-3 w-20 h-20 text-3xl font-bold leading-[55px] text-center rounded-full'>
              { user?.firstName.charAt(0) + user?.lastName.charAt(0) }
            </div>
            }
              {
                isImageLoading ?        
                ( <div className="z-10 flex items-center text-sm bottom-4 right-4 p-2 rounded-full hover:bg-red-400/60 bg-gray-400/60 absolute">
                  <HashLoader 
                  color="#ffffff"
                  loading={true}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
                </div>) : (
                <button
                onClick={() => {
                  fileInput.current.click();
                }}
                title="Edit profile picture"
                className="z-10 flex items-center text-sm bottom-4 right-4 p-2 rounded-full hover:bg-red-400/60 bg-gray-400/60 absolute"
              >
                <FaCamera />
              </button>
                )
              }
              
            </div>
          </div>

          <form className="flex flex-col">
            <div className="inline-grid items-start gap-3 gap-x-8 md:grid-cols-2">
              <label htmlFor="first-name" className="flex flex-col">
                <span className="text-gray-800 font-semibold">
                  First name
                </span>
                <input
                  id="first-name"
                  type="text"
                  name="firstName"
                  disabled={isGoogleUser}
                  onChange={handleChange}
                  value={ formData.firstName }
                  className="px-3 py-2 rounded outline-none focus:outline-none border border-[#858585] mt-1 w-full"
                />
              </label>
              <label htmlFor="last-name" className="flex flex-col">
                <span className="text-gray-800 font-semibold">
                  Last name
                </span>
                <input
                  id="last-name"
                  name="lastName"
                  type="text"
                  disabled={isGoogleUser}
                  onChange={handleChange}
                  value={ formData.lastName }
                  className="px-3 py-2 rounded outline-none focus:outline-none border border-[#858585] mt-1 w-full"
                />
              </label>
            </div>
            <label htmlFor="company" className="flex flex-col mt-3">
              <span className="text-gray-800 font-semibold">
                Company
              </span>
              <input
                id="company"
                type="text"
                name="company"
                onChange={handleChange}
                value={ formData.company }
                className="px-3 py-2 rounded outline-none focus:outline-none border border-[#858585] mt-1 w-full"
              />
            </label>

            <label htmlFor="address" className="flex flex-col mt-3">
              <span className="text-gray-800 font-semibold">
                Address
              </span>
              <input
                id="address"
                type="text"
                name="address"
                onChange={handleChange}
                value={ formData.address }
                className="px-3 py-2 rounded outline-none focus:outline-none border border-[#858585] mt-1 w-full"
              />
            </label>

            <label htmlFor="contact" className="flex flex-col mt-3">
              <span className="text-gray-800 font-semibold">
                Contact Number
              </span>
              <input
                id="contact"
                type="text"
                name="phoneNumber"
                onChange={handleChange}
                value={ formData.phoneNumber }
                className="px-3 py-2 rounded outline-none focus:outline-none border border-[#858585] mt-1 w-full"
              />
            </label>

            <div className="inline-grid items-start gap-3 gap-x-8 md:grid-cols-2 mt-3">
              <label htmlFor="country" className="flex flex-col">
                <span className="text-gray-800 font-semibold">
                  Country
                </span>
                <input
                  id="country"
                  type="text"
                  name="country"
                  onChange={handleChange}
                  value={ formData.country }
                  className="px-3 py-2 rounded outline-none focus:outline-none border border-[#858585] mt-1 w-full"
                />
              </label>
              <label htmlFor="city" className="flex flex-col">
                <span className="text-gray-800 font-semibold">
                  City
                </span>
                <input
                  id="city"
                  type="text"
                  name="city"
                  onChange={handleChange}
                  value={ formData.city }
                  className="px-3 py-2 rounded outline-none focus:outline-none border border-[#858585] mt-1 w-full"
                />
              </label>
            </div>

            <label htmlFor="bio" className="flex flex-col mt-3">
              <span className="text-gray-800 font-semibold">Bio</span>
              <textarea
                rows={5}
                id="bio"
                name="bio"
                onChange={handleChange}
                value={ formData.bio }
                className="resize-none px-3 py-2 rounded outline-none focus:outline-none border border-[#858585] mt-1 w-full"
              />
            </label>

            <div className="flex flex-col items-start mt-6 md:mt-8">
              <button
                type="submit"
                className="py-3 px-4 md:px-12 rounded text-white bg-brand-green"
                onClick={handelSubmit}
              >
                {
                isLoading ?        
                <PulseLoader
                  color="#ffffff"
                  loading={true}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                /> : 'Edit Profile'
                }
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};


export default EditProfileContainer;
