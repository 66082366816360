import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import {
    cancelTransaction,
  } from '../../actions/payment';
import PulseLoader from 'react-spinners/PulseLoader';
import { toast } from 'react-toastify';

const PendingTransaction = () => {
    const [pendingPayment, setPendingPayment] = useState(JSON.parse(localStorage.getItem("paymentDetails")))
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch();

    useEffect(() => {
        setPendingPayment(JSON.parse(localStorage.getItem("paymentDetails")))
    }, []);

    const handleContinueToPayment = () => {
       if(pendingPayment){
        window.location.replace(pendingPayment.paymentUrl)
       } 
    }

    const handleCancelPayment = () => {
        setIsLoading(true)
        if(pendingPayment){
            dispatch(cancelTransaction(pendingPayment.paymentId)).then((response) => {
                if(response.successMessage){
                    localStorage.removeItem("paymentDetails")
                    setIsLoading(false)
                    window.location.reload()
                }
                else{
                   toast.error("There was an error canceling your payment!")
                }
                
            })
        }
    }

  return (
    <div className="fixed z-50 flex items-start justify-end top-2 right-2">
        <div className="bg-white  rounded w-[370px]">
            <div className="p-3">
                <div className="p-3">
                You have an ongoing payment transaction...
                </div>
                <div className="p-3 flex flex-col justify-center items-center gap-5">
                 <div className="bg-green text-white text-sm px-12 py-2 text-center font-semibold hover:shadow-lg hover:cursor-pointer" onClick={handleContinueToPayment}>
                    Continue to Payment
                 </div>
                 <div className="bg-red-500 text-white text-sm px-12 py-2 text-center font-semibold hover:shadow-lg hover:cursor-pointer" onClick={handleCancelPayment}>
                 {isLoading ? (
                    <PulseLoader
                    color="#ffffff"
                    loading={true}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                    />
                 ) :
                   <p>Cancel Payment</p> 
                 }
                 </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default PendingTransaction