import React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';

const ProjectPagination = ({
  pages = 1,
  page = 1,
  onChange = () => {},
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const moveToPage = page => {
    onChange(page);
    navigate(`${location.pathname}?page=${page}`);
  };
  return (
    <div className="flex items-center space-x-3 md:space-x-4 justify-between">
      <button
        disabled={page === 1}
        onClick={() => {
          if (page > 1) {
            moveToPage(page - 1);
          }
        }}
        className="disabled:cursor-not-allowed disabled:bg-opacity-75 p-3 bg-black-800 text-white flex items-center space-x-2 sm:space-x-3 justify-center sm:min-w-[150px]"
      >
        <FaArrowLeft />
        <span>Back</span>
      </button>

      <div className="flex-grow flex items-center space-x-2 sm:space-x-4">
        {new Array(pages).fill(0).map((_, i) => (
          <div
            key={i}
            role="button"
            tabIndex={0}
            onClick={() => moveToPage(i + 1)}
            className={`w-[16px] h-[16px] rounded-full ${
              i + 1 === page
                ? 'bg-brand-green'
                : 'bg-[#F6F8F7]'
            }`}
          />
        ))}
      </div>

      <button
        disabled={page === pages}
        onClick={() => {
          if (page < 5) {
            moveToPage(page + 1);
          }
        }}
        className="disabled:cursor-not-allowed disabled:bg-opacity-75 p-3 bg-black-800 text-white flex items-center space-x-2 sm:space-x-3 justify-center sm:min-w-[150px]"
      >
        <span>Next</span>
        <FaArrowRight />
      </button>
    </div>
  );
};

export default ProjectPagination;
