import { FaArrowRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";

export const About = () => {
  return (
    <div className="p-4 md:p-16" data-aos="fade-up">
      <div className="bg-gray-301 p-6 flex flex-col md:flex-row md:rounded-full rounded-3xl">
        <div className="bg-white py-20 px-24 rounded-full md:rounded-r-none mr-8 md:border-r-2 border-r-slate-400 hidden md:block">
          <h1 className="text-green font-bold text-xl md:text-2xl text-center md:text-left">
            About RUPI
          </h1>
        </div>
        <h1 className="text-green font-bold text-xl md:text-2xl text-center md:text-left my-4 md:hidden">
          About RUPI
        </h1>
        <div className="flex flex-col justify-center space-y-8 md:mx-8 text-center md:text-left">
          <p>
          Rwanda Urban Planners Institute (RUPI) is a professional body representing urban planners in Rwanda. 
          It is a National organization that was established in 2013 and has over 3,000 professional members. 
          As a consequence of the first graduates from the former National University of Rwanda, 
          being the only university that started the urban and regional...
          </p>

          <div className="mx-auto md:m-0">
            <button className="text-sm text-green font-semibold flex items-center space-x-4 outline-none underline">
              <NavLink to="/about">Continue Reading</NavLink>
              <FaArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
