import { FaFile, FaRegFilePdf, FaTimes } from 'react-icons/fa';
import { useState, useEffect, useMemo } from 'react';
import { formatJoiErorr } from '../../../../lib/helpers/format';
import {
  uploadMultiple,
  uploadSingle,
} from '../../../../helpers/cloudinary.js';
import { BarLoader } from 'react-spinners';

const ChooseFileField = ({
  label,
  acceptFileTypes = 'application/pdf',
  labelClassName = 'text-sm',
  className = 'border border-slate-300 rounded-3xl h-48 overflow-scroll text-xs',
  isMultiple = true,
  error,
  register = {},
  onChange = () => {},
}) => {
  const fieldId = label.split(' ').join('-');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [dragError, setDragError] = useState();
  const [upload, setUpload] = useState({
    uploaded: [],
    uploading: [],
    removed: [],
  });

  const handleClickUpload = e => {
    const input = document.getElementById(fieldId);
    setDragError(undefined);
    input.click();
  };

  const handleFileChange = e => {
    const files = e.target.files;
    if (files.length === 0) return;

    Array.from(files).forEach(file => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const selectedFile = {
          name: file.name,
          url: reader.result,
        };
        if (isMultiple) {
          setSelectedFiles(prev => [...prev, selectedFile]);
        } else {
          setSelectedFiles(prev => [selectedFile]);
        }
      };

      reader.readAsDataURL(file);
    });
  };

  // const removeFile = name => {
  //   setSelectedFiles(
  //     selectedFiles.filter(file => file.name !== name),
  //   );
  // };
  const [secureUrls, setSecureUrls] = useState([]);

  useEffect(() => {
    if (selectedFiles.length === 0) return;
    async function uploading() {
      const nonUploaded = selectedFiles.filter(
        file => !upload.uploaded.includes(file.name),
      );
      const imageUrls = nonUploaded.map(file => file.url);
      const imageNames = nonUploaded.map(file => file.name);

      try {
        setUpload(prev => ({
          ...prev,
          uploading: [...imageNames],
        }));

        if (!isMultiple) {
          const last = imageUrls[0];
          const img = await uploadSingle(last);
          onChange(img);
        } else if (isMultiple && imageUrls.length > 0) {
          const images = await uploadMultiple(imageUrls);
          onChange([...secureUrls, ...images]);
          setSecureUrls(prev => [...prev, ...images]);
        }

        setUpload(prev => ({
          ...prev,
          uploading: [],
          uploaded: [...prev.uploaded, ...imageNames],
        }));
      } catch (error) {
        // console.log(error);
        setDragError(
          error.message ||
            'Something went wrong while uploading ' +
              label.toLowerCase(),
        );
      } finally {
        setUpload(prev => ({ ...prev, uploading: [] }));
      }
    }
    uploading();
  }, [selectedFiles, isMultiple]);

  // useMemo(() => {
  //   console.log(upload);
  // }, [upload?.uploading.length, upload?.uploaded.length]);

  return (
    <div className="flex flex-col space-y-2 w-full my-2 md:my-4">
      <label htmlFor={fieldId} className={labelClassName}>
        {label}
      </label>
      <input
        {...register}
        type="file"
        id={fieldId}
        name={fieldId}
        accept={acceptFileTypes}
        multiple={isMultiple}
        className="hidden"
        onChange={handleFileChange}
      />
      <div
        className={`${
          isDragging ? 'bg-green text-white' : 'bg-slate-100'
        } ${className}`}
      >
        <div className="bg-white flex justify-between px-8 py-2 items-center">
          {selectedFiles.length === 0 ? (
            <div></div>
          ) : (
            <div className="border rounded-full py-1 px-2 text-[8px]">
              {selectedFiles.length > 9
                ? selectedFiles.length
                : '0' + selectedFiles.length}
            </div>
          )}

          <button
            type="button"
            className="bg-black text-white rounded-full px-4 py-[3px] outline-none border-0"
            onClick={handleClickUpload}
          >
            {selectedFiles.length === 0
              ? 'Choose'
              : isMultiple
              ? 'Add +'
              : 'Change'}
          </button>
          <div className="border rounded-full py-1.5 px-1.5">
            <FaFile />
          </div>
        </div>

        {error ? (
          <p className="mt-1 px-4 text-red-500 text-sm">
            {upload.uploading.length > 0
              ? 'Upload is going on...'
              : formatJoiErorr(error)}
          </p>
        ) : null}

        <div
          className="flex items-center text-center justify-center"
          onDrop={e => {
            e.preventDefault();
            const files = e.dataTransfer.files;
            const fileTypes = acceptFileTypes.split(',');

            let unaccepted = 0;

            Array.from(files).forEach(file => {
              const reader = new FileReader();

              reader.onloadend = () => {
                const selectedFile = {
                  name: file.name,
                  url: reader.result,
                };

                if (!fileTypes.includes(file.type)) {
                  unaccepted += 1;
                } else {
                  if (isMultiple) {
                    setSelectedFiles(prev => [...prev, selectedFile]);
                  } else {
                    setSelectedFiles(prev => [selectedFile]);
                  }
                }
              };

              reader.readAsDataURL(file);
            });

            setIsDragging(false);

            if (unaccepted > 0) {
              setDragError(
                unaccepted +
                  ' files are not accepted, not in allowed format*',
              );
            }
          }}
          onDragLeave={e => {
            setIsDragging(false);
          }}
          onDragOver={e => {
            e.preventDefault();
            setDragError(undefined);
            setIsDragging(true);
          }}
        >
          {selectedFiles.length === 0 ? (
            <p className="pt-10">
              Or
              <br />
              Drag and drop your files here
            </p>
          ) : (
            <div className="flex flex-col w-full h-full mx-4 my-2">
              {selectedFiles.map((file, index) => {
                return (
                  <div
                    key={index}
                    className="w-full border rounded-md p-2 flex items-center space-x-2 my-[1.5px] justify-between"
                  >
                    <FaRegFilePdf className="text-red-300" />
                    <p className="text-[8px] leading-[10px] flex-grow text-left">
                      {file.name}
                      {upload.uploading.includes(file.name)
                        ? ' (Uploading...)'
                        : upload.uploaded.includes(file.name)
                        ? ' (Uploaded)'
                        : ''}
                    </p>
                    {/* <FaTimes
                      className="text-slate-500 cursor-pointer hover:text-red-400"
                      onClick={() => removeFile(file.name)}
                    /> */}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      {/* {selectedFiles.length > 0 && (
        <div className="text-xs text-center">
          <button
            type="button"
            className="bg-green text-white rounded-full px-4 py-[3px] outline-none border-0"
            onClick={async () => {}}
          >
            {upload.uploaded.length > 0
              ? 'Uploaded'
              : upload.uploading.length === 0
              ? 'Upload'
              : 'Uploading'}
          </button>
        </div>
      )} */}

      {selectedFiles.length > 0 && (
        <p className="text-red-500 text-xs">{dragError}</p>
      )}
      {upload.uploading.length > 0 && (
        <div className="px-4">
          <BarLoader color="#008D41" width="100%" />
        </div>
      )}
    </div>
  );
};

export default ChooseFileField;
