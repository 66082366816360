import React, { useEffect, useRef, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import { websiteContents } from '../data/contents';
import SearchContentCard from './SearchContentCard';
import { useNavigate } from 'react-router-dom';

const SearchContent = ({ onClose }) => {
  const componentRef = useRef(null);
  const [results, setResults] = useState([]);
  const [input, setInput] = useState('');
  const nav = useNavigate();

  useEffect(() => {
    document.addEventListener('dblclick', handleClickOutside);

    return () => {
      document.removeEventListener('dblclick', handleClickOutside);
    };
  }, []);

  const handleClickOutside = event => {
    if (
      componentRef.current &&
      !componentRef.current.contains(event.target)
    ) {
      onClose();
    }
  };

  const handleChange = e => {
    const { value } = e.target;
    setInput(value);
    setResults(
      !value
        ? []
        : websiteContents.filter(
            content =>
              content.text
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              content.description
                .toLowerCase()
                .includes(value.toLowerCase()),
          ),
    );
  };

  const isNotFound = input && !results.length;

  return (
    <div className="fixed w-full h-full inset-0 bg-black bg-opacity-25 backdrop-blur-sm z-50 top-0 right-0 bottom-0">
      <div
        className="flex justify-end p-4 text-2xl cursor-pointer"
        onClick={onClose}
      >
        <FaTimes className="text-white" />
      </div>
      <div
        className="bg-slate-100 w-4/5 md:w-1/2 mx-auto rounded-3xl md:mt-[10%] mt-[20%] overflow-hidden"
        ref={componentRef}
      >
        <div className="p-1">
          <input
            type="search"
            value={input}
            onChange={handleChange}
            className="p-2 px-6 rounded-full w-full border outline-none focus:border-b-2 border-green ring-0"
            placeholder="What are you looking for?"
          />
        </div>

        <div className="max-h-[24rem] overflow-y-scroll">
          {results.map((content, index) => {
            return (
              <SearchContentCard
                content={content}
                key={index}
                onClick={() => {
                  onClose();
                  nav(content.url);
                }}
              />
            );
          })}
          {isNotFound && (
            <div className="text-center py-8">No results found!</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchContent;
