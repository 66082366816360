import about from '../../assets/images/about/header.jpg';

const PageHeader = ({title, description }) => {
  return (
    <div
      className="min-h-[342px] flex flex-col items-center justify-center -mt-2 w-full text-white px-8 py-6 md:py-12 bg-top bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${about})` }}
    >
      <div className="flex flex-col max-w-6xl">
        <h2
          className="mt-2 text-center text-3xl md:text-4xl font-black tracking-wide"
          data-aos="zoom-in"
        >
          {title}
        </h2>
        <p className="mt-3 text-center" data-aos="zoom-in">
          {description}
        </p>
      </div>
    </div>
  );
};

export default PageHeader;
