import { FaChevronDown } from 'react-icons/fa';
import dots from '../../assets/images/icons/dots_small.svg';

const DisciplineSection = ({ id, discipline, isEven, isLast }) => {
  const handleNext = e => {
    e.preventDefault();

    const currentSection = document.getElementById(id);
    const nextDiv = currentSection.nextElementSibling;

    const yOffset = -65;
    const y =
      nextDiv.getBoundingClientRect().top + window.scrollY + yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  return (
    <section id={id}>
      <div
        className={`p-6 overflow-x-hidden md:p-12 md:px-16 flex items-center ${
          isEven ? 'bg-slate-100 flex-row' : 'flex-row-reverse'
        }`}
      >
        <div className=" w-full hidden md:block">
          <div
            className={`flex ${
              isEven ? 'justify-start' : 'justify-end'
            }`}
          >
            <div className="w-2/3 relative" data-aos= { isEven ? "fade-right" : "fade-left" }>
              <div className="">
                <div className="w-1/3 h-72 bg-green"></div>
                <div className="flex justify-end">
                  <div className="bg-green h-24 w-1/5 -mt-4"></div>
                </div>
              </div>
              <div className="w-full absolute top-0 bottom-0 p-4">
                <div className="w-full h-full bg-green-401">
                  <img
                    src={discipline.img}
                    alt={discipline.text}
                    className="w-full h-full object-cover"
                  />
                </div>
              </div>
              <div
                
                className={`absolute -bottom-4 p-4 w-[75%] text-center bg-white rounded-lg shadow-lg text-green md:text-lg lg:text-2xl font-semibold ${
                  isEven ? '-right-1/3' : '-left-1/2 border'
                }`}
              >
                {discipline.text}
              </div>
            </div>
          </div>

          <div
            className={`flex  ${
              isEven ? 'mr-28 justify-end' : 'justify-start ml-20'
            }`}
          >
            <img src={dots} alt="dots" />
          </div>
        </div>
        <div className="w-full" data-aos= { isEven ? "fade-left" : "fade-right" } >
          <h1 className="text-2xl text-neutral-700 font-semibold">
            {discipline.text} Discipline
          </h1>
          <div className="w-1/6 h-1 bg-green my-1.5"></div>
          <p className="my-8">{discipline.description}</p>
          {!isLast && (
            <button
              className="bg-green text-white flex items-center p-1 pr-4 rounded-full space-x-1.5 text-sm font-semibold hover:shadow-lg"
              onClick={handleNext}
            >
              <div className="text-neutral-700 bg-slate-100 p-2 text-sm rounded-full">
                <FaChevronDown />
              </div>
              <span>Next</span>
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default DisciplineSection;
