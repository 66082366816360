import React from 'react';
import NewPublication from '../components/Publications/NewPublication';
import AboutHeader from '../components/About/AboutHeader';

const NewPublicationPage = () => {
  return (
    <>
      <AboutHeader
        subTitle="RUPI Publications"
        details="Publish your work on our site and be able to share it with the world"
      />
      <div className="flex flex-col overflow-hidden items-center w-full bg-white p-8 py-6 md:py-12">
        <div className="flex flex-col items-stretch max-w-6xl w-full mx-auto">
          <h1
            className="text-lg md:text-3xl font-bold text-gray-800"
            data-aos="fade-down"
          >
            New Publication
          </h1>
          <NewPublication />
        </div>
      </div>
    </>
  );
};

export default NewPublicationPage;
