import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import success from '../assets/images/application/success.svg';
import attention from '../assets/images/icons/attention.png';
import pending from '../assets/images/icons/pending.jpg';
import { useFetcher } from '../api';
import Keys from '../constants/keys';
import { useSelector } from 'react-redux';
import { PopupBox } from '../components/Global/PopupBox';
import { useNavigate, useLocation } from 'react-router-dom';
import Benefits from '../components/Membership/benefits/Benefits';
import PopupModal from '../components/Global/PopupModal';

const MembershipStatus = () => {
  const [isAccepted, setIsAccepted] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const authenticatedUser = useSelector(
    state => state.auth.presentUser,
  );
  const [isPageLoading, setIsPageLoading] = useState(true);

  const handleStorePathMembershipStatus = () => {
    const link = '/membershipStatus';
    if (!authenticatedUser) {
      localStorage.setItem(Keys.REDIRECT_URL_KEY, link);
    }
  };

  const { data } = useFetcher('/api/applications/user/mine');
  const [application, setApplication] = useState(null);

  useEffect(() => {
    if (data && location.pathname == '/membershipStatus') {
      setApplication(data.data);
      setIsPageLoading(false);
    }
  }, [data, location.pathname]);

  useEffect(() => {
    if (application && application?.status !== "APPROVED" && application?.status !== "DISAPPROVED") {
      setIsPending(true);
    }
    if (application && application?.status === "APPROVED") {
      setIsAccepted(true);
    }
    if (application && application?.status === "DISAPPROVED") {
      setIsRejected(true);
    }
  }, [application, location]);
  

  const [showModal, setShowModal] = useState(false);

  return (
    <div className="mt-auto flex flex-col items-center w-full px-8 py-6 md:py-12">
      <div
        className="flex flex-col max-w-6xl w-full"
        data-aos="fade-up"
      >
        <h1 className="font-black text-brand-green">Membership</h1>
        <h2 className="text-3xl md:text-4xl font-extrabold tracking-wide mt-2">
          Your Membership Status
        </h2>

        {isAccepted && !isPageLoading ? (
          <div className="flex flex-col justify-start items-center md:w-1/2 mx-auto my-10 text-center">
            <img src={success} alt="Filling info success" />
            <h1 className="font-semibold text-lg mt-4">
              You are a member of RUPI
            </h1>
            { !application?.hasCertificate ?
            <>
              <p className="my-3 md:my-6 text-sm">
                We have reviewed and accepted your RUPI Membership application, click the button below to get your membership certificate.
              </p>
              <NavLink
                  to="/certificate"
                  className="bg-green text-white shadow-lg rounded-full px-4 py-2 text-xs hover:shadow-none"
              >
                  Get Your Certificate
              </NavLink>
            </>
            :
            <p className="my-3 md:my-6 text-sm">
                We have reviewed and accepted your RUPI Membership application, you are welcome to our family!
            </p>
            }
        </div>) :
        isPending && !isPageLoading ? (<div className="flex flex-col justify-start items-center md:w-1/2 mx-auto my-10 text-center">
            <img src={pending} alt="Filling info success" className='w-28'/>
            <h1 className="font-semibold text-lg mt-4">
                Your RUPI membership application is still under review...
            </h1>
            <p className="my-3 md:my-6 text-sm">
              We are still reviewing your application, Please check
              that you have paid the required application fee to get
              your application accepted. Click the button below to
              check your payments history.
            </p>
            <NavLink
              to="/payments"
              className="bg-green text-white shadow-lg rounded-full px-4 py-2 text-xs hover:shadow-none"
            >
              Check Payments History
            </NavLink>
          </div>
        ) : isRejected && !isPageLoading ? (
          <div className="flex flex-col justify-start items-center md:w-1/2 mx-auto my-10 text-center">
            <img
              src={attention}
              alt="Filling info success"
              className="w-28"
            />
            <h1 className="font-semibold text-lg mt-4">
              Your application for RUPI Membership has been rejected
            </h1>
            <p className="my-3 md:my-6 text-sm">
              We have reviewed your application for RUPI Membership
              and decided to reject it due to the fact that it didn't
              meet all the requirements.
            </p>
            <button
              className="bg-green text-white rounded-full px-4 py-2 text-xs"
              onClick={() => setShowModal(true)}
            >
              See reason
            </button>
          </div>
        ) : !application ? (
          <>
            <h1 className="flex justify-center items-center text-xl my-12 border border-black font-bold p-4 m-auto">
              You are not a member of RUPI!
            </h1>
            <Benefits />
          </>
        ) : (
          ''
        )}
      </div>
      <PopupModal
        onClose={() => setShowModal(false)}
        show={showModal}
        title={'Rejection Reason'}
      >
        <p>{application?.rejectReason}</p>
      </PopupModal>
      {!authenticatedUser && !isPageLoading && (
        <PopupBox
          logo={attention}
          status="Page Access Error!"
          buttonValue="Login"
          buttonColor="bg-green"
          onClick={event => {
            navigate('/login');
            handleStorePathMembershipStatus(event);
          }}
          message="Please Login to be able to view your membership status!"
        />
      )}
    </div>
  );
};

export default MembershipStatus;
