import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AiFillCloseCircle } from "react-icons/ai"
import { useSelector } from 'react-redux';
import { certificateMessageDisplayed } from '../../actions/auth'
import { connect } from 'react-redux';

const GetCertificate = ({ certificateMessage }) => {
    const [typedText, setTypedText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isLinkVisible, setIsLinkVisible] = useState(false);
    const [isShown, setIsShown] = useState(true);
    const authenticatedUser = useSelector(
        state => state.auth.presentUser,
      );
    const name = authenticatedUser?.firstName
    
    const userPayments = useSelector(
      state => state.payment.allUserPayments,
    );
    const validMembershipPaymentPlans = ["Junior", "Professional", "Consulting", "Company"];
    const membershipFeePayment = userPayments?.filter(payment => validMembershipPaymentPlans.includes(payment.paymentPlan)) || [];
    const latestPayment = membershipFeePayment.length - 1;

    const textToType =
      `Hi ${name}, RUPI team here! We wanted to let you know that we have successfully received an amount of ${membershipFeePayment[latestPayment].amountPaid} Rwf that you paid for our ${membershipFeePayment[latestPayment].paymentPlan} plan. You can now get your RUPI Membership Certificate. Click `;
  
    const linkText = (
      <>
        <NavLink to="/certificate" className="text-green underline mx-1">
          here
        </NavLink>
         to get your certificate.
      </>
    );
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        if (currentIndex < textToType.length) {
          setTypedText((prevText) => prevText + textToType[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
          setIsLinkVisible(true);
          clearInterval(intervalId);
        }
      }, 50);
  
      return () => clearInterval(intervalId);
    }, [currentIndex, textToType]);
  
    return (
        <>
        {  isShown && <div className="fixed z-50 flex items-start justify-end top-2 right-2">
            <div className="bg-white  rounded w-[370px]">
            <div className="relative p-3">
                <div className="absolute right-3 top-3">
                <AiFillCloseCircle className="text-2xl text-red-500 hover:cursor-pointer" onClick={() => {setIsShown(false); certificateMessage({hasMessageDisplayed: true})}}/>
                </div>
                <div className="p-3">
                {typedText}
                {isLinkVisible && linkText}
                </div>
            </div>
            </div>
        </div>
        
            }
        </>
    );
  };

  const mapDispatchToProps = dispatch => {
    return {
      certificateMessage: (body) => dispatch(certificateMessageDisplayed(body)),
    };
  };
  
  export default connect(
    null,
    mapDispatchToProps,
  )(GetCertificate);
