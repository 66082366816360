import React from 'react';

const MastercardIcon = ({ ...props }) => {
  return (
    <svg
      width="38"
      height="12"
      viewBox="0 0 38 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.1703 3.82662C19.1331 5.49845 20.6563 6.42724 21.808 6.98452C22.9597 7.5418 23.3684 7.91331 23.3684 8.43344C23.3684 9.21362 22.4396 9.54799 21.5851 9.58514C20.0619 9.62229 19.2074 9.17647 18.5015 8.8421L17.9443 11.3684C18.6502 11.7028 19.9505 11.9628 21.2879 12C24.4458 12 26.4892 10.4396 26.5263 8.02477C26.5263 4.97833 22.291 4.79257 22.3282 3.41796C22.3282 3.00929 22.7368 2.56347 23.5913 2.45201C24.0372 2.37771 25.1889 2.34056 26.5635 2.97214L27.0836 0.520122C26.3777 0.26006 25.4489 5.92024e-07 24.2601 5.92024e-07C21.2879 5.92024e-07 19.2074 1.56037 19.1703 3.82662ZM32.1362 0.185759C31.579 0.185759 31.0588 0.520123 30.8731 1.04025L26.3406 11.7771H29.4985L30.13 10.031H33.9938L34.3653 11.7771H37.1517L34.7368 0.185759H32.1362ZM32.582 3.34365L33.4737 7.6904H30.9845L32.582 3.34365ZM15.3808 0.185759L12.8916 11.7771H15.9009L18.3901 0.185759H15.3808ZM10.9226 0.185759L7.80186 8.06192L6.5387 1.37461C6.3901 0.631578 5.79566 0.185759 5.16408 0.185759H0.0742966L0 0.520122C1.04025 0.743032 2.2291 1.11455 2.97214 1.52322C3.41796 1.74613 3.52941 1.96904 3.67802 2.52631L6.05572 11.7771H9.21362L14.0805 0.185759H10.9226Z"
        fill="#060405"
      />
    </svg>
  );
};

export default MastercardIcon;
