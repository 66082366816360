import React from 'react';
import { useNavigate } from 'react-router';

const ProjectCard = ({ project }) => {
  const navigate = useNavigate();

  const moveToProject = () => {
    navigate(`/projects/${project.slug}`);
  };
  return (
    <div
      tabIndex={0}
      role="button"
      onClick={moveToProject}
      className="z-10 flex h-full flex-col bg-brand-green text-white"
      data-aos="fade-up"
    >
      <div className="flex flex-col">
        <img
          src={project.projectImage}
          alt={project.title}
          width="247px"
          height="247px"
          className="h-[247px] w-full object-cover"
        />
      </div>
      <div className="flex flex-col p-2">
        <h1 className="font-semibold tracking-wide">
          {project.title}
        </h1>
        {/* <p className="text-sm mt-1">{project.title}</p> */}
      </div>
    </div>
  );
};

export default ProjectCard;
