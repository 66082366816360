import React from 'react';
import { Services } from '../components/Home/services/Services';
import { About } from '../components/Home/About';
import { MembershipFees } from '../components/Home/MembershipFees/MembershipFees';
import { Why } from '../components/Home/Why/Why';
// import { Count } from '../components/Home/Count/Count';
import OurJourney from '../components/About/OurJourney';
import { Partners } from '../components/Home/Partners/Partners';
import { Subscribe } from '../components/Home/Subscribe/Subscribe';
import HowToApply from '../components/Membership/HowToApply';
import PresidentQuote from '../components/Home/PresidentQuote';

export const Home = () => {
  return (
    <div className="overflow-x-hidden">
      <PresidentQuote />
      <Services /> 
      <About />
      <HowToApply />
      <MembershipFees />
      <Why />
      {/* <Count /> */}
      <OurJourney />
      <Partners />
      {/* <Subscribe /> */}
    </div>
  );
};
