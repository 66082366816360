import { useFetcher } from '../../api';
import { useSearchParams } from 'react-router-dom';
import Blogs from '../../components/Blog/Blogs';
import Categories from '../../components/Blog/Categories';
import FeatureBlog from '../../components/Blog/FeatureBlog';
import PageHeader from '../../components/Global/PageHeader';
import { useEffect, useState } from 'react';
import DataWidget from '../../components/Global/DataWidget';
import Searchbar from '../../components/Global/Searchbar';

export const Blog = () => {
  const [searchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page')) || 1;
  const [page, setPage] = useState(currentPage);

  const [currentCategory, setCurrentCategory] = useState();
  const {
    data: categoriesData,
    isError: categoriesError,
    isLoading: categoriesLoading,
  } = useFetcher('/posts/getAllCategories');

  const {
    data: blogsData,
    isError: blogsError,
    isLoading: blogsLoading,
  } = useFetcher(
    `/posts/getAllPosts?page=${page}&perPage=5` +
      (currentCategory ? `&category=${currentCategory}` : ``),
  );

  const categories = categoriesData?.allCategories || [];

  const [firstBlog, ...blogs] = blogsData?.allAvailablePosts || [];

  const pagination = blogsData?.paginationDetails || {};

  useEffect(() => {
    setPage(currentPage);
    window.scrollTo({
      behavior: 'smooth',
      top: 170,
    });
  }, [currentPage]);

  const currentCategoryData = categories.find(
    item => item.slug === currentCategory,
  );

  return (
    <>
      <PageHeader
        title="Welcome To Our Blog"
        description="Learn more about different events and social activities held in RUPI"
      />
      {!blogsLoading && !firstBlog && !blogsError ? (
        <div className="flex justify-center w-full items-center py-12 text-center">
          <p className="text-gray-500 text-xl">
            There are no blogs available yet!
          </p>
        </div>
      ) : (
        <div className="md:p-8 md:px-16">
          {firstBlog ? <FeatureBlog blog={firstBlog} /> : null}
          <Searchbar
            currentLabel={currentCategoryData?.name || 'All'}
            selectItems={[
              { value: null, label: 'All' },
              ...categories.map(item => ({
                value: item.slug,
                label: item.name,
              })),
            ]}
            onSearch={value => setCurrentCategory(value)}
          />
          <h1 className="text-xl font-semibold my-4 mx-4 md:mx-0">
            Other posts
          </h1>
          <div className="flex flex-col md:flex-row items-start space-y-8 md:space-y-0 md:space-x-10">
            <DataWidget
              title={'News and events'}
              isLoading={blogsLoading}
              isError={blogsError}
            >
              <Blogs
                setNewPage={page => {
                  setPage(page);
                }}
                pages={pagination.totalPages}
                page={page}
                blogs={
                  !currentCategory
                    ? blogs
                    : blogs.filter(
                        blog =>
                          blog.categoryDetails.slug ===
                          currentCategory,
                      )
                }
              />
            </DataWidget>
            <DataWidget
              title={'Categories'}
              isLoading={categoriesLoading}
              isError={categoriesError}
              isEmpty={!categories.length}
            >
              <Categories
                onClick={slug => setCurrentCategory(slug)}
                active={currentCategory}
                categoriesData={categories}
              />
            </DataWidget>
          </div>
        </div>
      )}
    </>
  );
};
