import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Header } from './Header';
import logo from '../../../assets/images/icons/logo.jpg';
import { menuITems } from './data';
import {
  NavLink,
  useLocation
} from 'react-router-dom';
import {
  FaBars,
  FaFacebookSquare,
  FaInstagramSquare,
  FaPhoneAlt,
  FaRegTimesCircle,
  FaSearch,
  FaSignInAlt,
  FaTwitterSquare,
} from 'react-icons/fa';
import { RiLogoutCircleLine } from 'react-icons/ri';
import { PreNav } from './PreNav';
import MobileMenu from './components/MobileMenu';
import { getLoggedInUser, logout } from '../../../actions/auth';
import { getPayments } from '../../../actions/payment';
import { IoIosArrowDropdown } from 'react-icons/io';
import { CgProfile } from 'react-icons/cg';
import {
  MdOutlineAdminPanelSettings,
  MdOutlineAttachMoney,
} from 'react-icons/md';
import { RiLockPasswordLine } from 'react-icons/ri';
import { BsPeople } from 'react-icons/bs';
import { TbFileCertificate } from 'react-icons/tb';
import { API, getFeaturedBlog } from '../../../api';
import { useSelector } from 'react-redux';
import ExploreMenuItem from './elements/ExploreMenuItem';
import SearchContent from './components/SearchContent';
import FeaturedBlogCard from './components/FeaturedBlogCard';
import PulseLoader from 'react-spinners/PulseLoader';

const Nav = ({
  user,
  getUser,
  logoutUser,
  payments,
  getPayments,
  application: userApplication,
}) => {
  const [isSticky, setSticky] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [showPreMenu, setPreShowMenu] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [showSearchContent, setShowSearchContent] = useState(false);
  const [showFeaturedBlog, setShowFeaturedBlog] = useState(null);
  const [imageError, setImageError] = useState(false);
  const { pathname } = useLocation();
  const hash = window.location.hash.substring(1);
  const isHome = pathname === '/';
  const [application, setApplication] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isPaswordChangeLoading, setIsPaswordChangeLoading] = useState(false);
  const userPayments = useSelector(
    state => state.payment.allUserPayments,
  );
  const applicationFeePayment = userPayments?.filter(
    payment => payment.paymentPlan === 'Application Fee',
  );
  const latestFeePayment = applicationFeePayment?.length - 1;

  useEffect(() => {
    if (user && !userApplication) {
      async function getCurrentUserApplication() {
        try {
          const result = await API.get('/api/applications/user/mine');
          if (result.data?.data) {
            setApplication(result.data?.data);
          }
        } catch (error) {}
      }
      getCurrentUserApplication();
    }
  }, [user, userApplication]);

  useEffect(() => {
    setShowMenu(false);
    setShowSearchContent(false);

    async function getFeatured() {
      const blog = await getFeaturedBlog();
      if (blog && localStorage.getItem('blog') !== blog?.title) {
        setShowFeaturedBlog(blog);
      }
    }
    if (showFeaturedBlog === null) {
      getFeatured();
    }
  }, [pathname]);

  useEffect(() => {
    if (
      user !== 'null' &&
      application &&
      applicationFeePayment &&
      applicationFeePayment?.length == 0
    ) {
      localStorage.setItem(
        'price',
        JSON.stringify({
          id: application?.category == 'company' ? 2 : 1,
          amount:
            application?.category == 'company'
              ? '30,000 Rwf'
              : '10,000 Rwf',
          currency: '',
          paymentType: 'Application Fee',
        }),
      );
    }
    if (
      user !== 'null' &&
      application &&
      applicationFeePayment &&
      applicationFeePayment?.length !== 0 && 
      applicationFeePayment[latestFeePayment]?.transactionStatus !== 'Success'
    ) {
      localStorage.setItem(
        'price',
        JSON.stringify({
          id: application?.category == 'company' ? 2 : 1,
          amount:
            application?.category == 'company'
              ? '30,000 Rwf'
              : '10,000 Rwf',
          currency: '',
          paymentType: 'Application Fee',
        }),
      );
    }
  }, [user, application, applicationFeePayment]);

  const handleScroll = () => {
    const windowScrollTop = window.scrollY;

    if (windowScrollTop > 144) {
      setSticky(true);
      setShowMenu(false);
    } else {
      setSticky(false);
      setPreShowMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!user) {
      getUser();
    }
  }, [user]);

  useEffect(() => {
    if (user && !payments?.length) {
      getPayments();
    }
  }, [payments, user]);

  const Logout = () => {
    setIsLoading(true)
    logoutUser().then(() => window.location.reload());
    const IsGoogleUser = localStorage.getItem('IsGoogleUser');
    const price = localStorage.getItem('price');
    if (IsGoogleUser) {
      localStorage.removeItem('IsGoogleUser');
    }
    if (price) {
      localStorage.removeItem('price');
    }
  };

  const handleChangePassword = () => {
    setIsPaswordChangeLoading(true)
    logoutUser().then(() => window.location.replace('/forgotPassword'));
  };

  useMemo(() => {
    if (hash) {
      setTimeout(() => {
        const div = document.getElementById(hash);
        if (!div) return;
        const yOffset = -85;
        const y =
          div.getBoundingClientRect().top + window.scrollY + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }, 100);
    }
  }, [hash]);

  return (
    <>
    <div>
      <div className="relative">
        <div className="absolute top-0 left-0 right-0 z-10">
          <div className="flex shadow shadow-[#969b982e]">
            <div className="h-36 flex pl-8 py-2 bg-white">
              <img
                src={logo}
                alt="RUPI Logo"
                className="h-32 object-fill"
              />
            </div>
            <div className="relative w-full">
              <div className="h-36 absolute top-0 left-0 bottom-0">
                <div className="triangle-up"></div>
              </div>
              <div className="h-36 flex flex-col w-full">
                <div className="bg-[url('../images/nav/bg.jpg')] h-full flex">
                  <div className="hidden md:block w-16 h-full bg-transparent"></div>
                  <div className="flex justify-end md:justify-evenly w-full items-center text-white pr-2">
                    <div className="hidden md:flex items-center space-x-2">
                      <FaPhoneAlt />
                      <p>+250783453211</p>
                    </div>
                    <div className="hidden md:flex text-xl space-x-16 hover:cursor-pointer">
                      <FaFacebookSquare />
                      <FaInstagramSquare />
                      <FaTwitterSquare />
                    </div>
                    <div className="flex space-x-2">
                      <button
                        className="text-xs border border-neutral-400 py-2 px-3 md:py-3 md:px-5 rounded-full duration-300 hover:bg-white hover:text-green hover:font-semibold flex items-center space-x-2"
                        onClick={() => setShowSearchContent(true)}
                      >
                        <FaSearch />
                        <span className="hidden min-[400px]:block">
                          Search
                        </span>
                      </button>
                      {user ? (
                        <div className="relative">
                          {user?.picture && !imageError ? (
                            <div className="flex items-center gap-1">
                              <img
                                src={user?.picture.url}
                                alt={user?.name}
                                className="w-11 h-11 rounded-full hover:cursor-pointer"
                                onMouseOver={() => setIsShown(true)}
                                onMouseOut={() => setIsShown(false)}
                                onError={() => setImageError(true)}
                              />
                              <div>
                                <IoIosArrowDropdown />
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center gap-1">
                              <div
                                className="bg-green p-3 w-12 h-12 text-center rounded-full  hover:cursor-pointer"
                                onMouseOver={() => setIsShown(true)}
                                onMouseOut={() => setIsShown(false)}
                              >
                                {user?.firstName.charAt(0) +
                                  user?.lastName.charAt(0)}
                              </div>
                              <div>
                                <IoIosArrowDropdown />
                              </div>
                            </div>
                          )}

                          <div
                            className={`${
                              isShown ? 'visible' : 'invisible'
                            } bg-black shadow-2xl absolute w-[250px] right-0  mt-[1px] h-auto z-50`}
                            onMouseOver={() => setIsShown(true)}
                            onMouseOut={() => setIsShown(false)}
                          >
                            <NavLink
                              to="/profile"
                              className="flex items-center gap-2 py-3 hover:bg-green hover:cursor-pointer uppercase font-bold pl-5"
                            >
                              <CgProfile className="font-bold text-lg" />{' '}
                              Profile Settings
                            </NavLink>
                            { ( user?.role === 'admin' || user?.role === 'finance_admin' || user?.role === 'registrar_admin' || user?.role === 'super_admin' ) && (
                              <a
                                href={`${process.env.REACT_APP_DASHBOARD_URL}`}
                                target="__blank"
                                className="flex items-center gap-2 py-3 hover:bg-green hover:cursor-pointer border-t uppercase font-bold pl-5"
                              >
                                <MdOutlineAdminPanelSettings className="font-bold text-lg" />{' '}
                                Admin Panel
                              </a>
                            )}
                            <NavLink
                              to="/membershipStatus"
                              className="flex items-center gap-2 py-3 hover:bg-green hover:cursor-pointer border-t uppercase font-bold pl-5"
                            >
                              <BsPeople className="font-bold text-lg" />{' '}
                              Membership Status
                            </NavLink>
                            <NavLink
                              to="/payments"
                              className="flex items-center gap-2 py-3 hover:bg-green hover:cursor-pointer border-t uppercase font-bold pl-5"
                            >
                              <MdOutlineAttachMoney className="font-bold text-lg" />{' '}
                              Payments
                            </NavLink>
                            {application &&
                              application.status === 'APPROVED' &&
                              !application.hasCertificate && (
                                <NavLink
                                  to="/certificate"
                                  className="flex items-center gap-2 py-3 hover:bg-green hover:cursor-pointer border-t uppercase font-bold pl-5"
                                >
                                  <TbFileCertificate className="font-bold text-lg" />{' '}
                                  Get certificate
                                </NavLink>
                              )}
                            { user?.accountType === "Email" &&
                              <div
                              onClick={handleChangePassword}
                              className="flex items-center gap-2 py-3 hover:bg-green hover:cursor-pointer border-t uppercase font-bold pl-5"
                            >
                              <RiLockPasswordLine className="font-bold text-lg" />{' '}
                              {
                                isPaswordChangeLoading ? (
                                  <PulseLoader
                                    color="#ffffff"
                                    loading={true}
                                    size={10}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                ) : (
                                  'Change Password'
                                )
                              }
                            </div>
                            }
                            <div
                              className="flex items-center gap-2 py-3 hover:bg-green hover:cursor-pointer border-t uppercase font-bold pl-5"
                              onClick={Logout}
                            >
                              <RiLogoutCircleLine className="font-bold text-lg" />{' '}
                              {
                                isLoading ? (
                                  <PulseLoader
                                    color="#ffffff"
                                    loading={true}
                                    size={10}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                  />
                                ) : (
                                  'Logout'
                                )
                              }
                            </div>
                          </div>
                        </div>
                      ) : (
                        <NavLink
                          to="/login"
                          className="text-xs border border-neutral-400 py-2 px-3 md:py-3 md:px-5 rounded-full duration-300 hover:bg-white hover:text-green hover:font-semibold flex"
                        >
                          <FaSignInAlt className="min-[400px]:hidden" />
                          <span className="hidden min-[400px]:block">
                            Login / Sign Up
                          </span>
                        </NavLink>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={`h-full flex ${
                    isHome ? 'bg-[#031f0e2e]' : 'bg-[#0b450c]'
                  }`}
                >
                  <div className="w-24 h-full bg-transparent"></div>
                  <div
                    className="flex md:hidden justify-end w-full items-center px-4 text-white text-2xl"
                    onClick={() => setShowMenu(!showMenu)}
                  >
                    {showMenu ? (
                      <FaRegTimesCircle className="text-red-700 text-3xl" />
                    ) : (
                      <FaBars />
                    )}
                  </div>

                  <div className="hidden md:flex justify-evenly w-full items-center">
                    {menuITems.map((item, index) => {
                      if (!item.url) {
                        return (
                          <ExploreMenuItem item={item} key={index} />
                        );
                      }
                      return (
                        <NavLink
                          to={item.url}
                          key={index}
                          className="menu-link text-white py-1.5 hover:border-b-[2px] hover:border-white transition duration-300 ease-out text-xs lg:text-[16px]"
                        >
                          {item.text}
                        </NavLink>
                      );
                    })}
                    {/* </ul> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Navigation menu on mobile view */}
        {showMenu && (
          <div
            className={`absolute z-30 top-36 md:hidden flex-col w-full text-white`}
          >
            <MobileMenu menuITems={menuITems} />
          </div>
        )}
        {showPreMenu && (
          <div
            className={`fixed z-30 top-16 md:hidden flex-col w-full text-white`}
          >
            <MobileMenu menuITems={menuITems} />
          </div>
        )}

        <div className={`${isHome ? 'pt-16' : 'pt-36'}`}>
          {isHome && <Header />}
        </div>
      </div>
      {isSticky ? (
        <div className="fixed top-0 z-50 w-full h-16">
          <PreNav
            showMenu={showPreMenu}
            onClick={() => setPreShowMenu(!showPreMenu)}
          />
        </div>
      ) : null}
      {showSearchContent && (
        <SearchContent onClose={() => setShowSearchContent(false)} />
      )}
      {showFeaturedBlog && (
        <FeaturedBlogCard
          blog={showFeaturedBlog}
          onClose={() => setShowFeaturedBlog(null)}
        />
      )}
    </div>
    </>
  );
};

const mapStateToProps = state => ({
  user: state.auth.presentUser,
  token: state.auth.userToken,
  application: state.auth.application,
  payments: state.payment.allUserPayments,
});

const mapDispatchToProps = dispatch => {
  return {
    getUser: () => dispatch(getLoggedInUser()),
    logoutUser: () => dispatch(logout()),
    getPayments: () => dispatch(getPayments()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
