import project from '../../../assets/images/projects/project.png';

export const availableProjects = [
  {
    title: 'Land Survey and Urbanization',
    image: project,
    details: 'Muhanga District, Southern Provence',
  },
];

export const projectCategoryOptions = [
  {
    label: 'All',
    count: 112,
  },
  {
    label: 'Urban Planning',
    count: 26,
  },
  {
    label: 'GIS & RS',
    count: 12,
  },
  {
    label: 'Other',
    count: 6,
  },
];

export function getCategoriesWithCounts(projects) {
  const categories = projects.reduce((acc, { category }) => {
    if (!acc[category]) {
      acc[category] = { label: category, count: 1 };
    } else {
      acc[category].count++;
    }
    return acc;
  }, {});

  return Object.values(categories);
}

export function getFilteredProjects(AllProjects, category, exclude) {
  if (category === 'All')
    return AllProjects.filter(project => project.slug !== exclude);
  const filteredProjects = AllProjects.filter(
    project =>
      project.category === category && project.slug !== exclude,
  );
  return filteredProjects;
}
