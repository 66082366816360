import TextField from '../../elements/TextField';
import SelectTextField from '../../elements/SelectTextField';
import ChooseFileField from '../../elements/ChooseFileField';
import { FaTrash } from 'react-icons/fa';

import {
  FaAward,
  FaCalendarAlt,
  FaHouseUser,
  FaIdCard,
  FaPhoneAlt,
  FaPodcast,
  FaRegEnvelope,
  FaRegUser,
  FaUniversity,
  FaUsers,
} from 'react-icons/fa';
import { countries } from '../../../data/countries';
import { useState, useEffect } from 'react';
import NextButton from '../../elements/NextButton';

const PersonalInformation = ({
  register,
  errors,
  referees = [],
  setValue = () => {},
  onAddReferee = () => {},
  onRemoveReferee = () => {},
}) => {
  const [isOther, setOther] = useState(false);
  useEffect(() => {
    setValue('referee', referees[0], {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, []);
  return (
    <div>
      <div className="mb-12">
        <h1 className="text-lg font-semibold my-2">
          Personal Information
        </h1>
        <p className="text-sm">Tell us about you</p>
      </div>
      <div className="mb-16">
        <div className="flex md:space-x-10 flex-col md:flex-row">
          <TextField
            label="First Name"
            hint="Enter your first name here"
            suffix={<FaRegUser />}
            register={register('firstName')}
            error={errors.firstName?.message}
          />
          <TextField
            label="Last Name"
            hint="Enter your last name here"
            suffix={<FaRegUser />}
            register={register('lastName')}
            error={errors.lastName?.message}
          />
        </div>
        <div className="flex md:space-x-10 flex-col md:flex-row">
          <SelectTextField
            label="Gender"
            hint="Select gender"
            options={[
              { value: 'male', label: 'Male' },
              { value: 'female', label: 'Female' },
            ]}
            register={register('gender')}
            error={errors.gender?.message}
            onChange={value => {
              setValue('gender', value, {
                shouldDirty: true,
                shouldValidate: true,
              });
            }}
          />
          <TextField
            label="Email"
            hint="Enter your email address here"
            suffix={<FaRegEnvelope />}
            register={register('email')}
            error={errors.email?.message}
          />
        </div>
        <div className="flex md:space-x-10 flex-col md:flex-row">
          <TextField
            label="Phone Number"
            hint="+250 788 6385 773"
            suffix={<FaPhoneAlt />}
            register={register('phoneNumber')}
            error={errors.phoneNumber?.message}
          />
          <SelectTextField
            label="Nationality"
            hint="Rwanda"
            options={countries.map(country => ({
              value: country.name,
              label: country.name,
            }))}
            register={register('nationality')}
            error={errors.nationality?.message}
            onChange={value => {
              setValue('nationality', value, {
                shouldDirty: true,
                shouldValidate: true,
              });
            }}
          />
        </div>
        <div className="flex md:space-x-10 flex-col md:flex-row">
          <TextField
            label="ID or Passport"
            hint="Enter your ID here"
            suffix={<FaIdCard />}
            register={register('identificationNumber')}
            error={errors.identificationNumber?.message}
          />
          <TextField
            label="Date of Birth"
            hint="24-feb-2014"
            isDate={true}
            suffix={<FaCalendarAlt />}
            register={register('dateOfBirth')}
            error={errors.dateOfBirth?.message}
          />
        </div>
        <div className="flex md:space-x-10 flex-col md:flex-row">
          <TextField
            label="Place of Birth"
            hint="Huye, Rwanda"
            suffix={<FaHouseUser />}
            register={register('placeOfBirth')}
            error={errors.placeOfBirth?.message}
          />
          <TextField
            label="Place of Residence"
            hint="Kigali, Rwanda"
            suffix={<FaHouseUser />}
            register={register('placeOfResidence')}
            error={errors.placeOfResidence?.message}
          />
        </div>
      </div>
      <div className="mb-12">
        <h1 className="text-lg font-semibold my-2">Education</h1>
        <p className="text-sm">
          Let’s talk about your academic achievements
        </p>
      </div>
      <div className="mb-16">
        <div className="flex md:space-x-10 flex-col md:flex-row">
          <div className="flex flex-col w-full">
            <SelectTextField
              label="Level of Education"
              hint="Bachelors degree"
              options={[
                {
                  value: 'Post-doctorate degree',
                  label: 'Post-doctorate degree',
                },
                {
                  value: 'Doctorate or Doctoral degree',
                  label: 'Doctorate or Doctoral degree',
                },
                { value: "Master's", label: "Master's degree" },
                {
                  value: "Bachelor's degree",
                  label: "Bachelor's degree",
                },
                { value: 'secondary', label: 'High school' },
                { value: 'other', label: 'Other' },
              ]}
              register={register('education.level')}
              error={
                !isOther ? errors.education?.level?.message : null
              }
              onChange={value => {
                if (value === 'other') {
                  setOther(true);
                  setValue('education.level', undefined);
                } else {
                  setOther(false);
                  setValue('education.level', value);
                }
              }}
            />
            {isOther && (
              <TextField
                label="Other education level"
                hint="Enter your education degree here"
                suffix={<FaAward />}
                register={register('education.level')}
                error={errors.education?.level?.message}
              />
            )}
          </div>
          <TextField
            label="Institution"
            hint="Enter the institution name here"
            suffix={<FaAward />}
            register={register('education.institution')}
            error={errors.education?.institution?.message}
          />
        </div>
        <div className="flex md:space-x-10 flex-col md:flex-row">
          <div className="flex flex-col space-y-4 w-full">
            <TextField
              label="Field of specialization"
              hint="Enter the field name here"
              suffix={<FaAward />}
              register={register('education.field')}
              error={errors.education?.field?.message}
            />
            <SelectTextField
              label="Year of Graduation"
              hint="2017"
              options={[
                { value: '2023', label: '2023' },
                { value: '2022', label: '2022' },
                { value: '2021', label: '2021' },
                { value: '2020', label: '2020' },
                { value: '2019', label: '2019' },
                { value: '2018', label: '2018' },
                { value: '2017', label: '2017' },
                { value: '2016', label: '2016' },
                { value: '2015', label: '2015' },
                { value: '2014', label: '2014' },
                { value: '2013', label: '2013' },
                { value: '2012', label: '2012' },
                { value: '2011', label: '2011' },
                { value: '2010', label: '2010' },
                { value: '2009', label: '2009' },
                { value: '2008', label: '2008' },
                { value: '2007', label: '2007' },
                { value: '2006', label: '2006' },
                { value: '2005', label: '2005' },
                { value: '2004', label: '2004' },
                { value: '2003', label: '2003' },
                { value: '2002', label: '2002' },
                { value: '2001', label: '2001' },
                { value: '2000', label: '2000' },
                { value: 'Below 2000', label: 'Below 2000' },
              ]}
              register={register('education.graduationYear')}
              error={errors.education?.graduationYear?.message}
              onChange={value => {
                setValue('education.graduationYear', value, {
                  shouldDirty: true,
                  shouldValidate: true,
                });
              }}
            />
          </div>
          <ChooseFileField
            label="Diplomes and Certificates"
            register={register('certificates')}
            error={errors.certificates?.message}
            onChange={value => {
              setValue('certificates', value, {
                shouldDirty: true,
                shouldValidate: true,
              });
            }}
          />
        </div>
      </div>
      <div className="mb-2">
        <h1 className="text-lg font-semibold">Motivational Letter</h1>
      </div>
      <div className="mb-16">
        <ChooseFileField
          label={
            'Write a motivational letter addressed to the CEO of RUPI'
          }
          register={register('motivationLetter')}
          isMultiple={false}
          error={errors.motivationLetter?.message}
          onChange={value => {
            setValue('motivationLetter', !!value ? value : null, {
              shouldDirty: true,
              shouldValidate: true,
            });
          }}
        />
      </div>
      <div className="mb-2">
        <h1 className="text-lg font-semibold">CV</h1>
      </div>
      <div className="mb-16">
        <ChooseFileField
          label={'Upload your resume'}
          register={register('cv')}
          isMultiple={false}
          error={errors.cv?.message}
          onChange={value => {
            setValue('cv', value, {
              shouldDirty: true,
              shouldValidate: true,
            });
          }}
        />
      </div>
      <div className="mb-12">
        <h1 className="text-lg font-semibold my-2">
          Professional referee
        </h1>
        <p className="text-sm">
          Someone who can confirm that the above provided information
          is true
        </p>
      </div>
      <div className="mb-12">
        <div className="flex items-center space-x-3 overflow-x-auto">
          {referees.map((referee, index) => (
            <div
              key={index}
              className="flex items-center space-1 border border-gray-400 hover:border-brand-green rounded-full"
            >
              <button
                type="button"
                onClick={() => {
                  setValue('referee', referee, {
                    shouldDirty: true,
                    shouldValidate: true,
                  });
                }}
                className="p-1 pl-3 text-sm"
              >{`Referee ${index + 1}`}</button>
              <button
                type="button"
                onClick={() => onRemoveReferee(index)}
                className="text-red-400 hover:text-red-500 p-1 pr-3"
              >
                <FaTrash size={16} />
              </button>
            </div>
          ))}
        </div>
        <div className="flex md:space-x-10 flex-col md:flex-row">
          <TextField
            label="Names"
            hint="Enter the names here"
            suffix={<FaRegUser />}
            register={register('referee.name')}
            error={errors.referee?.name?.message}
          />
          <TextField
            label="Institution"
            hint="Enter the institution here"
            suffix={<FaUniversity />}
            register={register('referee.institution')}
            error={errors.referee?.institution?.message}
          />
        </div>
        <div className="flex md:space-x-10 flex-col md:flex-row">
          <TextField
            label="Position"
            hint="Enter the position here"
            suffix={<FaPodcast />}
            register={register('referee.position')}
            error={errors.referee?.position?.message}
          />
          <TextField
            label="Email"
            hint="Enter the email address here"
            suffix={<FaRegEnvelope />}
            register={register('referee.email')}
            error={errors.referee?.email?.message}
          />
        </div>
        <div className="flex md:space-x-10 flex-col md:flex-row">
          <TextField
            label="Phone Number"
            hint="Enter the phone number here"
            suffix={<FaPhoneAlt />}
            register={register('referee.phoneNumber')}
            error={errors.referee?.phoneNumber?.message}
          />
          <TextField
            label="What is your relationship with this person?"
            hint="My managing director"
            suffix={<FaUsers />}
            register={register('referee.relationship')}
            error={errors.referee?.relationship?.message}
          />
        </div>
        <NextButton
          type="button"
          label="Add Referee"
          onClick={onAddReferee}
          className="opacity-70 hover:opacity-100"
        />
      </div>
    </div>
  );
};

export default PersonalInformation;
