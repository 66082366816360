import React from 'react';
import { menuITems } from './data';
import { Link, NavLink } from 'react-router-dom';
import logo from '../../../assets/images/icons/logo_white.png';
import { FaBars, FaRegTimesCircle } from 'react-icons/fa';
import ExploreMenuItem from './elements/ExploreMenuItem';

export const PreNav = ({ showMenu, onClick }) => {
  return (
    <div className="bg-[#0b450c] h-full flex items-center px-4 lg:px-16 justify-between">
      <div className="w-24 bg-transparent">
        <img src={logo} alt="RUPI logo" />
      </div>
      <div className="hidden md:flex justify-end w-full items-center space-x-4 lg:space-x-10 xl:space-x-12">
        {menuITems.map((item, index) => {
          if (!item.url) {
            return <ExploreMenuItem item={item} key={index} />;
          }
          return (
            <NavLink
              to={item.url}
              key={index}
              className="menu-link text-white py-1.5 hover:border-b-[2px] hover:border-white transition duration-300 ease-out"
            >
              {item.text}
            </NavLink>
          );
        })}
        {/* </ul> */}
      </div>
      <div className="md:hidden" onClick={onClick}>
        {showMenu ? (
          <FaRegTimesCircle className="text-red-700 text-3xl" />
        ) : (
          <FaBars className="text-2xl text-white" />
        )}
      </div>
    </div>
  );
};
