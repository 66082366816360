import {
  GET_SERVICES,
  SERVICES_ERROR,
} from '../constants/actionTypes';
import * as api from '../api/index.js';

export const getAllServices = () => {
  return async dispatch => {
    try {
      const result = await api.getServices();
      if (!result.data.allServices) {
        throw new Error('Failed to retrieve services');
      }
      dispatch({
        type: GET_SERVICES,
        payload: {
          services: result.data.allServices,
        },
      });
    } catch (error) {
      dispatch({
        type: SERVICES_ERROR,
        payload: {
          error: {
            action: GET_SERVICES,
            message: 'Unable to retrieve services at this time.',
          },
        },
      });
    }
  };
};
