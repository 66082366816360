import React from 'react';

const SideNavbar = ({
  current,
  onChangeCurrent,
  categoryOptions,
}) => {
  return (
    <div className="flex h-fit px-[9px]" data-aos = "fade-right">
      <div className="min-h-[200px] h-full w-[6px] bg-brand-green" />
      <div className="flex flex-col h-full space-y-4 -translate-x-[14px] py-4">
        {categoryOptions.map(category => (
          <div
            role="button"
            tabIndex={0}
            key={category.label}
            className="flex items-center space-x-3"
            onClick={() => onChangeCurrent(category)}
          >
            <div
              className={`w-[20px] h-[20px] rounded-full border-2 border-brand-green ${
                current.label === category.label ? 'bg-brand-green' : 'bg-white'
              }`}
            />
            <p
              className={`whitespace-nowrap ${
                current.label === category.label ? 'text-brand-green' : ''
              }`}
            >{`${category.label} (${category.count})`}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SideNavbar;
