import React, { useState } from 'react';
import NextButton from '../elements/NextButton';
import PrevButton from '../elements/PrevButton';
import { FaCheckDouble } from 'react-icons/fa';
import { fees } from '../../data/fees';
import { useSelector } from 'react-redux';

const AcceptFee = ({
  membership,
  applicationCategoryId,
  onPrev,
  onNext,
  accepted = false,
}) => {
  const [isAccepted, setIsAccepted] = useState(accepted);

  const authenticatedUser = useSelector(
    state => state.auth.presentUser,
  );

  return (
    <div>
      <div className="shadow-lg rounded-lg flex flex-col items-center border-blue md:w-2/3 lg:w-1/2 mx-auto px-8 border-[0.5px]">
        <h1 className="text-lg font-semibold my-5">
          Application fee
        </h1>
        <p className="text-sm text-center">
          Hi {authenticatedUser.firstName}, you've chosen {membership} application. For this application we charge an amount of {' '}
          <span className="text-blue font-semibold">
            {fees[membership]}
          </span>{' '}
          as an application fee. This
          non-refundable amount will be paid at the end of the application
          process.
        </p>
        <div className="my-8 text-center border border-blue p-2 px-5 rounded-md text-blue font-bold">
          {fees[membership]}
        </div>
        <div
          className="flex items-center space-x-2 cursor-pointer mb-8"
          onClick={() => {
            setIsAccepted(!isAccepted)
            localStorage.setItem(
              'price',
              JSON.stringify({
                id: applicationCategoryId,
                amount: fees[membership],
                currency: '',
                paymentType: 'Application Fee'
              }),
            );
          }}
        >
          <div className="border-2 border-blue w-8 h-8 flex items-center justify-center rounded">
            {isAccepted && <FaCheckDouble className="text-blue" />}
          </div>
          <h1>I understand.</h1>
        </div>
      </div>

      <div>
        <div className="flex justify-between mt-16 mb-8">
          <PrevButton onClick={onPrev} />
          <NextButton
            disabled={!isAccepted}
            onClick={() => {
              if (!isAccepted) {
                return;
              }
              onNext(isAccepted);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AcceptFee;
