import React from 'react';
import StarIcon from '../../assets/images/icons/svg/StarIcon';
import avatar from '../../assets/images/about/avatar.jpeg';
import { membersData } from './data/members';
import { NavLink } from 'react-router-dom';

const OurMission = () => {
  return (
    <div
      className="mt-auto flex flex-col items-center w-full bg-white px-8 py-6 md:py-12"
      id="Our-mission"
    >
      <div className="flex flex-col gap-4 md:gap-x-16 md:justify-between md:items-center items-start md:flex-row max-w-6xl w-full">
        <div
          className="flex space-y-3 md:space-y-6 flex-col"
          data-aos="fade-right"
        >
          <div className="flex items-center space-x-2">
            {[1, 2, 3, 4, 5].map(item => (
              <StarIcon key={item} width={24} height={24} />
            ))}
          </div>
          <p className="max-w-xl tracking-wide text-lg md:text-lg leading-relaxed">
            “Our mission is to promote the profession of urban and
            rural planning in Rwanda through continuous capacity
            building to empower our member and the community in
            developing and managing sustainable plans; setting of code
            of ethics governing the profession and ensure it's
            compliance therewith and supervising the self-respect,
            secrecy and integrity of its members in the practice of
            their profession”
          </p>
          <div className="flex space-x-4 space-y-3 items-start">
            <img
              src={avatar}
              alt=""
              width={48}
              height={48}
              className="rounded-full w-20 h-20 object-contain"
            />
            <div className="flex flex-col">
              <h3 className="text-lg font-semibold">
                Vivien MUNYABURANGA
              </h3>
              <p className="">RUPI President</p>
            </div>
          </div>
        </div>

        <div
          className="flex flex-wrap w-full md:max-w-[30%] gap-y-4 gap-x-[4%] md:mx-auto"
          data-aos="zoom-in"
        >
          {membersData.map((member, index) => (
            <div
              to="#"
              className="shrink-0 flex flex-col w-[46%]"
              key={index}
            >
              <img
                src={member.image}
                height={member.height}
                className={`${member.top && 'mt-auto'} ${
                  index > 1 && 'md:-translate-x-4'
                }`}
                alt=""
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurMission;
