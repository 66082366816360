import React, { useEffect, useState } from 'react';
import ProjectCard from './ProjectCard';
import SideNavbar from './SideNavbar';
import {
  getCategoriesWithCounts,
  getFilteredProjects,
} from './data/projects';
import { getAllProjects } from '../../actions/project';
import { connect } from 'react-redux';
import ProjectPagination from './ProjectPagination';
import ProjectCardLoader from './ProjectCardLoader';
import DataWidget from '../Global/DataWidget';

const ProjectCardList = ({
  projects,
  error,
  loading,
  getAvailableProjects,
  paginationDetails,
  exclude,
}) => {
  const allCategory = {
    label: 'All',
    count: exclude ? projects.length - 1 : projects.length,
  };
  const [current, setCurrent] = useState(allCategory);

  const onChangeCurrent = item => {
    setCurrent(item);
  };

  useEffect(() => {
    if (projects.length === 0) {
      getAvailableProjects({ pageSize: exclude ? 7 : 6 });
    }
  }, [projects.length]);

  const categories = [
    allCategory,
    ...getCategoriesWithCounts(projects),
  ];

  const filtered = getFilteredProjects(
    projects,
    current.label,
    exclude,
  );

  useEffect(() => {
    if (filtered.length === 0) {
      setCurrent(allCategory);
    }
  }, [filtered.length]);

  return (
    <>
      <DataWidget
        // isLoading={loading}
        title={'Projects'}
        isError={error ? { message: error } : null}
        isEmpty={
          !projects.length &&
          !error &&
          !loading &&
          paginationDetails.currentPage === 1
        }
      >
        <>
          <h1
            className="text-lg md:text-3xl font-bold text-gray-800"
            data-aos="fade-down"
          >
            Our Projects
          </h1>
          <select
            className="mt-3 md:hidden w-full bg-white border border-gray-300 rounded-md py-2 px-3 text-gray-800 text-sm focus:outline-none focus:ring-brand-green focus:border-brand-green"
            onChange={event => {
              const matchedCategory = categories.find(
                category => category.label === event.target.value,
              );
              setCurrent(matchedCategory);
            }}
          >
            {categories.map((category, index) => (
              <option
                key={category.label}
                value={category.label}
                className={`whitespace-nowrap ${
                  current.label === category.label
                    ? 'text-brand-green'
                    : ''
                }`}
              >{`${category.label} (${category.count})`}</option>
            ))}
          </select>
          <div className="flex flex-col md:flex-row items-start w-full mt-6 md:mt-8">
            <div className="hidden md:block">
              <SideNavbar
                current={current}
                onChangeCurrent={onChangeCurrent}
                categoryOptions={categories}
              />
            </div>
            <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-3 flex-grow">
              {!projects.length && !error && loading
                ? [...Array(24)].map((_, index) => (
                    <ProjectCardLoader key={index} />
                  ))
                : null}
              {filtered.map((project, index) => (
                <ProjectCard key={index} project={project} />
              ))}
            </div>
          </div>
        </>
      </DataWidget>
      {paginationDetails && paginationDetails.totalPages > 1 && (
        <div className="mt-6 md:mt-12 max-w-4xl ml-auto w-full flex items-center justify-end">
          <ProjectPagination
            paginationDetails={paginationDetails}
            onNext={page => {
              getAvailableProjects({ page });
            }}
            onPrev={page => {
              getAvailableProjects({ page });
            }}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => ({
  projects: state.project.projects,
  paginationDetails: state.project.paginationDetails,
  error: state.project.error,
  loading: state.project.loading,
});

const mapDispatchToProps = dispatch => {
  return {
    getAvailableProjects: queries =>
      dispatch(getAllProjects(queries)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectCardList);
