import React from 'react';

const StarIcon = ({ ...props }) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.087 24.8328C18.4706 24.8328 17.6797 24.6351 16.691 24.0535L13.2132 21.9948C12.8527 21.7854 12.1548 21.7854 11.8059 21.9948L8.3165 24.0535C6.25776 25.2748 5.04811 24.7863 4.50144 24.3908C3.9664 23.9954 3.12895 22.9834 3.67562 20.6572L4.50144 17.0864C4.59449 16.7142 4.40839 16.0745 4.12924 15.7953L1.24468 12.9108C-0.197595 11.4685 -0.081282 10.2356 0.11645 9.6307C0.314182 9.02587 0.942271 7.9558 2.94285 7.61849L6.65323 7.00203C7.00217 6.94387 7.50231 6.57167 7.65352 6.25763L9.71225 2.15179C10.6427 0.279151 11.864 0 12.5037 0C13.1435 0 14.3647 0.279151 15.2952 2.15179L17.3424 6.246C17.5052 6.56004 18.0053 6.93224 18.3543 6.9904L22.0647 7.60686C24.0769 7.94416 24.705 9.01424 24.8911 9.61907C25.0772 10.2239 25.1935 11.4568 23.7628 12.8991L20.8783 15.7953C20.5991 16.0745 20.4246 16.7026 20.5061 17.0864L21.3319 20.6572C21.8669 22.9834 21.0411 23.9954 20.5061 24.3908C20.2153 24.6002 19.75 24.8328 19.087 24.8328ZM12.5037 20.0873C13.0737 20.0873 13.6436 20.2268 14.0972 20.4943L17.575 22.5531C18.5869 23.1579 19.2266 23.1579 19.4709 22.9834C19.7151 22.809 19.8896 22.1925 19.6337 21.0527L18.8079 17.4818C18.5869 16.5165 18.9475 15.2719 19.6453 14.5624L22.5299 11.6778C23.0998 11.1079 23.3557 10.5496 23.2394 10.1658C23.1115 9.78191 22.5764 9.46786 21.7855 9.33992L18.0751 8.72346C17.1795 8.57225 16.2025 7.85111 15.7954 7.03692L13.7483 2.94271C13.3761 2.19831 12.9108 1.75632 12.5037 1.75632C12.0966 1.75632 11.6314 2.19831 11.2708 2.94271L9.21211 7.03692C8.80502 7.85111 7.82799 8.57225 6.93238 8.72346L3.23363 9.33992C2.44271 9.46786 1.90767 9.78191 1.77972 10.1658C1.65178 10.5496 1.9193 11.1195 2.48923 11.6778L5.37379 14.5624C6.07166 15.2603 6.43223 16.5165 6.21124 17.4818L5.38542 21.0527C5.1179 22.2041 5.304 22.809 5.54826 22.9834C5.79251 23.1579 6.4206 23.1463 7.44416 22.5531L10.9219 20.4943C11.3639 20.2268 11.9338 20.0873 12.5037 20.0873Z"
        fill="#008D41"
      />
    </svg>
  );
};

export default StarIcon;
