export const journeyData = [
  {
    title: 'Members',
    count: '100+',
    details: 'We have a partnership with 100+ amazing companies',
  },
  {
    title: 'Partners',
    count: '30+',
    details: 'We have an experience of working with 30+ amazing companies and organizations',
  },
  {
    title: 'Years of Journey',
    count: '10',
    details: 'We have a have been in the industry for 10 whole years',
  },
];
