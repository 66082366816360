import React from 'react';
import {
  FaPlay,
  FaPlayCircle,
  FaRegPlayCircle,
  FaVideo,
} from 'react-icons/fa';
import image from '../../assets/images/header/headerImage.jpg';

const HowToApply = () => {
  return (
    <div
      className="flex bg-gray-301 overflow-hidden px-6 md:px-12 py-16 items-center flex-col md:flex-row space-y-4 md:space-y-0"
      id="how-to-apply"
    >
      <div className="w-full">
        <div className="flex flex-col items-center text-center md:text-left md:items-start justify-center space-y-6 w-full">
          <h1 className="text-4xl font-bold" data-aos="fade-right">
            How To Apply for Membership at RUPI
          </h1>
          <p className="pb-4 md:pb-2" data-aos="fade-right">
            Joining the Rwanda Urban Planners Institute can provide a
            range of benefits to urban planners, including
            professional development, networking opportunities, access
            to resources, advocacy, and recognition.
          </p>

          <a
            href={'https://www.youtube.com/watch?v=jZcvefZor3g'}
            target="_blank"
            data-aos="fade-up"
            className="bg-green text-white text-sm px-8 py-2 font-semibold hover:shadow-lg rounded-full flex items-center space-x-2"
          >
            <FaVideo />
            <span>Watch Tutorial</span>
          </a>
        </div>
      </div>
      <div className="w-full" data-aos="fade-left">
        <div className="relative h-48 ease-in duration-500">
          <img src={image} className="h-full w-full object-contain" />
          <div className="absolute top-[calc(50%-24px)] right-[calc(50%-24px)]">
            <a
              href={'https://www.youtube.com/watch?v=jZcvefZor3g'}
              target="_blank"
              data-aos="fade-up"
              className="text-5xl text-white hover:text-slate-200"
            >
              <FaRegPlayCircle />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToApply;
