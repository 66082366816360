import React from 'react';
import { validatePhoneNumber } from '../../lib/helpers/phone';
import { API } from '../../api';
import PopupModal from '../Global/PopupModal';
import TextInput from './TextInput';
import PulseLoader from 'react-spinners/PulseLoader';
import { useNavigate } from 'react-router-dom';

const PayWithMomo = ({
  visible,
  logoIcon,
  paymentMode,
  payerName,
  numberPlaceholder,
  amount,
  currency,
  itemId,
  onToggle,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [phoneValue, setPhoneValue] = React.useState('');
  const navigate = useNavigate();

  const onClose = () => {
    onToggle(false);
    if(isSuccess){
      navigate('/payments')
    }
  };
  const handleSubmit = async event => {
    event.preventDefault();
    setIsSuccess(false);
    setErrors(prev => ({
      ...errors,
      phoneNumber: null,
      message: null,
    }));
    const phoneNumber = phoneValue;
    if (!phoneNumber) {
      setErrors(prev => ({
        ...prev,
        phoneNumber: 'Phone number is required',
      }));
      return;
    }
    const isValid = !validatePhoneNumber(phoneNumber);
    if (!isValid) {
      setErrors(prev => ({
        ...prev,
        phoneNumber: 'Invalid phone number',
      }));
      return;
    }
    const payload = {
      telephoneNumber: phoneNumber,
      itemId,
      paymentMethod: paymentMode,
      payerName: payerName
    };

    try {
      setLoading(true);
      await API.post(`/api/opay/paymentrequest`, payload).then(response =>{ 
        if(response.data.failedTransaction) {
          const message = response.data.failedTransaction 
          setErrors({ ...errors, message });
        }
        else{
          setIsSuccess(true);
        }
        });
    } catch (error) {
      const message =
        "Something went wrong! That's all we know.";
      setErrors({ ...errors, message });
    } finally {
      setLoading(false);
    }
  };
  return (
    <React.Fragment>
      <button
        type="button"
        onClick={() => onToggle(!visible)}
        className="flex items-center space-x-2 bg-gray-200 px-4 py-2 rounded"
      >
        <img src={logoIcon} alt="" className="h-10 w-10" />
        <span>Pay with {paymentMode}</span>
      </button>
      <PopupModal
        onClose={onClose}
        show={visible}
        title={`Pay with ${paymentMode}`}
      >
        <div className="px-2 pb-4">
          <h1 className="text-2xl font-bold flex items-center space-x-2 justify-center">
            <img src={logoIcon} alt="" className="h-10 w-10" />
          </h1>
          {isSuccess ? (
            <div className="rounded-md mt-4 p-4 text-sm text-center font-normal bg-green text-white">
              Check your phone for a payment request...
            </div>
          ) : null}
          {errors.message ? (
            <div className="rounded-md mt-4 p-4 text-sm text-center font-normal bg-red-500 text-white">
              {errors.message}
            </div>
          ) : null}
          <form
            onSubmit={handleSubmit}
            className="flex flex-col items-center mt-6"
          >
            <div className="flex flex-col w-full">
              <div className="mb-2 block">
                <label htmlFor="amount" value="Amount" />
              </div>
              <TextInput
                type="text"
                id="amount"
                value={amount + ' ' + currency}
                disabled={true}
              />
              <div className="mb-2 block mt-4">
                <label htmlFor="phone" value="Phone Number" />
              </div>
              <TextInput
                type="text"
                id="phone"
                value={phoneValue}
                onChange={value => setPhoneValue(value)}
                placeholder={numberPlaceholder}
              />
              {errors.phoneNumber && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.phoneNumber}
                </p>
              )}
            </div>
            <button
              type="submit"
              className="disabled:cursor-not-allowed disabled:opacity-80 mt-6 px-12 rounded-lg py-3 uppercase font-semibold text-slate-800 bg-gradient-to-r from-[#008D41] to-[#67C870]"
            >
              {
                loading ? (
                  <PulseLoader
                    color="#000000"
                    loading={true}
                    size={10}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  `Pay ${amount + ' ' + currency}`
                )
              }
            </button>
          </form>
        </div>
      </PopupModal>
      {/* <Modal show={visible} size="md" popup={true} onClose={onClose}>
        <Modal.Header />
        <Modal.Body></Modal.Body>
      </Modal> */}
    </React.Fragment>
  );
};

export default PayWithMomo;
