import { AUTH, LOGIN, REGISTER, LOGOUT, FORGOT_PASSWORD, NEW_PASSWORD, FETCH__USER, GET_APPLICATION, CERTIFICATE_MESSAGE_DISPLAYED } from '../constants/actionTypes';
import * as api from '../api/index.js';
import Keys from '../constants/keys';

export const signup = (formData) => async (dispatch) => {
    try {
      const { data } = await api.signUp(formData);

      dispatch({ type: REGISTER, data });
    
    } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
          } else {
            return 'Something went wrong. Please try again!';
        }
    }
};
  
export const signin = (formData, router) => async (dispatch) => {
  try {
    const  result  = await api.signIn(formData);

    dispatch({
      type: LOGIN,
      payload: {
        presentUser: result.data.result,
        userToken: result.data.Access_Token,
      },
    });

    const link = localStorage.getItem(Keys.REDIRECT_URL_KEY);
    if (link) {
      localStorage.removeItem(Keys.REDIRECT_URL_KEY);
      router(link);
    } else {
      router('/');
    }

  } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return 'Something went wrong. Please try again!';
        }
  }
};

export const getLoggedInUser = () => {
  return async dispatch => {
    try {
      const result = await api.loggedInUser();
      if (!result.data.loggedInUser) {
        throw new Error('Failed to fetch the logged In User');
      }
      dispatch({
        type: FETCH__USER,
        payload: {
          presentUser: result.data.loggedInUser,
        },
      });   
    } catch (error) {
      if(error){
        // console.log('User not logged In');
      }
      
    }
  };
};

export const logout = () => {
  return async dispatch => {
    try {
      const result = await api.logoutUser();
      dispatch({
        type: LOGOUT,
        payload: {
          presentUser: result.data.successMessage,
        },
      });
    } catch (error) {
      // console.log(error);
    }
  };
};
  
export const googleauth = (formData, router) => async (dispatch) => {
  try {
    const result = await api.googleAuth(formData);

    dispatch({
      type: LOGIN,
      payload: {
        presentUser: result.data.result,
      },
    });

    const link = localStorage.getItem(Keys.REDIRECT_URL_KEY);
    if (link) {
      localStorage.removeItem(Keys.REDIRECT_URL_KEY);
      router(link);
    } else {
      router('/');
    }
    
  } catch (error) {
        if (error.response && error.response.data) {
            return error.response.data;
        } else {
            return 'Something went wrong. Please try again!';
        }
  }
};

export const forgotpassword = (formData) => async (dispatch) => {
  try {
    const { data } = await api.forgotPassword(formData);

    dispatch({ type: FORGOT_PASSWORD, data });
  
  } catch (error) {
      if (error.response && error.response.data) {
          return error.response.data;
        } else {
          return 'Something went wrong. Please try again!';
      }
  }
};

export const newpassword = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.newPassword(formData);

    dispatch({ type: NEW_PASSWORD, data });
    
    router('/login');
  
  } catch (error) {
      if (error.response && error.response.data) {
          return error.response.data;
        } else {
          return 'Something went wrong. Please try again!';
      }
  }
};

export const updateuser = (formData, router) => async (dispatch) => {
  try {
    const { data } = await api.updateUser(formData);

    dispatch({ type: AUTH, data });
    
    router('/profile');

    return data;
  
  } catch (error) {
      if (error.response && error.response.data) {
          return error.response.data;
        } else {
          return error;
      }  
  }
};

export const getApplications = () => {
  return async dispatch => {
    try {
      const result = await api.getApplications();
      if (!result.data.data) {
        throw new Error('Failed to fetch the logged In User');
      }
      dispatch({
        type: GET_APPLICATION,
        payload: {
          application: result.data.data,
        },
      });
    } catch (error) {
      if(error){
        // console.log('User not logged In');
      }
      
    }
  };
};

export const certificateMessageDisplayed = (body) => {
  return async dispatch => {
    try {
      const response = await api.showCertificateMessage(body);
      dispatch({
        type: CERTIFICATE_MESSAGE_DISPLAYED,
        payload: {
          certificateMessage: response.data.updatedUser,
        },
      });
    } catch (error) {
      // console.log(error);
    }
  };
};

