import React from 'react';
import moment from 'moment';

const ServiceCard = ({ service }) => {
  return (
    <div className="relative flex flex-col pt-2 pb-8 -translate-x-4 sm:translate-x-0">
      <div
        className="z-10 flex h-full flex-col bg-white rounded-xl p-8 md:p-12"
        data-aos="zoom-in"
      >
        <h1 className="font-semibold text-lg tracking-wide">
          {service.serviceTitle}
        </h1>
        <p className="mt-3 md:mt-4 text-sm">
          {service.serviceDescription}
        </p>
      </div>
      <div className="-rotate-2 translate-x-6 z-0 top-0 left-0 absolute flex-grow w-full h-full bg-brand-green rounded-xl" />
    </div>
  );
};

export default ServiceCard;
