import {
  GET_SERVICES,
  SERVICES_ERROR,
} from '../constants/actionTypes';

const initServiceState = {
  error: null,
  loading: true,
  services: [],
};

const serviceReducer = (state = initServiceState, action) => {
  switch (action.type) {
    case GET_SERVICES:
      return {
        error: null,
        loading: false,
        services: [...action.payload.services],
      };
    case SERVICES_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      };
    default:
      return state;
  }
};

export default serviceReducer;
