import React from 'react';

const RocketIcon = ({ ...props }) => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.3438 22.5422L23.6037 25.9811C21.6806 27.6576 19.4211 28.9033 16.9769 29.6346L17.9693 34.2645C18.02 34.5009 18.1256 34.7221 18.2775 34.9102C18.4294 35.0983 18.6235 35.248 18.8439 35.3473C19.0644 35.4465 19.3051 35.4926 19.5466 35.4817C19.7881 35.4708 20.0237 35.4033 20.2344 35.2845L26.255 31.8888C27.1024 31.3739 27.7643 30.6032 28.1453 29.6878C28.5263 28.7724 28.6066 27.7596 28.3748 26.7956L27.3438 22.5422ZM11.2011 13.5296L14.4212 9.70642L10.4054 8.73751C9.42917 8.39708 8.36269 8.42144 7.40298 8.80609C6.44327 9.19074 5.65517 9.90968 5.18425 10.8301L1.79229 16.8425C1.67344 17.0531 1.6058 17.2887 1.59485 17.5302C1.58389 17.7718 1.62991 18.0126 1.72921 18.2331C1.8285 18.4536 1.97827 18.6476 2.16642 18.7995C2.35457 18.9514 2.57582 19.0569 2.81229 19.1075L7.54945 20.123C8.33479 17.7123 9.57432 15.4742 11.2011 13.5296ZM25.7978 13.5918C26.2552 13.5918 26.7023 13.4562 27.0825 13.2021C27.4628 12.948 27.7592 12.5868 27.9343 12.1643C28.1093 11.7417 28.1551 11.2768 28.0659 10.8282C27.9766 10.3796 27.7564 9.96754 27.433 9.64413C27.1096 9.32072 26.6975 9.10048 26.2489 9.01125C25.8003 8.92202 25.3354 8.96782 24.9128 9.14285C24.4903 9.31787 24.1291 9.61427 23.875 9.99456C23.6209 10.3749 23.4853 10.8219 23.4853 11.2793C23.4855 11.8926 23.7292 12.4806 24.1628 12.9143C24.5965 13.3479 25.1845 13.5916 25.7978 13.5918Z"
        fill="#027794"
      />
      <path
        d="M34.8433 3.17904C34.7737 2.91498 34.6353 2.67412 34.4422 2.48103C34.2491 2.28795 34.0083 2.14956 33.7442 2.08C30.5817 1.25793 27.2513 1.34608 24.1367 2.33429C21.0221 3.32251 18.2501 5.17057 16.14 7.66555L11.2011 13.5296C8.73384 16.4724 7.16864 20.0649 6.69355 23.8756C6.66308 24.1098 6.68681 24.3479 6.7629 24.5715C6.83898 24.795 6.9654 24.9981 7.1324 25.1651L11.9117 29.9444C12.0548 30.0876 12.2247 30.2012 12.4118 30.2788C12.5988 30.3563 12.7992 30.3961 13.0017 30.3961C13.0461 30.3961 13.0905 30.3939 13.1357 30.3901C17.0049 30.0704 20.6716 28.526 23.6036 25.9812L29.2509 20.7886C31.7547 18.684 33.6095 15.9118 34.5995 12.7944C35.5895 9.67702 35.6741 6.34259 34.8433 3.17904ZM25.7977 13.5918C25.3404 13.5918 24.8933 13.4562 24.513 13.2021C24.1327 12.948 23.8363 12.5868 23.6613 12.1642C23.4862 11.7417 23.4404 11.2767 23.5297 10.8281C23.6189 10.3796 23.8391 9.96752 24.1625 9.64411C24.486 9.3207 24.898 9.10045 25.3466 9.01123C25.7952 8.922 26.2601 8.96779 26.6827 9.14282C27.1052 9.31785 27.4664 9.61425 27.7205 9.99454C27.9746 10.3748 28.1102 10.8219 28.1102 11.2793C28.11 11.8925 27.8663 12.4806 27.4327 12.9142C26.999 13.3479 26.411 13.5916 25.7977 13.5918Z"
        fill="#C3E8F1"
      />
    </svg>
  );
};

export default RocketIcon;
