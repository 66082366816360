import { useState, useEffect, memo } from 'react';
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { FaCheck } from 'react-icons/fa';
import Completed from './components/steps/Completed';
import Informations from './components/steps/informations/Informations';
import MembershipCategory from './components/steps/MembershipCategory';
// import Plan from './components/steps/plan/Plan';
import { API } from '../../api';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { PopupBox } from '../../components/Global/PopupBox';
import Keys from '../../constants/keys';
import { useSelector } from 'react-redux';
import attention from '../../assets/images/icons/attention.png';
import { useFetcher } from '../../api';
import AcceptFee from './components/steps/AcceptFee';

const Application = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [applicationData, setApplicationData] = useState({
    information: {},
  });
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedMembership, setSelectedMembership] = useState(
    applicationData.category,
  );
  const authenticatedUser = useSelector(
    state => state.auth.presentUser,
  );

  const handleStorePathApplication = () => {
    const link = '/application';
    if (!authenticatedUser) {
      localStorage.setItem(Keys.REDIRECT_URL_KEY, link);
    }
  };

  const { data, isError, isLoading } = useFetcher(
    '/api/applications/user/mine',
  );
  const [application, setApplication] = useState(null);

  useEffect(() => {
    if (data) {
      setApplication(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (application && currentStep !== 3 && currentStep !== 4) {
      navigate('/membershipStatus');
    }
  }, [application]);

  useEffect(() => {
    const step = searchParams.get('step');
    if (step) {
      setCurrentStep(parseInt(step));
    }
    const category = searchParams.get('category');
    if (category) {
      setSelectedMembership(category);
    }
    const plan = searchParams.get('plan');
    if (plan) {
      setApplicationData(prev => ({
        ...prev,
        category,
        information: {
          ...prev.information,
          plan,
        },
      }));
    }
  }, [searchParams]);

  const apply = async userData => {
    try {
      setLoading(true);
      await API.post(`/api/applications`, userData);
      setCurrentStep(4);
    } catch (error) {
      const err = error.response?.data || error;
      const errMessage =
        err?.message || 'Something went wrong. Please try again!';
      // toast.error(errMessage);
      // console.log(errMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const windowScrollTop = window.scrollY;
    if (windowScrollTop > 144) {
      window.scrollTo({
        top: 145,
        behavior: 'smooth',
      });
    }
  }, [currentStep]);

  const switchSteps = () => {
    switch (currentStep) {
      case 1:
        return (
          <MembershipCategory
            membership={selectedMembership}
            onNext={selected => {
              setSelectedMembership(selected);
              setCurrentStep(2);
              setApplicationData(prev => ({
                ...prev,
                category: selected,
              }));
            }}
          />
        );
      case 2:
        return (
          <AcceptFee
            onPrev={() => {
              setCurrentStep(1);
            }}
            membership={selectedMembership}
            applicationCategoryId={
              selectedMembership == 'individual' ? 1 : 2
            }
            accepted={applicationData.feeAccepted}
            onNext={() => {
              setCurrentStep(3);
            }}
          />
        );
      case 3:
        return (
          <Informations
            loading={loading}
            onPrev={() => setCurrentStep(2)}
            membership={selectedMembership}
            information={applicationData.information}
            onNext={data => {
              setApplicationData(prev => {
                const state = {
                  ...prev,
                  information: {
                    ...prev.information,
                    ...data,
                  },
                };
                apply(state);
                return state;
              });
            }}
          />
        );
      case 4:
        return <Completed membership={applicationData.category} />;
      default:
        return null;
    }
  };

  return (
    <div className="mx-8 md:mx-24 my-8">
      <div className="text-center">
        <h1 className="text-xl font-semibold">
          Apply for membership at RUPI
        </h1>
        <p className="text-grey-601 my-2">
          Welcome to RUPI membership application form. This could be
          the start of something big...
        </p>
      </div>
      <div className="border-2 border-slate-200 rounded-3xl px-8 md:px-24 py-6 mt-12">
        <div className="flex border-slate-200 pl-3">
          {[1, 2, 3, 4].map(step => {
            return (
              <div
                key={step}
                className={`flex items-center ${
                  step !== 4 ? 'w-full' : ''
                }`}
              >
                <div
                  className={`${
                    currentStep === step
                      ? currentStep === 4
                        ? 'bg-green text-white py-2 px-[8px]'
                        : 'bg-blue text-white'
                      : currentStep > step
                      ? 'bg-green text-white py-2 px-[8px]'
                      : 'bg-slate-200'
                  } rounded-full p-1 px-3 text-grey-601`}
                >
                  {currentStep > step || currentStep === 4 ? (
                    <FaCheck />
                  ) : (
                    step
                  )}
                </div>

                <div className="w-full h-1 rounded-full bg-slate-200 mx-2">
                  {currentStep === step && (
                    <div className="h-full w-1/2 bg-blue rounded-full"></div>
                  )}
                  {currentStep > step && (
                    <div className="h-full w-full bg-green rounded-full"></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        <div className="px-8">
          <div className="w-full h-[1px] bg-slate-200 my-6"></div>
        </div>

        <div>{switchSteps()}</div>
      </div>
      {/* <ToastContainer /> */}
      {!authenticatedUser && (
        <PopupBox
          logo={attention}
          status="Page Access Error!"
          buttonValue="Login"
          buttonColor="bg-green"
          onClick={event => {
            navigate('/login');
            handleStorePathApplication(event);
          }}
          message="Please Login to be able to apply for membership at RUPI!"
        />
      )}
    </div>
  );
};

export default memo(Application);
