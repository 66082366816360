import React from 'react';

const PublicationCardLoader = () => {
  return (
    <div
      className="animate-pulse z-10 rounded-[10px] relative flex flex-col shadow"
      data-aos="fade-up"
    >
      <div className="h-[367px] bg-slate-200 w-full rounded-[10px]" />
      <div className="flex flex-col items-start bottom-0 p-4 absolute bg-black/30 rounded-b-[10px] backdrop-blur w-full">
        <div className="h-2 bg-slate-200 w-full" />
        <div className="h-2 bg-slate-200 mt-3 w-full" />
        <div className="mt-6 py-3 px-4 md:px-8 text-sm rounded-full h-2 bg-slate-200 w-8" />
      </div>
    </div>
  );
};

export default PublicationCardLoader;
