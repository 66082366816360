import {
  GET_ALL_PROJECTS,
  PROJECT_ERROR,
} from '../constants/actionTypes';

const initProjectState = {
  loading: true,
  error: null,
  projects: [],
  paginationDetails: null,
};
const projectReducer = (state = initProjectState, action) => {
  switch (action.type) {
    case GET_ALL_PROJECTS:
      return {
        ...state,
        error: null,
        loading: false,
        projects: [...action.payload.projects],
        paginationDetails: action.payload.paginationDetails,
      };
    case PROJECT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default projectReducer;
