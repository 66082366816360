import React from 'react';

const MessageIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4659 19.1897C15.1772 19.1897 14.8886 19.1152 14.6279 18.9569L10.8942 16.7408C10.5032 16.7315 10.1121 16.7037 9.73969 16.6478C9.4883 16.6106 9.27415 16.4429 9.18104 16.2008C9.08793 15.9587 9.13448 15.698 9.30208 15.5025C9.9166 14.7856 10.2332 13.929 10.2332 13.0165C10.2332 10.7633 8.24995 8.92903 5.8105 8.92903C4.89803 8.92903 4.0228 9.18052 3.28725 9.66468C3.08241 9.79504 2.83102 9.81366 2.60756 9.71124C2.39341 9.60882 2.23513 9.40389 2.2072 9.1618C2.17927 8.9011 2.16064 8.64049 2.16064 8.37048C2.16064 3.75226 6.16431 0 11.0805 0C15.9966 0 20.0002 3.75226 20.0002 8.37048C20.0002 10.903 18.8271 13.2306 16.7601 14.8228L17.0766 17.3554C17.1511 17.9886 16.8718 18.5845 16.3411 18.929C16.0804 19.0966 15.7731 19.1897 15.4659 19.1897ZM11.0711 15.3349C11.2015 15.3256 11.3318 15.3629 11.4436 15.4374L15.3448 17.7558C15.4472 17.821 15.531 17.7931 15.5869 17.7558C15.6335 17.7279 15.7079 17.6534 15.6893 17.523L15.3262 14.5807C15.2983 14.32 15.41 14.0687 15.6148 13.9197C17.5142 12.5883 18.6036 10.5584 18.6036 8.35176C18.6036 4.50642 15.2331 1.37799 11.0805 1.37799C7.08608 1.37799 3.80865 4.28301 3.56657 7.93286C4.26489 7.66284 5.01907 7.51385 5.80118 7.51385C9.01344 7.51385 11.6205 9.97185 11.6205 12.9979C11.6298 13.8172 11.4343 14.6086 11.0711 15.3349Z"
        fill="#008D41"
      />
      <path
        d="M3.0912 20.009C2.84912 20.009 2.61635 19.9438 2.4022 19.8042C1.98321 19.5341 1.75976 19.0686 1.81562 18.5751L2.00183 17.1413C0.744867 16.1171 0 14.5994 0 13.0072C0 11.1916 0.949715 9.49705 2.54187 8.48217C3.50089 7.85836 4.6368 7.52319 5.81928 7.52319C9.03155 7.52319 11.6386 9.98122 11.6386 13.0072C11.6386 14.2363 11.1917 15.4467 10.3723 16.4057C9.32018 17.6813 7.74662 18.4262 6.0148 18.482L3.74296 19.8321C3.53812 19.9531 3.31466 20.009 3.0912 20.009ZM5.80997 8.91978C4.8975 8.91978 4.02227 9.17118 3.28672 9.65534C2.09493 10.4188 1.38732 11.6665 1.38732 13.0072C1.38732 14.3014 2.02046 15.4839 3.13776 16.2474C3.35191 16.3964 3.46363 16.6478 3.4357 16.9085L3.23086 18.5006L5.45615 17.1785C5.56788 17.1133 5.68893 17.0761 5.80997 17.0761C7.17866 17.0761 8.47289 16.4895 9.29225 15.4932C9.90676 14.767 10.2326 13.9104 10.2326 12.9979C10.2326 10.754 8.24943 8.91978 5.80997 8.91978Z"
        fill="#008D41"
      />
    </svg>
  );
};

export default MessageIcon;
