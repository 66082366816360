import React from 'react';

const TextInput = ({
  type,
  id,
  ref,
  placeholder,
  value,
  disabled = false,
  onChange = () => {},
}) => {
  return (
    <input
      type={type}
      id={id}
      value={value}
      ref={ref}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      disabled={disabled}
      className="border p-2 px-2.5 rounded-md"
    />
  );
};

export default TextInput;
