import React from 'react';

const MoneyIcon = () => {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.51336 18.5964C3.30041 18.5964 1.4873 16.9878 1.4873 15.0073V13.1104C1.4873 12.7292 1.80344 12.4131 2.18467 12.4131C2.56589 12.4131 2.88203 12.7292 2.88203 13.1104C2.88203 14.2727 4.00706 15.1467 5.51336 15.1467C7.01966 15.1467 8.14476 14.2727 8.14476 13.1104C8.14476 12.7292 8.46087 12.4131 8.8421 12.4131C9.22332 12.4131 9.53946 12.7292 9.53946 13.1104V15.0073C9.53946 16.9878 7.73562 18.5964 5.51336 18.5964ZM3.12373 15.9185C3.53285 16.6809 4.45338 17.2016 5.51336 17.2016C6.57335 17.2016 7.49388 16.6716 7.903 15.9185C7.24283 16.3183 6.42458 16.5508 5.51336 16.5508C4.60215 16.5508 3.7839 16.3183 3.12373 15.9185Z"
        fill="#008D41"
      />
      <path
        d="M5.51336 13.9936C3.98847 13.9936 2.62167 13.2962 1.9429 12.1897C1.64536 11.7062 1.4873 11.139 1.4873 10.5625C1.4873 9.58624 1.91501 8.67502 2.69605 7.99626C4.20235 6.67594 6.79652 6.67599 8.3121 7.98705C9.09315 8.67511 9.53016 9.58624 9.53016 10.5625C9.53016 11.139 9.37209 11.7062 9.07455 12.1897C8.40509 13.2962 7.03826 13.9936 5.51336 13.9936ZM5.51336 8.36818C4.78811 8.36818 4.11866 8.60993 3.61656 9.04695C3.14236 9.45607 2.88203 9.99536 2.88203 10.5625C2.88203 10.888 2.96567 11.1856 3.13304 11.4646C3.56075 12.1712 4.47197 12.6081 5.51336 12.6081C6.55476 12.6081 7.46596 12.1712 7.88438 11.4739C8.05175 11.2042 8.13545 10.8973 8.13545 10.5718C8.13545 10.0047 7.87512 9.46536 7.40091 9.04695C6.90811 8.60993 6.23862 8.36818 5.51336 8.36818Z"
        fill="#008D41"
      />
      <path
        d="M5.51336 16.5507C3.21672 16.5507 1.4873 15.0723 1.4873 13.1197V10.5627C1.4873 8.58221 3.29111 6.97363 5.51336 6.97363C6.56405 6.97363 7.56827 7.33626 8.3214 7.98713C9.10245 8.67519 9.53946 9.58641 9.53946 10.5627V13.1197C9.53946 15.0723 7.81 16.5507 5.51336 16.5507ZM5.51336 8.36835C4.06285 8.36835 2.88203 9.35396 2.88203 10.5627V13.1197C2.88203 14.282 4.00706 15.156 5.51336 15.156C7.01966 15.156 8.14476 14.282 8.14476 13.1197V10.5627C8.14476 9.99553 7.88443 9.45624 7.41022 9.03782C6.90812 8.61011 6.23862 8.36835 5.51336 8.36835Z"
        fill="#008D41"
      />
      <path
        d="M16.5506 11.2044C15.1466 11.2044 13.9564 10.163 13.8448 8.82407C13.7705 8.05233 14.0494 7.29918 14.6073 6.75059C15.0722 6.26707 15.7324 5.99744 16.4297 5.99744H18.373C19.2936 6.02533 20.0002 6.75059 20.0002 7.64321V9.55863C20.0002 10.4512 19.2936 11.1765 18.4009 11.2044H16.5506ZM18.3451 7.39216H16.439C16.1136 7.39216 15.816 7.51303 15.6022 7.73619C15.3325 7.99654 15.2024 8.34987 15.2396 8.7032C15.2861 9.31687 15.8811 9.80968 16.5506 9.80968H18.373C18.4939 9.80968 18.6055 9.6981 18.6055 9.55863V7.64321C18.6055 7.50374 18.4939 7.40146 18.3451 7.39216Z"
        fill="#008D41"
      />
      <path
        d="M13.724 17.2016H11.3995C11.0183 17.2016 10.7021 16.8854 10.7021 16.5042C10.7021 16.123 11.0183 15.8068 11.3995 15.8068H13.724C16.123 15.8068 17.6757 14.2541 17.6757 11.8551V11.2043H16.5507C15.1466 11.2043 13.9565 10.1629 13.8449 8.82394C13.7705 8.05219 14.0496 7.29904 14.6074 6.75045C15.0724 6.26696 15.7324 5.99731 16.4298 5.99731H17.6664V5.34643C17.6664 3.17067 16.3927 1.67365 14.3285 1.4319C14.1053 1.3947 13.91 1.39472 13.7147 1.39472H5.34643C5.12328 1.39472 4.90941 1.41331 4.69555 1.4412C2.64996 1.70155 1.39472 3.18926 1.39472 5.34643V7.20606C1.39472 7.58729 1.07858 7.90342 0.697361 7.90342C0.316137 7.90342 0 7.58729 0 7.20606V5.34643C0 2.4826 1.76668 0.409128 4.50964 0.0650963C4.76069 0.0279037 5.04889 0 5.34643 0H13.7147C13.9379 0 14.2261 0.00929809 14.5237 0.0557888C17.2666 0.371926 19.0612 2.45471 19.0612 5.34643V6.69467C19.0612 7.07589 18.745 7.39202 18.3638 7.39202H16.4298C16.1044 7.39202 15.8069 7.5129 15.593 7.73606C15.3234 7.9964 15.1931 8.34973 15.2303 8.70306C15.2768 9.31674 15.872 9.80954 16.5415 9.80954H18.3731C18.7543 9.80954 19.0705 10.1257 19.0705 10.5069V11.8551C19.0705 15.0537 16.9226 17.2016 13.724 17.2016Z"
        fill="#008D41"
      />
    </svg>
  );
};

export default MoneyIcon;
