import React from 'react';
import { FaBookOpen, FaEdit } from 'react-icons/fa';
import { NavLink, useLocation } from 'react-router-dom';

const SideNavbar = () => {
  const { pathname } = useLocation();
  return (
    <div className="flex md:flex-col flex-row flex-wrap md:flex-nowrap gap-6 md:border-r border-slate-600 w-fit md:pr-6" data-aos="fade-right">
      <NavLink
        to="/publications"
        className={`py-3 px-4 rounded-lg text-white whitespace-nowrap flex flex-nowrap items-center space-x-1 ${
          pathname === '/publications'
            ? 'bg-brand-green'
            : 'bg-slate-600'
        }`}
      >
        <FaBookOpen />
        <span className='pl-1'>Publications</span>
      </NavLink>
      <NavLink
        to="/publications/new"
        className={`py-3 px-4 rounded-lg text-white whitespace-nowrap flex flex-nowrap items-center space-x-1 ${
          pathname === '/publications/new'
            ? 'bg-brand-green'
            : 'bg-slate-600'
        }`}
      >
        <FaEdit />
        <span className='pl-1'>Publish Your Work</span>
      </NavLink>
    </div>
  );
};

export default SideNavbar;
