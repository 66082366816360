import React, { useEffect, useState } from 'react'; 
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import { getuserCertificate } from '../actions/certificate';
import moment from 'moment';
import { AiFillCheckCircle } from 'react-icons/ai'
import { RxCrossCircled } from 'react-icons/rx'
import { Preloader } from '../components/Global/Preloader';

const CertificateData = ({ certificate, getCertificate }) => {
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.presentUser);
  const { id } = useParams();
  const decodedId = decodeURIComponent(id);
  const [isPageLoading, setIsPageLoading] = useState(true)

  useEffect(() => {
    async function fetchData() {
      setIsPageLoading(true);
      try {
        await getCertificate(decodedId);
      } catch (error) {
        // console.log(error)
      } finally {
      setIsPageLoading(false);
      }
    }
    fetchData();
  }, []);


  const currentDate = moment();
  const expirationDate = moment(certificate?.expirationDate, 'D MMMM YYYY');
  const isValid = expirationDate.isSameOrAfter(currentDate, 'day') ? true : false;   

  if (isPageLoading) {
    return <Preloader />
  }

  return (
    <>
      {
        certificate ?
        <div className={`flex justify-center items-center shadow-2xl w-[95%] md:w-[50%] mx-auto border-4 ${isValid ? 'border-brand-green' : 'border-red-500'} my-10`}>  
          <div className={`space-y-3 flex flex-col m-7 text-lg md:text-base md:m-10 md:px-8 md:border-l-4 ${isValid ? 'border-brand-green' : 'border-red-500'} `}>
            <p className="text-[#787878]">
              <span className="font-bold text-black">Certificate Owner:</span>{' '}
              { certificate?.certificateOwner }
            </p>
            <p className="text-[#787878] flex gap-1">
              <span className="font-bold text-black">Status:</span>{' '} 
              { !certificate ? 'Checking...' : isValid ?  <span className="flex items-center gap-1 font-bold text-brand-green"><p>Valid</p> <AiFillCheckCircle className='text-md'/></span>  : <span className="flex items-center gap-1 font-bold text-red-500"><p>Expired</p> <RxCrossCircled className='text-md font-bold'/></span> }
            </p>
            <p className="text-[#787878]">
              <span className="font-bold text-black">Registration Number:</span>{' '}
              { certificate?.ownerRegNumber}
            </p>
            <p className="text-[#787878]">
              <span className="font-bold text-black">Category:</span>{' '}
              { certificate?.ownerCategory }
            </p>
            <p className="text-[#787878]">
              <span className="font-bold text-black">Issued Date:</span>{' '}
              { certificate?.issuedDate }
            </p>
            <p className="text-[#787878]">
              <span className="font-bold text-black">Expiration Date:</span>{' '}
              { certificate?.expirationDate }
            </p>

          </div>
        </div> 
        :
        <div className={`flex justify-center items-center shadow-2xl w-[95%] md:w-[50%] mx-auto border-4 ${isValid ? 'border-brand-green' : 'border-red-500'} my-10`}>  
          <div className="space-y-3 flex flex-col justify-center items-center m-7 text-lg md:text-base md:m-10 md:px-8">
          <RxCrossCircled className='text-5xl font-bold text-red-500'/>
          <p className='text-xl font-bold text-red-500 text-center'>Certificate not found in our system!</p>
          </div>
        </div>
      }

      </>
  );
};

const mapStateToProps = state => ({
  certificate: state.certificate.Certificate,
});
  
  const mapDispatchToProps = dispatch => {
    return {
        getCertificate: (certificateId) => dispatch(getuserCertificate(certificateId)),
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CertificateData);

