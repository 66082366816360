import { disciplines } from './data/disciplines';
import DisciplineCard from './elements/DisciplineCard';

const OurDisciplines = () => {
  return (
    <div className="overflow-x-hidden py-8 px-8 md:px-12">
      <h1 className="text-2xl font-semibold text-center" data-aos="fade-down">
        Our <span className="text-green">Disciplines</span>
      </h1>
      <div className="flex my-8 flex-col-reverse md:flex-row w-full md:items-center">
        <div className="bg-slate-100b w-full md:p-8" data-aos="zoom-in">
          {Object.keys(disciplines).map((dataKey, index) => {
            return (
              <DisciplineCard
                key={index}
                isEven={Math.floor(index % 2)}
                id={dataKey}
                discipline={disciplines[dataKey]}
              />
            );
          })}
        </div>
        <div className="bg-orange-400b md:w-2/5 py-4 md:px-4">
          <h1 className="text-xl font-semibold text-center md:text-left" data-aos="fade-left">
            Various Disciplines Available in RUPI
          </h1>
          <div className="flex flex-col md:flex-row items-center relative my-8" data-aos="fade-left">
            <div className="h-12 w-12 flex flex-col items-center justify-center space-y-4 absolute bottom-0 md:bottom-auto md:-left-0.5 transform -rotate-90 md:-rotate-0">
              <div className="w-full h-2 bg-green transform -rotate-[30deg] rounded-full"></div>
              <div className="w-full h-2 bg-green transform rotate-[30deg] rounded-full"></div>
            </div>
            <div className="h-36 w-2 md:h-2 md:w-1/2 bg-green rounded-full rounded-t-none md:rounded-full md:rounded-r-none"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurDisciplines;
