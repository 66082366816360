import axios from 'axios';
import useSWR from 'swr';

export const API = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
});

//AUTHENTICATION
export const signUp = formData => API.post('/auth', formData);
export const signIn = formData =>
  API.post('/auth/loginUser', formData);
export const loggedInUser = () => {
  return API.get(`/auth/loggedInUser`);
};
export const logoutUser = () => {
  return API.post(`/auth/logoutUser`);
};
export const googleAuth = formData =>
  API.post('/auth/googleAuth', formData);
export const forgotPassword = formData =>
  API.post('/auth/forgotPassword', formData);
export const newPassword = formData =>
  API.put('/auth/newPassword', formData);
export const updateUser = formData =>
  API.put('/auth/updateUser', formData);
export const showCertificateMessage = data =>
  API.put('/auth/certificateMessageDisplayed', data);

// PAYMENT
export const confirmPayment = formData =>
  API.post('/payment/confirmPayment', formData);
export const getUserPayments = () => API.get('/payment/userPayments');
export const deletePayment = paymentId =>
  API.delete(`/payment/deletePayment?paymentId=${paymentId}`);

//PROJECTS
export const getProjects = ({ category, page, pageSize }) => {
  const perPage = pageSize || 6;
  page = page || 1;

  category = !category || category === 'All' ? '' : category;

  return API.get(
    `/projects/getAllProjects?page=${page}&perPage=${perPage}&category=${category}`,
  );
};
//BLOGS
export const getBlogs = ({ keyword }) => {
  return API.get(`/posts/getAllPosts?keyword=${keyword}`);
};
export const getSingleProject = slug =>
  API.get(`/projects/getSingleProject?slug=${slug}`);

//SERVICES
export const getServices = () => {
  return API.get(`/services/getAllServices`);
};

//TESTIMONIALS
export const getTestimonials = () => {
  return API.get(`/testimonial/getAllTestimonials?limit=8`);
};

//MEMBERS
export const getMembers = () => {
  return API.get(`/staff/getAllMembers`);
};

//CERTIFICATE
export const saveCertificate = certificateData =>
  API.post('/certificate/saveCertificate', certificateData);
export const getCertificate = certificateId =>
  API.get(
    `/certificate/getCertificate?certificateId=${certificateId}`,
  );
export const hasACertificate = (data, id) =>
  API.put('/payment/hasACertificate?id=' + id, data);

//APPLICATION AND PUBLICATION
export const getApplications = () => {
  return API.get(`/api/applications?limit=10`);
};

/**
 *
 * @param {string} path
 */
export const fetcher = async path => {
  return API.get(path)
    .then(res => res.data)
    .catch(error => {
      throw error.response?.data || error;
    });
};

/**
 *
 * @param {string} pathname
 */
export const useFetcher = pathname => {
  const { data, error, mutate } = useSWR(pathname, fetcher);
  return {
    data,
    isLoading: !error && !data,
    isError: error,
    mutate,
  };
};

/* 
 LIKES, REPLIES AND COMMENTS
*/
//----------------------------------------------------------------
//Liking or unliking
export const likePost = blogId => {
  return API.post('/posts/likePost/' + blogId);
};
//CRUD comments
export const addComment = (body, blogId) =>
  API.post('/posts/createComment/' + blogId, body);

export const getComments = blogId =>
  API.get('/posts/comments/' + blogId);

export const deleteComment = commentId =>
  API.delete('/posts/deleteComment/' + commentId);

export const updateComment = (commentId, body) =>
  API.put('/posts/updateComment/' + commentId, body);

export const likeComment = commentId =>
  API.post('/posts/likeComment/' + commentId);

export const addReply = (commentId, body) =>
  API.post('/posts/commentReply/' + commentId, body);

export const getReplies = commentId =>
  API.get('/posts/getAllCommentReplies/' + commentId);

export const getFeaturedBlog = async () => {
  try {
    const result = await API.get('/posts/featured');
    if (result.data?.blog?.title) {
      return result.data?.blog;
    }
  } catch (error) {
    return false;
  }
};
