export const partners = [
  {
    name: 'MOE',
    logo: 'https://i.imgur.com/Bj034cI.png',
    link: 'https://www.environment.gov.rw/',
  },
  {
    name: 'NLA',
    logo: 'https://i.imgur.com/ErngtfS.png',
    link: 'https://www.lands.rw/home',
  },
  {
    name: 'RHA',
    logo: 'https://i.imgur.com/C0ZRaBI.png',  
    link: 'https://www.rha.gov.rw/',
  },
  {
    name: 'CAP',
    logo: 'https://i.imgur.com/5FFZpJ3.png',  
    link: 'https://www.commonwealth-planners.org/',
  },
  {
    name: 'RAPEP',
    logo: 'https://i.imgur.com/vkpX6No.png',  
    link: 'https://www.rapep.org.rw/',
  },
  {
    name: 'IER',
    logo: 'https://i.imgur.com/Z5YcMHN.png',  
    link: 'https://engineersrwanda.rw/ier-home',
  },
  {
    name: 'RIA',
    logo: 'https://i.imgur.com/Zgs34yu.png',  
    link: 'https://ria.rw/',
  },
  {
    name: 'IRPV',
    logo: 'https://i.imgur.com/JlqFpOV.png',  
    link: 'https://irpv.rw/',
  },
];
