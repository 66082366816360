import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaChevronLeft } from 'react-icons/fa';
import VisaIcon from '../../assets/images/icons/svg/VisaIcon';
import MastercardIcon from '../../assets/images/icons/svg/MastercardIcon';
import smartcashIcon from '../../assets/images/icons/smartcashIcon.png';
import cardPayment from '../../assets/images/icons/cardPayment.png'; 
import {
  checkOutPayment,
} from '../../actions/payment';
import { useDispatch } from 'react-redux';
import { PopupBox } from '../Global/PopupBox';
import successLogo from '../../assets/images/icons/successLogo.png';
import attention from '../../assets/images/icons/attention.png';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import PayWithMomo from './PayWithMomo';
import momo from '../../assets/images/pay/momo.png';
import airtelMoney from '../../assets/images/pay/airtelMoney.png';
import { useFetcher } from '../../api';
import Keys from '../../constants/keys';
import CircleLoader	 from 'react-spinners/CircleLoader';


export const PaymentForm = () => {
  const [isMomoVisible, setIsMomoVisible] = useState(false);
  const [isAirtelVisible, setIsAirtelVisible] = useState(false);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [hasPaymentFailed, setHasPaymentFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [price, setPrice] = useState(
    JSON.parse(localStorage.getItem('price')),
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const onBack = () => {
    navigate(-1);
  };
  const [values, setValues] = useState({
    clientToken: null,
    success: '',
    error: '',
    instance: '',
  });
  const authenticatedUser = useSelector(
    state => state.auth.presentUser,
  );
  const { data } = useFetcher('/api/applications/user/mine');
  const [application, setApplication] = useState(null);

  useEffect(() => {
    if (data && location.pathname == '/pay') {
      setApplication(data?.data);
    }
  }, [data, location.pathname]);
  

  const handleStorePathPaymentForm = () => {
    const link = '/pay';
    if (!authenticatedUser) {
      localStorage.setItem(Keys.REDIRECT_URL_KEY, link);
    }
  };

  useEffect(() => {
    setPrice(JSON.parse(localStorage.getItem('price')));
  }, [location.pathname]);

  useEffect(() => {
    const windowScrollTop = window.scrollY;
    if (windowScrollTop > 144 && location.pathname == '/pay') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [location.pathname]);


  const handlePayment = async e => {
    e.preventDefault();
    setIsCardLoading(true)
    let paymentData = {
      itemId: price?.id,
    };

    dispatch(checkOutPayment(paymentData))
      .then(response => {
        if (response.status == "200" && response.data.reply == "Sent to CC Process" && response.data.success == 1 ) {
          localStorage.setItem("paymentDetails", JSON.stringify({paymentId: response.paymentInformation._id, paymentUrl: response.data.url}))
          window.location.replace(response.data.url)
        } else {
          toast.error('Something went wrong! Reload your page and try again')
        }
      })
      .catch(err => {
        setValues({ ...values, error: err, success: '' });
      });
  };

  const handleSuccessfullPayment = () => {
    setIsPaymentSuccessful(false);
    navigate('/payments');
    localStorage.removeItem('price');
  };

  const customerName = application?.category === "individual" ? application?.information.firstName +' '+ application?.information.lastName : application?.information.name

  return (
    <div className="flex flex-col max-w-6xl w-full">
      <button
        onClick={onBack}
        className="mx-8 flex items-center space-x-1 font-bold text-[#060405]"
      >
        <FaChevronLeft /> <span>Back</span>
      </button>
      <div className="flex justify-center border shadow-2xl outline-green rounded m-auto p-7 mt-6 md:mt-12">
        <div className="flex flex-col md:px-8">
          <h1 className="">
            Balance:{' '}
            <span className="font-black text-black">
              {price?.amount} {price?.currency} /
            </span>
            <span className="pl-1 font-black text-green">
              {price?.paymentType}
            </span>
          </h1>

          <div className="mt-6 flex flex-col gap-5 md:flex-row md:gap-10">
            <PayWithMomo
              visible={isMomoVisible}
              logoIcon={momo}
              paymentMode="MOMO"
              numberPlaceholder="078... / 079..."
              amount={price?.amount}
              payerName={customerName}
              currency={price?.currency}
              itemId={price?.id}
              onToggle={param => setIsMomoVisible(param)}
            />
            <PayWithMomo
              visible={isAirtelVisible}
              amount={price?.amount}
              currency={price?.currency}
              itemId={price?.id}
              payerName={customerName}
              logoIcon={airtelMoney}
              paymentMode="Airtel Money"
              numberPlaceholder="072... / 073..."
              onToggle={param => setIsAirtelVisible(param)}
            />
          </div>

          <div className="flex flex-col md:flex-row items-center justify-center md:gap-7 mt-5 space-x-2 bg-gray-200 px-4 py-0 rounded hover:cursor-pointer" onClick={handlePayment}>
            <p className='flex items-center gap-1'>
            {
              isCardLoading ?
              <CircleLoader	
              color="#000000"
              loading={true}
              size={20}
              className='m-5'
              aria-label="Loading Spinner"
              data-testid="loader"
              />
              :
              <img src={cardPayment} alt="" className='h-16 w-16'/>
            }
            <p>Pay With Card</p>
            </p>
            <p className='flex items-center gap-4 mb-4 md:mb-0'>
              <MastercardIcon className="" />
              <VisaIcon className="" />
              <img src={smartcashIcon} alt="" className='w-12'/>
            </p>
          </div>
        </div>
      </div>
      {isPaymentSuccessful && (
        <PopupBox
          logo={successLogo}
          status="Success!"
          buttonValue="Ok"
          buttonColor="bg-green"
          onClick={handleSuccessfullPayment}
          message={`You successfully payed an amount of ${price?.amount} ${price?.currency} to RUPI, our team is checking this payment...`}
        />
      )}
      {!isLoggedIn && (
        <PopupBox
          logo={attention}
          status="Page Access Error!"
          buttonValue="Login"
          buttonColor="bg-green"
          onClick={() => navigate('/login')}
          message="Please Login to be able to make payments via this site!"
        />
      )}
      {!price && (
        <PopupBox
          logo={attention}
          status="Page Access Error!"
          buttonValue="Go Home"
          buttonColor="bg-green"
          onClick={() => navigate('/')}
          message="Please choose a plan you want to go with to be able to make payments via this site!"
        />
      )}
      {hasPaymentFailed && (
        <PopupBox
          logo={attention}
          status="Payment failed!"
          buttonValue="Try again"
          buttonColor="bg-green"
          onClick={() => window.location.reload()}
          message={errorMessage}
        />
      )}
      {!authenticatedUser && (
        <PopupBox
          logo={attention}
          status="Page Access Error!"
          buttonValue="Login"
          buttonColor="bg-green"
          onClick={event => {
            navigate('/login');
            handleStorePathPaymentForm(event);
          }}
          message="Please Login to be able to make payments!"
        />
      )}
    </div>
  );
};
