import React from 'react';

const DisciplineCard = ({ isEven, discipline, id }) => {
  const handleClick = e => {
    e.preventDefault();

    const currentSection = document.getElementById(id);

    const yOffset = -65;
    const y =
      currentSection.getBoundingClientRect().top +
      window.scrollY +
      yOffset;

    window.scrollTo({ top: y, behavior: 'smooth' });
  };
  return (
    <div
      className={`flex ${
        isEven ? 'justify-start' : 'justify-end'
      } -my-3 md:-my-8`}
    >
      <div className="md:w-[calc(50%+20px)] w-full">
        <div className="flex justify-end relative">
          <div className="w-5 h-5 rounded-[100%] rounded-b-none bg-white transform rotate-12 absolute -right-0.5 top-1 border border-b-0"></div>
        </div>
        <div className="shadow bg-white py-2 px-8 pr-12 rounded-lg rounded-tr-none mt-5 border">
          <h2 className="text-lg font-[500] text-[#2B3377]">
            {discipline.text}
          </h2>
          <div className="flex justify-end">
            <button
              className="bg-green text-xs rounded-lg px-6 my-2 py-2.5 text-white hover:shadow"
              onClick={handleClick}
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisciplineCard;
