import React, { useEffect, useState } from 'react';
import ProjectCardList from '../components/Projects/ProjectCardList';
import { useParams } from 'react-router';
import { getSingleProject } from '../api';
import { AxiosError } from 'axios';

export const ViewProjectPage = () => {
  const { slug } = useParams();
  const [project, setProject] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const fetchProject = async () => {
      try {
        setError();
        const result = await getSingleProject(slug);

        if (!result.data.fetchedProject) {
          throw new Error('Failed to fetch project');
        }
        setProject(result.data.fetchedProject);
      } catch (error) {
        if (error instanceof AxiosError && error.response.data) {
          setError(error.response.data.ProjectFetchedError);
          return;
        }
        setError(error.message);
      }
    };
    fetchProject();
  }, [slug]);

  return (
    <div className="flex flex-col items-center w-full bg-white p-8 py-6 md:py-12">
      {error && (
        <div className="text-red-500 text-xs text-center">
          {error}
        </div>
      )}
      <div className="flex flex-col max-w-6xl w-full mx-auto">
        {project && (
          <>
            <h1 className="text-lg md:text-lg font-semibold text-gray-800 mb-2">
              {project.title}
            </h1>
            <img
              src={project.projectImage}
              alt={project.title}
              height={525}
              className="min-h-[325px] max-h-[525px]"
            />

            <div className="flex flex-col-reverse md:flex-row gap-20 gap-y-5 mt-6">
              <div className="flex flex-col md:w-[55%] lg:w-[65%]">
                <h2 className="text-lg font-bold text-gray-800">
                  Project Description
                </h2>
                <p className="text-sm text-gray-800 mt-3 leading-loose">
                  {project.description}
                </p>

                <h2 className="text-lg font-bold text-gray-800 mt-6">
                  What We Did
                </h2>
                <p className="text-sm text-gray-800 mt-3 leading-loose">
                  {project.activitiesPerformed}
                </p>

                <h2 className="text-lg font-bold text-gray-800 mt-6">
                  Results
                </h2>
                <p className="text-sm text-gray-800 mt-3 leading-loose">
                  {project.result}
                </p>
              </div>
              <div className="flex flex-col md:flex-grow">
                <h2 className="text-lg font-bold text-gray-800">
                  Project Information
                </h2>
                <p className="mt-3">
                  <span className="font-semibold">Employer:</span>{' '}
                  {project.employer}
                </p>
                <p className="mt-2">
                  <span className="font-semibold">Location:</span>{' '}
                  {project.location}
                </p>
                <p className="mt-2">
                  <span className="font-semibold">Year:</span>{' '}
                  {project.year}
                </p>

                {project.otherProjectImages &&
                  project.otherProjectImages.length > 0 && (
                    <div className="hidden md:flex flex-col mt-6">
                      <h2 className="text-lg font-bold text-gray-800">
                        Other Project Images
                      </h2>

                      <div className="flex flex-col space-y-3 mt-4">
                        {project.otherProjectImages.map(
                          (image, index) => {
                            return (
                              <img
                                role="button"
                                tabIndex={0}
                                src={image}
                                alt={`${
                                  project.title
                                } other project images (${
                                  index + 1
                                })`}
                                height={247}
                                className="min-h-[247px] w-full md:w-96 object-cover"
                                key={index}
                              />
                            );
                          },
                        )}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </>
        )}
        <h1 className="text-lg md:text-3xl font-bold text-gray-800 my-6">
          Related Projects
        </h1>
        <ProjectCardList exclude={slug} />
      </div>
    </div>
  );
};
