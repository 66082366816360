import { GET_MEMBERS } from '../constants/actionTypes';

const initStaffState = {
  error: null,
  members: [],
};

const memberReducer = (state = initStaffState, action) => {
  switch (action.type) {
    case GET_MEMBERS:
      return {
        error: null,
        members: [...action.payload.members],
      };
    default:
      return state;
  }
};

export default memberReducer;
