import React, { useState, useRef, useEffect } from 'react';
import { exportComponentAsPNG } from 'react-component-export-image';
import QRCode from 'react-qr-code';
import { useMediaQuery } from '@react-hook/media-query';
import CertificatePageError from './CertificatePageError';
import { PopupBox } from '../components/Global/PopupBox';
import Keys from '../constants/keys';
import { useSelector, connect } from 'react-redux';
import attention from '../assets/images/icons/attention.png';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useFetcher } from '../api';
import { alreadyHasACertificate } from '../actions/payment';
import { saveUserCertificate } from '../actions/certificate';
import { AiOutlineWarning } from 'react-icons/ai';
import { Preloader } from '../components/Global/Preloader';

const Certificate = ({ hasCertificate, saveCertificate }) => {
  const certificateWrapperRef = useRef();
  const [certificateData, setCertificateData] = useState(null);
  const [certificateName, setCertificateName] = useState('');
  const [isCertificateLoading, setIsCertificateLoading] =
    useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [isDownloadingImage, setIsDownloadingImage] = useState(false);
  const isDesktop = useMediaQuery('(min-width: 992px)');
  const authenticatedUser = useSelector(
    state => state.auth.presentUser,
  );
  const { data } = useFetcher('/api/applications/user/mine');
  const [application, setApplication] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const userPayments = useSelector(
    state => state.payment.allUserPayments,
  );
  const applicationFeePayment = userPayments?.filter(
    payment => payment.paymentPlan === 'Application Fee',
  );
  const validMembershipPaymentPlans = [
    'Junior',
    'Professional',
    'Consulting',
    'Company',
  ];
  const membershipFeePayment =
    userPayments?.filter(payment =>
      validMembershipPaymentPlans.includes(payment.paymentPlan),
    ) || [];
  const latestPayment = membershipFeePayment.length - 1;

  useEffect(() => {
    if (application && application.status !== 'APPROVED') {
      navigate('/membershipStatus');
    }
  }, [application]);

  useEffect(() => {
    if (data && location.pathname == '/certificate') {
      setApplication(data.data);
      setCertificateData(data.data);
      setCertificateName(
        data.data.category == 'company'
          ? data.data.information.name
          : data.data.information.firstName +
              ' ' +
              data.data.information.lastName,
      );
      setIsCertificateLoading(false);
      setIsPageLoading(false);
    }
  }, [data, location.pathname]);

  const handleStorePathCertificate = () => {
    const link = '/certificate';
    if (!authenticatedUser) {
      localStorage.setItem(Keys.REDIRECT_URL_KEY, link);
    }
  };

  const formatDate = date => {
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    const formattedDate = new Date(date).toLocaleDateString(
      'en-GB',
      options,
    );

    const [day, month, year] = formattedDate.split(' ');
    const dayWithSuffix = day + getOrdinalSuffix(day);
    return `${dayWithSuffix} ${month} ${year}`;
  };

  const getOrdinalSuffix = day => {
    if (day >= 11 && day <= 13) {
      return 'th';
    }

    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const currentDate = new Date();
  const day = currentDate.getDate();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();
  const todayDate = `${month}/${day}/${year}`;
  const formattedDate = formatDate(todayDate);
  const validUntilDate = `31st December ${year}`;
  const userRegistrationNumber = certificateData?.registrationNumber;
  const membershipCategory = certificateData?.membership;
  const userAbbraviated = certificateName
    ?.split(' ')
    .map(word => word[0])
    .join('');

  const certificatePayload = {
    certificateOwner: certificateName,
    application: application?._id,
    ownerCategory: membershipCategory,
    ownerRegNumber: `${userRegistrationNumber}/${userAbbraviated}/RUPI/${year}`,
    issuedDate: formattedDate,
    expirationDate: validUntilDate,
  };

  const handleDownloadPNG = () => {
    setIsDownloadingImage(true);
    exportComponentAsPNG(certificateWrapperRef, {
      html2CanvasOptions: { useCORS: true, backgroundColor: null },
    }).then(() => {
      setIsDownloadingImage(false);
      hasCertificate({ hasCertificate: true }, application?._id);
      saveCertificate(certificatePayload);
    });
  };

  const encodedId = encodeURIComponent(
    `${userRegistrationNumber}/${userAbbraviated}/RUPI/${year}`,
  );

  if (!isDesktop) {
    return <CertificatePageError />;
  }

  return (
    <>
      <div className="flex flex-col justify-center items-center py-7 bg-green-702">
        <div
          id="downloadWrapper"
          className="rounded-lg max-w-[800px]"
          ref={certificateWrapperRef}
        >
          <div className="relative">
            {membershipCategory == 'Junior' ? (
              <img
                className="block w-full h-auto"
                src="https://i.imgur.com/A5B0qhO.png"
                alt="Junior / Graduate Certificate"
              />
            ) : membershipCategory == 'Professional' ? (
              <img
                className="block w-full h-auto"
                src="https://i.imgur.com/ll4zRVV.png"
                alt="Corporate Certificate"
              />
            ) : membershipCategory == 'Company' ? (
              <img
                className="block w-full h-auto"
                src="https://i.imgur.com/LM0nzan.png"
                alt="Company Certificate"
              />
            ) : membershipCategory == 'Consulting' ? (
              <img
                className="block w-full h-auto"
                src="https://i.imgur.com/Ptm8xCY.png"
                alt="Fellow Certificate"
              />
            ) : membershipCategory == 'none' ? (
              <PopupBox
                logo={attention}
                status="Page Access Error!"
                buttonValue="Go Home"
                buttonColor="bg-green"
                onClick={event => {
                  navigate('/');
                }}
                message="Sorry, You don't have access to this page! We are still reviewing your application."
              />
            ) : (
              <Preloader />
            )}

            <p
              className={`absolute text-[#13562f] font-bold inset-0  -translate-y-[16.4%] flex justify-center items-center text-center ${
                certificateName.length > 28
                  ? 'text-[30px] leading-10 px-5'
                  : 'text-[44px]'
              }`}
            >
              {isCertificateLoading ? 'Loading' : certificateName}
            </p>
            <p
              className={`absolute font-lora inset-0 leading-[30px] ${
                membershipCategory == 'Junior'
                  ? '-translate-y-[4.5%]'
                  : '-translate-y-[3.2%]'
              } flex justify-center items-center text-justify px-[9%]`}
            >
              Pursuant to and in accordance with the terms of the
              Provisional Acceptance Letter 0659/1601 dated 04/08/2022
              from Hon. Dr. Mujawamariya Jeanne d'Arc - Minister of
              Environment, governing the profession of Urban Planning
              in Rwanda. In witness thereof, the common seal was
              affixed at a meeting of the Governing Council where this
              member was accepted into the {membershipCategory}{' '}
              category.
            </p>
            {membershipCategory == 'Junior' && (
              <div className="absolute font-lora inset-0 leading-[30px] translate-y-[4%] text-red-500 font-semibold flex justify-center items-center text-justify px-[9%]">
                ( THIS IS NOT A PRACTICING CERTIFICATE )
              </div>
            )}
            <p className="absolute font-lora inset-0 text-[#13562f] translate-x-[4.5%]  translate-y-[7.4%] flex justify-center font-bold items-center text-center text-[21px]">
              {userRegistrationNumber}/{userAbbraviated}/RUPI/{year}
            </p>
            <p className="absolute font-lora inset-0  translate-y-[12%] flex justify-center font-bold items-center text-center text-[16.5px]">
              {formattedDate}
            </p>
            <p className="absolute font-lora inset-0 translate-x-[4.3%]  translate-y-[14.6%] flex justify-center font-bold items-center text-center text-[16.5px]">
              RUPI Governing Council
            </p>
            <p className="absolute font-lora inset-0 translate-x-[2.3%]  translate-y-[17.1%] flex justify-center font-bold items-center text-center text-[16.5px]">
              {validUntilDate}
            </p>
            <div className="absolute bottom-[3.5%] left-[42.8%]">
              <QRCode
                value={`${process.env.REACT_APP_FRONTEND_URL}/certificate/${encodedId}`}
                className="w-[110px] h-[110px]"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center gap-10 h-[300px]">
          <div className="flex gap-2 justify-center items-center">
            <AiOutlineWarning className="text-5xl text-red-500" />
            <p className="text-white font-semibold">
              Note that this certificate is only downloaded once!
            </p>
          </div>
          <button
            className="mt-4 px-7 py-2 border-2 border-blue-300 bg-blue-200 text-blue-900 rounded-lg font-semibold cursor-pointer hover:bg-white"
            onClick={handleDownloadPNG}
          >
            {isDownloadingImage ? 'Downloading...' : 'Download'}
          </button>
        </div>
        {!authenticatedUser && !isPageLoading && (
          <PopupBox
            logo={attention}
            status="Page Access Error!"
            buttonValue="Login"
            buttonColor="bg-green"
            onClick={event => {
              navigate('/login');
              handleStorePathCertificate(event);
            }}
            message="Please Login to be able to get your certificate!"
          />
        )}
        {!application && !isPageLoading && (
          <PopupBox
            logo={attention}
            status="Page Access Error!"
            buttonValue="Join RUPI"
            buttonColor="bg-green"
            onClick={event => {
              navigate('/application');
            }}
            message="Please join our community to be able to get our certificate!"
          />
        )}
        {applicationFeePayment &&
          applicationFeePayment?.length !== 0 &&
          membershipFeePayment &&
          membershipFeePayment?.length === 0 && (
            <PopupBox
              logo={attention}
              status="Page Access Error!"
              buttonValue="Go Home"
              buttonColor="bg-green"
              onClick={event => {
                navigate('/');
              }}
              message="You have to pay the required licencing fee to be able to get a certificate"
            />
          )}
        {applicationFeePayment &&
          applicationFeePayment?.length !== 0 &&
          membershipFeePayment &&
          membershipFeePayment?.length !== 0 &&
          membershipFeePayment[latestPayment].transactionStatus !==
            'Success' && (
            <PopupBox
              logo={attention}
              status="Page Access Error!"
              buttonValue="Go Home"
              buttonColor="bg-green"
              onClick={event => {
                navigate('/');
              }}
              message={`Hi ${authenticatedUser?.firstName}, the latest licencing fee payment you made was not successfull! Please pay the required licencing fee to be able to get a certificate`}
            />
          )}
        {application && application.hasCertificate && (
          <PopupBox
            logo={attention}
            status="Page Access Error!"
            buttonValue="Okay"
            buttonColor="bg-green"
            onClick={() => navigate('/')}
            message={`Hi ${authenticatedUser?.firstName}, you can't access this page again because you already downloaded a certificate. Contact us on +250783453211 if maybe you lost it or want to get another certificate!`}
          />
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    hasCertificate: (body, id) =>
      dispatch(alreadyHasACertificate(body, id)),
    saveCertificate: body => dispatch(saveUserCertificate(body)),
  };
};

export default connect(null, mapDispatchToProps)(Certificate);
