import React from 'react';
import { availablePublications } from './data/publications';

const PublicationCard = ({
  publication = availablePublications[0],
}) => {
  return (
    <div
      className="z-10 rounded-[10px] relative flex h-full flex-col bg-brand-green text-white"
      data-aos="fade-up"
    >
      <div className="flex flex-col">
        <img
          src={publication.cover}
          alt={publication.title}
          height="367px"
          className="h-[367px] w-full object-cover rounded-[10px]"
        />
      </div>
      <div className="flex flex-col items-start bottom-0 p-4 text-white absolute bg-black/30 rounded-b-[10px] backdrop-blur w-full">
        <p className="text-sm font-light">{publication.name}</p>
        <h1 className="text-xl md:text-2xl font-bold mt-2 truncate">
          {publication.title}
        </h1>
        <a
          href={publication.link}
          target="_blank"
          rel="noopener noreferrer"
          className="mt-6 py-3 px-4 md:px-8 text-sm md:text-base font-semibold rounded-full text-white bg-brand-green"
        >
          View Publication
        </a>
      </div>
    </div>
  );
};

export default PublicationCard;
