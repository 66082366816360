import React, { useState, useEffect } from 'react'
import { getPayments } from '../actions/payment';
import { connect, useSelector } from 'react-redux';
import { MembershipFees } from '../components/Home/MembershipFees/MembershipFees';
import { useNavigate, useLocation } from 'react-router-dom';
import { PopupBox } from '../components/Global/PopupBox'
import Keys from '../constants/keys';
import attention from '../assets/images/icons/attention.png'
import PulseLoader from 'react-spinners/PulseLoader';

const Payments = ({ payments, getPayments }) => {
  const navigate = useNavigate()
  const location = useLocation() 
  const [pendingPayment, setPendingPayment] = useState(JSON.parse(localStorage.getItem("paymentDetails")))
  const authenticatedUser = useSelector(
    state => state.auth.presentUser,
  );
  const [isPageLoading, setIsPageLoading] = useState(true)
  const handleStorePathPaymentsHistory = () => {
    const link = '/payments';  
    if (!authenticatedUser) {
      localStorage.setItem(Keys.REDIRECT_URL_KEY, link);
    }
  }

  useEffect(() => { 
      getPayments().then(() => setIsPageLoading(false))
}, []);

useEffect(() => {
    setPendingPayment(JSON.parse(localStorage.getItem("paymentDetails")))
    if(pendingPayment) {
      localStorage.removeItem("paymentDetails")
    }
}, []);

const formatMoney = (amountPaid) => {
  const formatter = new Intl.NumberFormat('en-RW', {
    style: 'currency',
    currency: 'RWF',
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  }); 
  const formattedValue = formatter.format(amountPaid).replace("RF", "");
  const amount = formattedValue.replace("RWF", "").trim();
  const formattedAmount = `${amount} Rwf`;

  return formattedAmount;
}

  return (
    <>
      <div className="mt-auto flex flex-col items-center w-full px-8 py-6 md:py-12">
        <div className="flex flex-col max-w-6xl w-full" data-aos="fade-up">
          <h1 className="font-black text-brand-green">Payments</h1>
          <h2 className="text-3xl md:text-4xl font-extrabold tracking-wide mt-2">
            Your Payments History
          </h2>
          { payments && payments.length !== 0  
              ?
            (
              <div className="overflow-x-auto">
                <table className="w-full mt-10 border-collapse divide-white divide-y-[20px]">
                  <thead>
                      <tr className='bg-green'>
                      <th className="p-4 text-center">Transaction ID</th>
                      <th className="p-4 text-center">Payment Plan</th>
                      <th className="p-4 text-center">Payment Method</th>
                      <th className="p-4 text-center">Amount</th>
                      <th className="p-4 text-center flex justify-center items-center">Status</th>
                      <th className="p-4 text-center">Time</th>
                      </tr>
                  </thead>
                  <tbody className=" divide-white divide-y-[20px]">
                      { payments?.map(({transactionId, paymentPlan, paymentMethod, amountPaid, transactionStatus, createdAt}, index) => (
                      <tr className="bg-green/30" key={index}>
                          <td className="text-center p-4">{transactionId }</td>
                          <td className="text-center p-4">{paymentPlan}</td>
                          <td className="text-center p-4">{paymentMethod }</td>
                          <td className="text-center p-4">{formatMoney(amountPaid)}</td>
                          <td className="text-center p-4 flex justify-center items-center"> <p className={`rounded px-3 py-1 text-sm font-semibold ${transactionStatus == 'Pending' ? 'text-yellow-500 bg-yellow-100/50' : transactionStatus == 'Success' ? 'text-green bg-green/20' : 'text-red-500 bg-red-100/70'}`}>{transactionStatus}</p> </td>
                          <td className="text-center p-4">{createdAt}</td>
                      </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )
            :
            !isPageLoading && payments?.length == 0 ?
            (
              <>
                <h1 className='flex justify-center items-center text-xl mt-12 border border-black font-bold p-4 m-auto'>
                  No payments made!
                </h1>
                <MembershipFees />
              </>
            )
            :
          ( 
  
          <h1 className='flex justify-center items-center text-xl mt-12 font-bold p-4 m-auto'>
            <PulseLoader
                color="#000000"
                loading={true}
                size={10}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
          </h1>
            )
          }
        </div>
        {
        !authenticatedUser && !isPageLoading && (
            < PopupBox 
              logo={attention} 
              status="Page Access Error!" 
              buttonValue="Login" 
              buttonColor="bg-green" 
              onClick={(event) => {
                navigate('/login')
                handleStorePathPaymentsHistory(event)
              }} 
              message="Please Login to be able to view your payments history!"/>
            )
        }
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  payments: state.payment.allUserPayments
});

const mapDispatchToProps = dispatch => {
  return {
    getPayments: () => dispatch(getPayments())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Payments);