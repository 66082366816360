import {
  GET_ALL_PROJECTS,
  PROJECT_ERROR,
} from '../constants/actionTypes';
import * as api from '../api/index.js';

export const getAllProjects = queries => {
  return async dispatch => {
    try {
      const result = await api.getProjects(queries);
      if (!result.data.allAvailableProjects) {
        throw new Error('Failed to retrieve projects');
      }
      if (
        result.data.allAvailableProjects.length >= 0 &&
        (!queries.category || queries.category != 'All')
      ) {
        dispatch({
          type: GET_ALL_PROJECTS,
          payload: {
            projects: result.data.allAvailableProjects,
            paginationDetails: result.data.paginationDetails,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: PROJECT_ERROR,
        payload: { error: error.message },
      });
    }
  };
};
