import React from 'react';
import SelectPaymentForm from '../components/Payment/SelectPaymentForm';

const PaymentMethods = () => {
  return (
    <div className="flex flex-col items-center w-full bg-white p-8 py-6 md:py-12">
      <SelectPaymentForm />
    </div>
  );
};

export default PaymentMethods;
