import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaCamera,
  FaPlusCircle,
} from 'react-icons/fa';
import dots from '../../assets/images/icons/members/dots.svg';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { getAllTestimonials } from '../../actions/testimonial';
import PopupModal from '../Global/PopupModal';
import { API } from '../../api';
import { toast } from 'react-toastify';
import { PulseLoader } from 'react-spinners';
import { useFetcher } from '../../api';

const initFormState = {
  loading: false,
  error: null,
  message: null,
};

const Testimonials = ({
  getTestimonials,
  testimonials,
  name,
  picture,
}) => {
  const [currentIndex, setCurrentIndex] = useState();
  const testmony = testimonials[currentIndex];
  const { data } = useFetcher('/api/applications/user/mine');
  const [application, setApplication] = useState(null);

  useEffect(() => {
    if (data) {
      setApplication(data.data);
    }
  }, [data]);

  useEffect(() => {
    if (testimonials.length < 1) {
      getTestimonials();
    } else {
      setCurrentIndex(0);
    }
  }, [testimonials]);

  const [formValue, setFormValue] = useState({
    name: '',
    message: '',
    picture: '',
    upload: 'default',
  });

  useMemo(() => {
    setFormValue(prev => ({ ...prev, name, picture }));
  }, [name, picture]);

  const [formState, setFormState] = useState(initFormState);

  const handleChange = e => {
    setFormState(initFormState);
    if (e.target.name === 'picture') {
      const files = e.target.files;
      if (files.length === 0) return;
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormValue(prev => ({
          ...prev,
          [e.target.name]: reader.result,
          upload: 'new',
        }));
      };
      reader.readAsDataURL(files[0]);
    } else {
      setFormValue(prev => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFormState(initFormState);
    if (!formValue.picture) {
      setFormState(prev => ({
        ...prev,
        error: 'Profile picture required!',
      }));
      return;
    }
    try {
      setFormState({ loading: true, error: null, message: null });
      await API.post('/testimonial/addTestimonial', {
        name: formValue.name,
        image: formValue.picture,
        testimonial: formValue.message,
        upload: formValue.upload,
      });
      toast.success(
        'Thanks for giving us your testimonial! It should be visible publicy once viewed by our team.',
      );
      handleCloseModal();
      setFormValue(prev => ({ ...prev, message: '' }));
    } catch (error) {
      setFormState(prev => ({
        ...prev,
        error:
          error.response?.data?.validationError ||
          error.response?.data?.message ||
          error.message ||
          'Something went wrong!',
      }));
    } finally {
      setFormState(prev => ({ ...prev, loading: false }));
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => {
    setOpenModal(false);
    setFormState(initFormState);
  };

  return (
    <>
      <div
        className="px-6 overflow-hidden md:px-12"
        id="testimonials"
      >
        <div className="text-center mb-12" data-aos="fade-down">
          <h1 className="text-3xl font-semibold mt-12">
            What our members say
          </h1>
          <p className="my-3 text-sm text-grey-601">
            Several RUPI members, who already believe in our service.
          </p>
        </div>
        {currentIndex >= 0 && (
          <div className=" flex flex-col md:flex-row md:pr-12 md:space-x-12 items-center md:-space-y-7">
            <div className=" w-full flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
              <div className="md:w-[70%] relative">
                <div>
                  <div className=" flex justify-end">
                    <img src={dots} alt="dots" className="w-1/2" />
                  </div>

                  <div className="border-[7px] border-green w-20 h-20 rounded-full mt-32 ml-12"></div>
                </div>
                <div className="w-[75%] md:w-[70%] mx-auto absolute top-0 bottom-0 my-4 right-0 left-0">
                  <img
                    src={testmony.image}
                    alt={testmony.name}
                    data-aos="fade-right"
                    className="w-full h-full object-cover rounded-full rounded-tr-none shadow-xl"
                  />
                </div>
              </div>
              <div className="w-full flex flex-col justify-center space-y-4 items-center text-center md:items-start md:text-left">
                <h1
                  className="text-xl font-semibold"
                  data-aos="fade-up"
                >
                  {testmony.name}
                </h1>
                <p className="my-2" data-aos="fade-up">
                  {testmony.testimonial}
                </p>
                <div className="flex space-x-1" data-aos="fade-left">
                  {testimonials.map((testimonial, index) => {
                    return (
                      <div
                        className={`h-3 w-3 rounded-full hover:shadow-md ${
                          currentIndex === index
                            ? 'bg-green'
                            : 'bg-slate-300'
                        }`}
                        onClick={() => setCurrentIndex(index)}
                        key={index}
                      ></div>
                    );
                  })}
                </div>
                <div className="md:hidden flex justify-between text-3xl space-x-10 text-green pt-6">
                  <FaArrowAltCircleLeft
                    onClick={() => {
                      if (currentIndex === 0) {
                        setCurrentIndex(testimonials.length - 1);
                        return;
                      }
                      setCurrentIndex(currentIndex - 1);
                    }}
                  />
                  <FaArrowAltCircleRight
                    onClick={() => {
                      if (currentIndex === testimonials.length - 1) {
                        setCurrentIndex(0);
                        return;
                      }
                      setCurrentIndex(currentIndex + 1);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="hidden md:block relative w-[25%]">
              <div className="w-32 h-32 bg-orange-100 rounded-full mt-16"></div>
              <div
                className="absolute top-0 right-0"
                data-aos="zoom-in"
              >
                <div className="big-circle">
                  {testimonials.map((member, index) => {
                    return (
                      <div
                        className="small-circle hover:shadow-lg"
                        key={index}
                        onClick={() => setCurrentIndex(index)}
                      >
                        <img
                          src={member.image}
                          alt={member.name}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    );
                  })}
                  <div className="current-user">
                    <img
                      src={testmony.image}
                      alt={testmony.name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      {
        application && application.status === "APPROVED" &&
        (  
        <div className="flex justify-center mt-12">
          <button
            className="px-4 py-1.5 bg-green text-white rounded-full text-sm flex items-center space-x-2 hover:shadow-lg"
            onClick={() => setOpenModal(true)}
          >
            <FaPlusCircle />
            <span> Add Your Testimonial</span>
          </button>
        </div>
        )
      }
      </div>
      <PopupModal
        onClose={handleCloseModal}
        show={openModal}
        title={'Add Your Testimonial'}
      >
        {formState.error && (
          <div className="text-white text-xs bg-red-500 rounded-md text-center p-2 mb-2">
            {formState.error}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="flex items-center justify-center space-x-2 text-white">
            <div className="w-20 h-20 rounded-full text-slate-600 bg-slate-200 flex items-center justify-center">
              {formValue.picture ? (
                <img
                  src={formValue.picture}
                  alt="testimony profile"
                  className="w-20 h-20 object-cover rounded-full border-2 border-slate-300"
                />
              ) : (
                <FaCamera />
              )}
            </div>
            <input
              type="file"
              name="picture"
              id="picture"
              multiple={false}
              accept="image/png,image/jpeg,image/jpg"
              hidden
              onChange={handleChange}
            />
            <span
              className="text-xs bg-green  px-2.5 py-1 rounded-md flex items-center space-x-1 hover:bg-slate-600 hover:shadow-lg  cursor-pointer"
              onClick={e =>
                document.getElementsByName('picture')[0].click()
              }
            >
              <FaCamera />
              <span>{formValue.picture ? 'Change' : 'Upload'}</span>
            </span>
          </div>
          <div className="flex flex-col text-md my-1">
            <label htmlFor="name" className="text-sm py-1">
              Your Name
            </label>
            <input
              type="text"
              className="border rounded px-2 py-1 placeholder:text-xs"
              placeholder="Name"
              value={formValue.name}
              onChange={handleChange}
              id="name"
              name="name"
              required
              disabled={name ? true : false}
            />
          </div>
          <div className="flex flex-col text-md my-2">
            <label htmlFor="message" className="text-sm py-1">
              Your Message
            </label>
            <textarea
              className="border rounded px-2 py-1 placeholder:text-xs resize-none"
              placeholder="Message"
              id="message"
              name="message"
              value={formValue.message}
              onChange={handleChange}
              required
              rows={4}
            />
          </div>
          <div className="text-center mt-4">
            <button
              type="submit"
              className="px-4 py-1.5 bg-green text-white rounded-full text-sm hover:shadow-lg"
            >
              {formState.loading ? (
                <PulseLoader
                  color="#ffffff"
                  loading={true}
                  size={10}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              ) : (
                'Submit'
              )}
            </button>
            <div className="flex items-center py-2">
              <div className="h-[1px] w-full bg-slate-200 rounded-full mx-1"></div>
              <span className="text-xs text-slate-400">OR</span>
              <div className="h-[1px] w-full bg-slate-200 rounded-full mx-1"></div>
            </div>
            <span
              className="underline text-slate-400 text-xs cursor-pointer hover:text-grey-601"
              onClick={handleCloseModal}
            >
              Not now
            </span>
          </div>
        </form>
      </PopupModal>
    </>
  );
};

const mapStateToProps = state => ({
  testimonials: state.testimonial.testimonials,
  name: state.auth.presentUser?.name || '',
  picture: state.auth.presentUser?.picture?.url || '',
});

const mapDispatchToProps = dispatch => {
  return {
    getTestimonials: () => dispatch(getAllTestimonials()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Testimonials);
