import { combineReducers } from 'redux';
import auth from './auth';
import project from './project';
import service from './service';
import member from './member';
import testimonial from './testimonial';
import payment from './payment'
import certificate from './certificate'

export default combineReducers({
  auth,
  project,
  service,
  member,
  testimonial,
  payment,
  certificate,
});
