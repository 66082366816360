import PageHeader from '../components/Global/PageHeader';
import { MembershipFees } from '../components/Home/MembershipFees/MembershipFees';
import Benefits from '../components/Membership/benefits/Benefits';
import Categories from '../components/Membership/Categories';
import HowToApply from '../components/Membership/HowToApply';
import JoinUs from '../components/Membership/JoinUs';
import Testimonials from '../components/Membership/Testimonials';

export const Membership = () => {
  return (
    <>
      <PageHeader
        title="Get RUPI Membership"
        description="Learn more about RUPI  Membership and join our amazing community "
      />
      <JoinUs />
      <Benefits />
      <Testimonials />
      <Categories />
      <HowToApply />
      <MembershipFees />
    </>
  );
};
