import React, { useState, useEffect } from 'react'
import vision from '../../assets/images/about/vision.png';
import { useNavigate } from 'react-router-dom';
import Keys from '../../constants/keys';
import { useSelector } from 'react-redux';
import { useFetcher } from '../../api';

const OurVision = () => {
  const navigate = useNavigate();
  const authenticatedUser = useSelector(
    state => state.auth.presentUser,
  ); 

  const userPayments = useSelector(
    state => state.payment.allUserPayments,
  );
  const applicationFeePayment = userPayments?.filter(payment => payment.paymentPlan === "Application Fee");
  const latestFeePayment = applicationFeePayment?.length - 1;

  const handleStorePathVision = () => {
    const link = '/application';  
    if (!authenticatedUser) {
      localStorage.setItem(Keys.REDIRECT_URL_KEY, link);
    }
  } 

  const { data } = useFetcher('/api/applications/user/mine');
  const [application, setApplication] = useState(null);

  useEffect(() => {
    if (data) {
      setApplication(data.data);
    }
  }, [data]);

  return (
    <div
      className="mt-auto flex flex-col items-center w-full bg-gray-401 px-8 py-6 md:py-12"
      id="Our-vision"
    >
      <div
        className="flex items-center md:justify-end p-4 bg-cover md:bg-auto bg-center justify-center max-w-6xl w-full min-h-[400px] md:min-h-[569px] md:bg-left-top bg-no-repeat"
        data-aos="fade-right"
        style={{ backgroundImage: `url(${vision})` }}
      >
        <div
          className="flex flex-col items-start rounded-sm bg-brand-green text-white p-8 w-full max-w-md"
          data-aos="fade-left"
        >
          <h1 className="text-xl font-bold md:text-3xl text-white">
            Our Vision
          </h1>
          <p className="text-md mt-4">
            To be Africa's most serving Professional Body for
            community development, through sustainable plans and
            projects performed by our members in collaboration with
            various institutions.
          </p>

          <button
            type="button"
            className="mt-6 bg-white text-brand-green font-semibold px-4 py-3 rounded-md"
            onClick={event => {
              if (!authenticatedUser) {
                handleStorePathVision(event);
                navigate('/login');
              } else {
                navigate(
                  application && applicationFeePayment?.length == 0 ? "/application?step=4" :
                  application && applicationFeePayment && applicationFeePayment?.length !== 0 && applicationFeePayment[latestFeePayment]?.transactionStatus !== 'Success' ? "/application?step=4" : 
                  application && applicationFeePayment && applicationFeePayment?.length !== 0 && applicationFeePayment[latestFeePayment]?.transactionStatus === 'Success' ? "/membershipStatus" :
                  "/application"
                );
              }
            }}
          >
            Join Our Institution
          </button>
        </div>
      </div>
    </div>
  );
};

export default OurVision;
