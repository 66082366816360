import React from "react";


export const PopupBox = ({ logo, status, message, buttonValue, buttonColor , onClick }) => {
  return (
    <div className="fixed z-50 inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center">
      <div className="bg-white px-4 py-5 rounded w-[370px]">
        <img src={logo} alt="Logo" className="w-20 m-auto"/>
        <p className="text-center text-2xl font-bold my-5">{ status }</p>

        <div className="text-center">
          { message }
        </div>
        <div className="mt-7 font-bold text-lg text-center">
          <button className={`px-10 py-1 ${buttonColor} text-white rounded`} onClick={onClick}>
            { buttonValue }
          </button>
        </div>
      </div>
    </div>
  );
}