import * as actionType from '../constants/actionTypes';

const paymentReducer = (state = { paymentDetails : [], token: null }, action) => {
  switch (action.type) {
    case actionType.INITIALIZE_PAYMENT:

      return { ...state, token: action.data, paymentDetails: [] };

    case actionType.CONFIRM_PAYMENT:
      return { ...state, paymentDetails: [action.data], token: null };

    case actionType.GET__PAYMENTS:
      return {
        token: null,
        allUserPayments: [...action.payload.allUserPayments],
      };

    case actionType.DECIDE_ON_CERTIFICATE:
      return {
         ...state, token: null, decideCertificate: action.payload.decideCertificate
      };

    default:
      return state;
  }
};

export default paymentReducer;