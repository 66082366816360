import React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

const ProjectPagination = ({ paginationDetails, onNext, onPrev }) => {
  // const [currentPage, setPage] = React.useState(1);
  // const moveToPage = page => {
  //   setPage(page);
  // };

  return (
    <div className="flex items-center space-x-3 md:space-x-4 justify-between">
      <button
        onClick={
          paginationDetails.currentPage === 1
            ? null
            : () => onPrev(paginationDetails.currentPage - 1)
        }
        className={`p-3 ${
          paginationDetails.currentPage === 1
            ? 'bg-gray-300 cursor-default'
            : 'bg-black-800'
        }  text-white flex items-center space-x-2 sm:space-x-3 justify-center sm:min-w-[150px]`}
      >
        <FaArrowLeft />
        <span>Back</span>
      </button>

      <div className="flex-grow flex items-center space-x-2 sm:space-x-4">
        {new Array(paginationDetails.totalPages)
          .fill(0)
          .map((_, i) => (
            <div
              key={i}
              role="button"
              tabIndex={0}
              onClick={
                i + 1 === paginationDetails.currentPage
                  ? null
                  : () => onNext(i + 1)
              }
              className={`w-[16px] h-[16px] rounded-full ${
                i + 1 === paginationDetails.currentPage
                  ? 'bg-brand-green cursor-default'
                  : 'bg-gray-300'
              }`}
            />
          ))}
      </div>

      <button
        onClick={
          paginationDetails.currentPage ===
          paginationDetails.totalPages
            ? null
            : () => onNext(paginationDetails.currentPage + 1)
        }
        className={`p-3    ${
          paginationDetails.currentPage ===
          paginationDetails.totalPages
            ? 'bg-gray-300 cursor-default'
            : 'bg-black-800'
        } text-white flex items-center space-x-2 sm:space-x-3 justify-center sm:min-w-[150px]`}
      >
        <span>Next</span>
        <FaArrowRight />
      </button>
    </div>
  );
};

export default ProjectPagination;
