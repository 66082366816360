import React from 'react';

const CircleIcon = ({ ...props }) => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.1251 3.08325C17.9186 3.08544 13.4565 6.80579 12.5181 11.927C16.9996 9.47465 22.6206 11.1196 25.073 15.6011C26.587 18.3678 26.587 21.7153 25.073 24.482C30.9333 23.4084 34.8138 17.7874 33.7403 11.9271C32.8016 6.80269 28.3348 3.08128 23.1251 3.08325Z"
        fill="#C3E8F1"
      />
      <path
        d="M10.7916 33.9167C15.0488 33.9167 18.4999 30.4655 18.4999 26.2083C18.4999 21.9511 15.0488 18.5 10.7916 18.5C6.53439 18.5 3.08325 21.9511 3.08325 26.2083C3.08325 30.4655 6.53439 33.9167 10.7916 33.9167Z"
        fill="#027794"
      />
      <path
        d="M16.9584 10.7917C12.2048 10.7922 8.22545 14.3955 7.75464 19.1257C11.664 17.4461 16.1948 19.2536 17.8744 23.163C18.7087 25.1047 18.7087 27.3038 17.8744 29.2455C22.9577 28.74 26.6689 24.2094 26.1634 19.1261C25.693 14.3952 21.7127 10.7913 16.9584 10.7917Z"
        fill="#52A0B4"
      />
    </svg>
  );
};

export default CircleIcon;
