import ImageIcon from '../../../assets/images/icons/svg/ImageIcon';
import MessageIcon from '../../../assets/images/icons/svg/MessageIcon';
import MoneyIcon from '../../../assets/images/icons/svg/MoneyIcon';
import PlayIcon from '../../../assets/images/icons/svg/PlayIcon';
import SearchIcon from '../../../assets/images/icons/svg/SearchIcon';
import UserCircleIcon from '../../../assets/images/icons/svg/UserCircleIcon';

export const companyValues = [
  {
    icon: <MessageIcon />,
    title: 'Serve',
    description:
      'Our philosophy is to participate actively in community development plans and ensure tangible results.',
  },
  {
    icon: <MoneyIcon />,
    title: 'Collaborative',
    description:
      'We intend to work with various partners and / or stakeholders engaged in urban and regional planning.',
  },
  {
    icon: <UserCircleIcon />,
    title: 'Integrity',
    description:
      'Our existence is meant by integrity of members while practicing their profession.',
  },
  {
    icon: <SearchIcon />,
    title: 'Innovative',
    description:
      'Provide innovative solutions to community to ensure success of urban and rural plans.',
  }
];
