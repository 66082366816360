import { GET_TESTIMONIALS } from '../constants/actionTypes';

const initTestimonialState = {
  error: null,
  testimonials: [],
};

const testimonialReducer = (state = initTestimonialState, action) => {
  switch (action.type) {
    case GET_TESTIMONIALS:
      return {
        error: null,
        testimonials: [...action.payload.testimonials],
      };
    default:
      return state;
  }
};

export default testimonialReducer;
