import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import image from '../../assets/images/header/headerImage.jpg';
import Keys from '../../constants/keys';
import { useSelector } from 'react-redux';
import { useFetcher } from '../../api';

const JoinUs = () => {
  const authenticatedUser = useSelector(
    state => state.auth.presentUser,
  );

  const userPayments = useSelector(
    state => state.payment.allUserPayments,
  );
  const applicationFeePayment = userPayments?.filter(payment => payment.paymentPlan === "Application Fee");
  const latestFeePayment = applicationFeePayment?.length - 1;

  const handleStorePathJoinUs = () => {
    const link = '/application';
    if (!authenticatedUser) {
      localStorage.setItem(Keys.REDIRECT_URL_KEY, link);
    }
  };

  const { data } = useFetcher('/api/applications/user/mine');
  const [application, setApplication] = useState(null);

  useEffect(() => {
    if (data) {
      setApplication(data.data);
    }
  }, [data]);

  return (
    <div className="flex items-center my-8" data-aos="fade-up">
      <div className="hidden md:block w-full">
        <img
          src={image}
          alt="Members of RUPI"
          className="rounded-r-full"
        />
      </div>
      <div className="mx-8 md:mx-12 flex flex-col items-center text-center md:text-left md:items-start justify-center space-y-4 w-full">
        <h1 className="text-3xl font-bold">
          Be part of our creative community
        </h1>
        <p className="pb-4 md:pb-2">
        Membership in RUPI provides access to a network of like-minded professionals, resources, and
        opportunities to get involved in working groups. RUPI membership is a great way to get involved in
        community urban planning projects and meet others who share your interests and have a positive
        impact on building community.
        </p>

        <Link
          to={
            !authenticatedUser ? "/login" : 
            application && applicationFeePayment?.length == 0 ? "/application?step=4" :
            application && applicationFeePayment && applicationFeePayment?.length !== 0 && applicationFeePayment[latestFeePayment]?.transactionStatus !== 'Success' ? "/application?step=4" : 
            application && applicationFeePayment && applicationFeePayment?.length !== 0 && applicationFeePayment[latestFeePayment]?.transactionStatus === 'Success' ? "/membershipStatus" :
            "/application"
          } 
          onClick={!authenticatedUser ? handleStorePathJoinUs : ''}
          className="bg-green text-white text-sm px-12 py-2 font-semibold hover:shadow-lg"
        >
          Join Us
        </Link>
      </div>
    </div>
  );
};

export default JoinUs;
