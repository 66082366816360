import React from 'react';
import AboutHeader from '../components/About/AboutHeader';
import ProjectContainer from '../components/Projects/ProjectContainer';

export const Projects = () => {
  return (
    <>
      <AboutHeader
        subTitle="RUPI Projects"
        details="Learn more about RUPI projects "
      />
      <ProjectContainer />
    </>
  );
};
