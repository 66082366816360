export const menuITems = [
  {
    text: 'Home',
    url: '/',
  },
  {
    text: 'About',
    url: '/about',
  },
  {
    text: 'Membership',
    url: '/membership',
  },
  // {
  //   text: 'Committees',
  //   url: '/committee',
  // },
  {
    text: 'Disciplines',
    url: '/disciplines',
  },
  {
    text: 'Services',
    url: '/services',
  },
  {
    text: 'Projects',
    url: '/projects',
  },

  {
    text: 'Explore',
    url: null,
    items: [
      {
        text: 'Blog',
        url: '/blog',
      },
      {
        text: 'Publications',
        url: '/publications',
      },
    ],
  },
];
